define("web/ui/authenticated/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthenticatedIndex extends Ember.Route {
    redirect() {
      this.transitionTo("authenticated.quotes");
    }

  }

  _exports.default = AuthenticatedIndex;
});