define("web/ui/components/forms/fields/calculated/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  let FormsFieldsCalculated = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class FormsFieldsCalculated extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
    }

    get isFormula() {
      if (this.value === undefined || Ember.isEmpty(this.value)) return false;
      return this.value.toString().slice(0, 1) === "=";
    }

    handleEnter(e) {
      if (e.keyCode === 13) this.handleCalculate();
    }

    handleInput(e) {
      this.value = e.target.value;
      this.update();
    }

    handleCalculate() {
      this.value = eval(this.value.slice(1));
      this.update();
    }

    update() {
      if (!isNaN(this.value)) this.args.onUpdate(Number(this.value));
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.value;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleEnter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleEnter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCalculate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleCalculate"), _class.prototype)), _class));
  _exports.default = FormsFieldsCalculated;
});