define("web/ui/components/facilities/cards/selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b5povyuZ",
    "block": "{\"symbols\":[\"@facility\",\"@mode\",\"@quote\",\"@readOnly\"],\"statements\":[[6,[37,1],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[8,\"facilities/cards/parts/title\",[],[[\"@facility\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\",\"@query\"],[\"authenticated.settings.facilities.show\",[32,1,[\"id\"]],[30,[36,0],null,[[\"returnToQuote\",\"returnMode\"],[[32,3,[\"id\"]],[32,2]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,\"facilities/cards/parts/title\",[],[[\"@facility\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"facilities/cards/parts/address\",[],[[\"@facility\"],[[32,1]]],null],[2,\"\\n\\n\\n\"],[6,[37,1],[[32,1,[\"phoneNumber\"]]],null,[[\"default\"],[{\"statements\":[[8,\"facilities/cards/parts/phone\",[],[[\"@facility\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/facilities/cards/selected/template.hbs"
    }
  });

  _exports.default = _default;
});