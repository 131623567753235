define("web/ui/components/quotes/form/email-dialog-customer/body/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/form/email-dialog-customer/body/add-email.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _addEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = (0, _emberConcurrency.task)(function* (emails, medicalRecords, add) {
    const result = yield this.apollo.mutate({
      mutation: _addEmail.default,
      variables: {
        id: this.quote.id,
        emails: emails,
        medicalRecords: medicalRecords,
        add: add
      }
    }, "quotesFlightUpdatesEmailList");

    if (result) {
      this.emailsList = result.quote.emailList;
    }
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "emailsList", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "newEmails", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "sendMedicalNewEmails", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "updateEmailList", _descriptor5, this);
    }

    get quote() {
      return this.args.quote;
    }

    get customer() {
      return this.quote.customer;
    }

    get reps() {
      return this.customer.reps;
    }

    get rep() {
      return this.quote.rep;
    }

    get otherReps() {
      return this.reps.filter(item => item.id != this.rep.id);
    }

    get otherRepIds() {
      return this.selectedOtherReps.map(item => item.id);
    }

    get emailList() {
      const emailList = this.emailsList.map(item => item.email);
      return emailList;
    }

    get otherEmails() {
      const otherEmails = this.emailsList.filter(email => {
        return !this.reps.some(rep => rep.email === email.email) && email.email !== this.args.me;
      });
      return otherEmails;
    }

    get shouldGetMedical() {
      const medical = this.emailsList.filter(item => item.medicalRecords).map(item => item.email);
      return medical;
    }

    toggleSendMedical() {
      this.sendMedicalNewEmails = !this.sendMedicalNewEmails;
    }

    handleAddEmailToList(email) {
      this.updateEmailList.perform([email], false, true);
    }

    handleAddMedicalRecordsToList(email) {
      this.updateEmailList.perform([email], true, true);
    }

    handleRemoveEmailToList(email) {
      this.updateEmailList.perform([email], false, false);
    }

    handleInput(e) {
      this.newEmails = e.target.value.split(",");
    }

    handleAddNewEmailsToList() {
      this.updateEmailList.perform(this.newEmails, this.sendMedicalNewEmails, true);
      this.newEmails = [];
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "emailsList", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.quote.emailList;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "newEmails", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sendMedicalNewEmails", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleSendMedical", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleSendMedical"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleAddEmailToList", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAddEmailToList"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleAddMedicalRecordsToList", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAddMedicalRecordsToList"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleRemoveEmailToList", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRemoveEmailToList"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleInput", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleAddNewEmailsToList", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAddNewEmailsToList"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateEmailList", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});