define("web/ui/components/quotes/active-form/sections/updates/active-aircraft/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/updates/active-aircraft/mutation.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _temp;

  let _class = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* (id) {
    const variables = {
      id: this.args.quote.id,
      aircraftId: id
    };
    const result = yield this.apollo.mutate({
      mutation: _mutation.default,
      variables
    }, "quotesSetActiveAircraft");
    Ember.set(this.args.quote, "aircraftInFlight", result.quote.aircraftInFlight);
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor2, this);
    }

    handleSelection(id) {
      this.update.perform(id);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSelection", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSelection"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "update", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});