define("web/ui/components/quotes/active-form/sections/updates/item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/updates/item/update.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _update) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  const DEBOUNCE_MS = 500;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
    yield this.runSave.perform();
  }).restartable(), _dec6 = (0, _emberConcurrency.task)(function* () {
    const {
      id,
      eventNotes,
      eventDescription
    } = this.args.update;
    const attributes = {
      eventNotes,
      eventDescription
    };
    const variables = {
      id,
      attributes
    };
    yield this.apollo.mutate({
      mutation: _update.default,
      variables
    });
  }).drop(), _dec7 = Ember._action, _dec8 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "eventNotes", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "eventDescription", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "completedAt", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "runSave", _descriptor6, this);
      this.loadTrackedProperties();
    }

    loadTrackedProperties() {
      const {
        eventNotes,
        eventDescription,
        completedAt
      } = this.args.update;
      this.eventDescription = eventDescription;
      this.eventNotes = eventNotes;
      this.completedAt = completedAt;
    }

    async handleFieldChange(value, field) {
      try {
        await this.save.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) throw e;
      }
    }

    async handleDelete() {
      if (!confirm("Are you sure you want to delete this item")) return;
      const {
        id
      } = this.args.update;
      await this.args.onDelete(id);
    }

    async handleToggleComplete() {
      if (this.disabled) return;

      if (this.cannotToggle) {
        alert("Cannot mark this item as incomplete, as items after it have been marked as complete");
        return;
      }

      await this.args.onToggleComplete(this.args.update.id);
      this.completedAt = Ember.isPresent(this.completedAt) ? null : moment();
    }

    get cannotToggle() {
      return this.complete && this.args.lastItemCompleted > this.args.update.position;
    }

    get disabled() {
      return this.incomplete && !this.isNextUp;
    }

    get isNextUp() {
      return this.args.lastItemCompleted + 1 === this.args.update.position;
    }

    get incomplete() {
      return !this.complete;
    }

    get complete() {
      return Ember.isPresent(this.completedAt);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "eventNotes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "eventDescription", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "completedAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleFieldChange", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFieldChange"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "runSave", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleDelete", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDelete"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleComplete", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleComplete"), _class2.prototype)), _class2));

  _exports.default = _class;
});