define("web/ui/authenticated/settings/users/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IZ0I5+i2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Add a new user\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-12 text-right\"],[12],[2,\"\\n\\t\\t\"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.settings.users\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\tcancel\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\t\\t\"],[8,\"forms/user\",[],[[\"@showBasicForm\",\"@model\"],[true,[34,0]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/users/new/template.hbs"
    }
  });

  _exports.default = _default;
});