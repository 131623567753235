define("web/ui/components/forms/flight-update-template/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency", "web/ui/components/forms/flight-update-template/save.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency, _save) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  const defaultConfiguration = `template do
end`;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      id: this.args.configuration.id,
      content: this.value
    };
    yield this.apollo.mutate({
      mutation: _save.default,
      variables
    });
  }).drop(), _dec6 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "editor", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor4, this);
      this.value = this.args.configuration.content ?? defaultConfiguration;
    }

    handleUpdate(value) {
      this.value = value;
    }

    handleSave() {
      this.save.perform();
    }

    setEditor(editor) {
      this.editor = editor;
    }

    get canSave() {
      return this.value != this.args.value;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleUpdate", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleUpdate"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSave", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSave"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "setEditor", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "setEditor"), _class2.prototype)), _class2));

  _exports.default = _class;
});