define("web/ui/components/quotes/active-form/sections/case-notes/form/iv-access/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s/38l8Nl",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"IV Access\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"ui/toggle-switch-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.iv_central\",\"CENTRAL\"]],null],[2,\"\\n\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.iv_picc\",\"PICC\"]],null],[2,\"\\n\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.iv_piv\",\"PIV\"]],null],[2,\"\\n\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.iv_art\",\"ART\"]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\"],[\"details.iv_location\",\"Location\"]],null],[2,\"\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\"],[\"details.iv_size\",\"Size\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/iv-access/template.hbs"
    }
  });

  _exports.default = _default;
});