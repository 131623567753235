define("web/ui/components/airports/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0U4HB1z8",
    "block": "{\"symbols\":[\"@airport\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"authenticated.settings.airports.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,1,[\"code\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[32,1,[\"country\"]]],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[32,1,[\"latitude\"]]],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[1,[32,1,[\"longitude\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/airports/list/item/template.hbs"
    }
  });

  _exports.default = _default;
});