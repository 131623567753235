define("web/ui/components/forms/form/inputs/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HxctohAb",
    "block": "{\"symbols\":[\"@labelClass\"],\"statements\":[[10,\"label\"],[15,0,[31,[\"block \",[32,1]]]],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"label\"]]],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\",\"titleize\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/inputs/label/template.hbs"
    }
  });

  _exports.default = _default;
});