define("web/ui/components/quotes/active-form/sections/case-notes/print/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class2, _descriptor, _descriptor2, _temp;

  const formOnly = "form_only";
  const allNotes = "all_notes";
  const customerViewableOnly = "customer_viewable";

  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "operation", _descriptor, this);
      (0, _defineProperty2.default)(this, "formOnly", formOnly);
      (0, _defineProperty2.default)(this, "allNotes", allNotes);
      (0, _defineProperty2.default)(this, "customerViewableOnly", customerViewableOnly);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
    }

    handleToggleNotes(operation) {
      if (this.operation === operation) this.operation = formOnly;else this.operation = operation;
    }

    get caseNotesBaseUrl() {
      const {
        token: sessionToken
      } = this.session.data.authenticated;
      const {
        quote: {
          token,
          internalToken
        }
      } = this.args;
      let quoteToken;

      if ([this.formOnly, this.allNotes].includes(this.operation)) {
        quoteToken = internalToken;
      } else {
        quoteToken = token;
      }

      let url = `${_environment.default.APP.host}/case_notes/${quoteToken}?auth=${sessionToken}`;
      if (this.operation === this.formOnly) url += "&form_only=true";
      return url;
    }

    handleView() {
      const url = `${this.caseNotesBaseUrl}`;
      window.open(url, "_blank");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "operation", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return allNotes;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleNotes", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleNotes"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleView", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleView"), _class2.prototype)), _class2));

  _exports.default = _class;
});