define("web/ui/components/quotes/active-form/sections/case-notes/form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/case-notes/form/save.graphql", "web/ui/components/quotes/active-form/sections/case-notes/form/query.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _save, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  const newCaseNote = {};

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      id: this.args.caseNoteId
    };
    const result = yield this.apollo.query({
      query: _query.default,
      variables,
      fetchPolicy: "network-only"
    }, "caseNote");
    this.caseNote = result;
    this.isMedical = this.caseNote.details.isMedical;
  }).drop(), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrency.task)(function* () {
    if (this.disabled) return;
    const {
      quote
    } = this.args;
    let variables = {
      quoteId: quote.id,
      attributes: this.attributesForSave
    };
    const {
      caseNoteId
    } = this.args;
    if (caseNoteId != undefined) variables.id = caseNoteId;
    yield this.apollo.mutate({
      mutation: _save.default,
      variables
    });
    this.args.onChangeSection("case-notes");
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "caseNote", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isMedical", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor5, this);
      this.setup();
    }

    handleToggleNoteType() {
      if (this.isMedical) {
        if (confirm("Are you sure you want to remove the medical information?")) this.removeMedicalInformation();
      }

      this.isMedical = !this.isMedical;
    }

    removeMedicalInformation() {
      const transport = this.caseNote.details.transport;
      Ember.set(this.caseNote, "details", {
        transport
      });
    }

    setup() {
      if (this.args.caseNoteId) {
        this.loadData.perform();
      } else {
        this.caseNote = {
          recordedAt: moment().toDate(),
          details: {}
        };
      }
    }

    get disabled() {
      const {
        receivedFrom,
        obtainedBy,
        recordedAt
      } = this.caseNote;
      return Ember.isEmpty(receivedFrom) || Ember.isEmpty(recordedAt);
    }

    handleFieldChange() {}

    handleSave() {
      this.save.perform();
    }

    get attributesForSave() {
      const {
        receivedFrom,
        obtainedBy,
        recordedAt,
        details,
        customerViewable
      } = this.caseNote;
      details.isMedical = this.isMedical;
      return {
        receivedFrom,
        obtainedBy,
        recordedAt,
        details,
        customerViewable: false
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "caseNote", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isMedical", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleNoteType", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleNoteType"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleFieldChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFieldChange"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSave", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSave"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});