define("web/ui/components/quotes/actions/delete-button/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "web/gql/mutations/quotes/destroy.graphql", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _destroy, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let QuotesActionsDeleteButton = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    if (!confirm("Are you sure you want to delete this?")) return;
    let {
      id
    } = this.args.quote;
    const result = yield this.apollo.mutate({
      mutation: _destroy.default,
      variables: {
        id
      }
    }, "quotesDestroy");
    if (result.error != undefined) alert(result.error.message);else this.args.onDelete();
  }).drop(), (_class = (_temp = class QuotesActionsDeleteButton extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "delete", _descriptor2, this);
    }

    handleClick() {
      this.delete.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QuotesActionsDeleteButton;
});