define("web/ui/components/quotes/active-form/sections/case-notes/form/skin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GPfdNokg",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Skin\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,3,[\"textarea\"]],[],[[\"@field\",\"@label\"],[\"details.skin\",\"\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/skin/template.hbs"
    }
  });

  _exports.default = _default;
});