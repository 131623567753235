define("web/ui/components/quotes/active-form/sections/case-notes/form/pmh/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e76dqp6C",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"PMH\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\\t\"],[8,\"ui/toggle-switch-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.pmh_cad\",\"CAD\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.pmh_copd\",\"COPD\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.pmh_dementia\",\"Dementia\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.pmh_dm\",\"DM\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.pmh_htn\",\"HTN\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.pmh_hyperlipidemia\",\"Hyperlipidemia\"]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/pmh/template.hbs"
    }
  });

  _exports.default = _default;
});