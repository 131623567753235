define("web/ui/components/facilities/cards/parts/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYmWCw4i",
    "block": "{\"symbols\":[\"@facility\"],\"statements\":[[10,\"h4\"],[14,0,\"\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/facilities/cards/parts/title/template.hbs"
    }
  });

  _exports.default = _default;
});