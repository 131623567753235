define("web/ui/authenticated/settings/facilities/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "web/constants", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _constants, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  const DEFAULT_PAGE = 1;
  const SEARCH_DEBOUNCE = 750;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* (nameFilter) {
    yield (0, _emberConcurrency.timeout)(SEARCH_DEBOUNCE);
    yield this.doSearch.perform(nameFilter);
  }).restartable(), _dec7 = (0, _emberConcurrency.task)(function* (nameFilter) {
    this.resetPaging();
    this.nameFilter = nameFilter;
  }).restartable(), (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "activeOnly", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "page", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "nameFilter", _descriptor3, this);
      (0, _defineProperty2.default)(this, "queryParams", ["activeOnly", "page", "nameFilter"]);
      (0, _defineProperty2.default)(this, "pageSize", _constants.facilitiesPageSize);
      (0, _initializerDefineProperty2.default)(this, "startSearch", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "doSearch", _descriptor5, this);
    }

    handleSearch(e) {
      this.startSearch.perform(e.target.value);
    }

    handleActiveToggle() {
      this.resetPaging();
      this.activeOnly = !this.activeOnly;
    }

    resetPaging() {
      this.page = DEFAULT_PAGE;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "activeOnly", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_PAGE;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "nameFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSearch", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearch"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleActiveToggle", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleActiveToggle"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "startSearch", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doSearch", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});