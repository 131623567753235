define("web/ui/components/forms/form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let FormsForm = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class FormsForm extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "isValid", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "errors", _descriptor2, this);
    }

    get _isValid() {
      return this.args.requiredFields === undefined || this._hasAllRequiredFields;
    }

    get _hasAllRequiredFields() {
      return this.args.requiredFields.every(item => {
        return Ember.isPresent(this.args.model[item]);
      });
    }

    async handleSubmit(e) {
      e.preventDefault();
      if (this.args.onSubmit != undefined && this.isValid) this.args.onSubmit();
    }

    handleInput(field, value, error = null) {
      let {
        model
      } = this.args;
      if (Ember.get(model, field) == value) return;
      Ember.set(model, field, value);
      this.processError(field, error);
      this.isValid = this._isValid;
      if (this.args.onFieldChange != undefined) this.args.onFieldChange(this.errors);
    }

    processError(field, error) {
      const err = this.errors.find(item => item.field == field);
      if (error === null && err === undefined) return;

      if (error === null && err != undefined) {
        this.errors = this.errors.filter(error => {
          return error.field != field;
        });
        return;
      }

      if (err === undefined) this.errors = [...this.errors, {
        field: field,
        error: error
      }];
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isValid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this._isValid;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = FormsForm;
});