define("web/ui/components/picklists/customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sDXxY4mi",
    "block": "{\"symbols\":[\"option\",\"@queryVariables\",\"@selected\",\"@selectedId\",\"@onChange\"],\"statements\":[[8,\"picklists/picklist\",[],[[\"@query\",\"@searchField\",\"@queryResultName\",\"@queryVariables\",\"@selected\",\"@selectedId\",\"@onChange\"],[[34,0],\"name\",\"customers\",[32,2],[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"query\"]}",
    "meta": {
      "moduleName": "web/ui/components/picklists/customer/template.hbs"
    }
  });

  _exports.default = _default;
});