define("web/ui/components/users/picklist/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/gql/queries/accounts/user-list.graphql", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _userList, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let CrmSalesRepsPicklist = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    let result = yield this.apollo.query({
      query: _userList.default,
      variables: {
        role: ["sales_rep", "admin", "sys_admin"]
      },
      fetchPolicy: "network-only"
    }, "accountsUserList");
    this.items = result.filter(item => item.profile != null).filter(item => this.excludeIds === undefined || !this.excludeIds.includes(item.id)).map(item => {
      return {
        id: Number(item.id),
        name: item.profile.fullName
      };
    });
    let {
      selected
    } = this.args;
    if (selected) this.selected = this.items.find(item => item.id === Number(selected));else this.selected = null;
  }).drop(), (_class = (_temp = class CrmSalesRepsPicklist extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "items", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor4, this);
    }

    handleChange(value) {
      this.selected = value;
      this.args.onChange(Number(value.id));
    }

    handleInsert(element, [component]) {
      component.loadData.perform();
    }

    handleUpdate(element, [component, excludeIds, selected]) {
      component.loadData.perform();
    }

    get excludeIds() {
      return this.args.excludeIds;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CrmSalesRepsPicklist;
});