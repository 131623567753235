define("web/ui/components/quotes/active-form/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6RK/aGod",
    "block": "{\"symbols\":[\"@quote\",\"@pickup\",\"@dropoff\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-12 text-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"h2\"],[12],[10,\"strong\"],[12],[2,\"Flight Details - (\"],[1,[32,2,[\"airport\",\"code\"]]],[2,\" - \"],[1,[32,3,[\"airport\",\"code\"]]],[2,\")\"],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[14,5,\"color:#a00000;\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"patientInfo\",\"name\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-xs-12 text-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"h3\"],[12],[10,\"strong\"],[12],[2,\"Patient: \"],[1,[32,1,[\"patientInfo\",\"name\"]]],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"tripReference\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-xs-12 text-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"h3\"],[12],[10,\"strong\"],[12],[2,\"Trip #: \"],[1,[32,1,[\"tripReference\"]]],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,1,[\"tripOn\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-xs-12 text-center\"],[12],[2,\"\\n\\t\\t\"],[10,\"h3\"],[12],[10,\"strong\"],[12],[2,\"Trip Date:\\n\\t\\t\\t\\t\"],[8,\"ui/date-time-display\",[],[[\"@dateOnly\",\"@date\"],[true,[32,1,[\"tripOn\"]]]],null],[2,\"\\n\\t\\t\\t\"],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/header/template.hbs"
    }
  });

  _exports.default = _default;
});