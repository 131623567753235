define("web/ui/components/quotes/form/email-dialog-medical-crew/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wHtjBEZ4",
    "block": "{\"symbols\":[\"crewMember\",\"@sendToMe\",\"@me\"],\"statements\":[[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[32,2]]],null],[2,\"\\n\\t\\tSend this email to me (\"],[1,[32,3]],[2,\")\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"Send to medical crew\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[8,\"input\",[[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleOtherClick\"]],[32,1]],null]],null]],[[\"@type\"],[\"checkbox\"]],null],[2,\"\\n\\t\\t\"],[1,[32,1,[\"fullName\"]]],[2,\" - \"],[1,[32,1,[\"jobTitle\"]]],[2,\" - \"],[1,[32,1,[\"email\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"medicalCrew\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/email-dialog-medical-crew/body/template.hbs"
    }
  });

  _exports.default = _default;
});