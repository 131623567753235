define("web/ui/components/picklists/picklist/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  const DEBOUNCE_MS = 500;
  let Picklist = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* (value) {
    yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
    const {
      queryVariables,
      searchField
    } = this.args;
    const variables = { ...queryVariables,
      [searchField]: value
    };
    const result = yield this.apollo.query({
      query: this.query,
      variables,
      fetchPolicy: "network-only"
    }, this.queryResultName);
    this.options = result;
    return result;
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* () {
    const {
      selectedQuery,
      selectedId,
      selectedQueryResultName
    } = this.args;
    const result = yield this.apollo.query({
      query: selectedQuery,
      variables: {
        id: selectedId
      },
      fetchPolicy: "network-only"
    }, selectedQueryResultName);
    this.options = [result];
  }).drop(), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = (0, _emberConcurrency.task)(function* () {
    const results = yield this.apollo.query({
      query: this.query,
      variables: this.queryVariables,
      fetchPolicy: "network-only"
    }, this.queryResultName);
    this.options = results;
  }).drop(), (_class = (_temp = class Picklist extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "options", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "loadSelected", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor6, this);
    }

    async handleSearch(value) {
      const result = await this.search.perform(value);
      return result;
    }

    async handleSetup(element, [component]) {
      const {
        remote,
        selectedId
      } = component.args;

      if (!Ember.isBlank(remote)) {
        if (Ember.isBlank(selectedId)) return;
        await component.loadSelected.perform();
        component.selectedFromArgs();
      } else {
        await component.loadData.perform();
        component.selectedFromArgs();
      }
    }

    handleUpdate(element, [component, selected, selectedId]) {
      component.selectedFromArgs();
    }

    selectedFromArgs() {
      if (this.args.selected) this.selected = this.args.selected;
      if (this.args.selectedId) this.selected = this.options.find(item => item.id === this.args.selectedId);
      if (this.args.selected === null || this.args.selectedId === null) this.selected = null;
    }

    handleChange(value) {
      this.selected = value;
      this.args.onChange(value);
    }

    get query() {
      return this.args.query;
    }

    get queryResultName() {
      return this.args.queryResultName;
    }

    get queryVariables() {
      return this.args.queryVariables === undefined ? {} : this.args.queryVariables;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "options", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selected;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSearch", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearch"), _class.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSetup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleSetup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Picklist;
});