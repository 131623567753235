define("web/ui/components/forms/form/inputs/select/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/ui/components/forms/form/inputs/base-input"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _baseInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  let FormsFormSelect = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class FormsFormSelect extends _baseInput.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor, this);
      this.selected = this.getSelected();
    }

    handleUpdate(element, [passenger, component]) {
      if (component.selected === null) component.selected = component.getSelected();
    }

    getSelected(initial = true) {
      if (this.args.model === undefined) return null;
      let value = this.args.model[this.args.field];

      if (initial && this.args.selectedTransform != undefined) {
        this.args.onHandleInput(this.args.field, value);
        return this.args.selectedTransform(value);
      }

      return {
        value: value,
        label: this.labelFromValue(value)
      };
    }

    labelFromValue(value) {
      let item = this.args.options.find(item => item.value === value);
      return item === undefined ? null : item.label;
    }

    handleInput(field, value) {
      this.args.onHandleInput(field, value.value);
      this.selected = this.getSelected(false);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleUpdate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = FormsFormSelect;
});