define("web/utils/download-file", ["exports", "downloadjs"], function (_exports, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadFile = downloadFile;

  function downloadFile(url, fileName, session) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      let ajax = new XMLHttpRequest();
      ajax.open('GET', url);
      let token = session.get('data.authenticated.token');
      ajax.setRequestHeader("Authorization", `Bearer ${token}`);
      ajax.responseType = 'blob';

      ajax.onload = function (e) {
        if (this.status === 500) {
          reject();
        } else {
          (0, _downloadjs.default)(e.target.response, fileName, 'application/pdf');
          resolve();
        }
      };

      ajax.onerror = function (e) {
        reject();
      };

      ajax.send();
    });
  }
});