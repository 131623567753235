define("web/ui/components/quotes/form/misc-fee/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let QuotesFormMiscFee = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class QuotesFormMiscFee extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "amount", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "label", _descriptor2, this);
    }

    get quote() {
      return this.args.quote;
    }

    get amountValue() {
      return this.quote[this.amountFieldName];
    }

    get labelValue() {
      return this.quote[this.labelFieldName];
    }

    get amountFieldName() {
      return this.fieldName('amount');
    }

    get labelFieldName() {
      return this.fieldName('label');
    }

    fieldName(field) {
      return `misc${Ember.String.capitalize(field)}${this.item}`;
    }

    get item() {
      return this.args.item;
    }

    handleInput(field, e) {
      let value = e.target.value;
      if (field === 'amount' && !isNaN(value)) value = Number(value);
      this[field] = value;
      let fieldName = this.fieldName(field);
      this.args.onUpdate(fieldName, value);
    }

    clearForm() {
      this.amount = null;
      this.label = null;
      this.args.onUpdate(this.amountFieldName, null);
      this.args.onUpdate(this.labelFieldName, null);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.amountValue;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "label", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.labelValue;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearForm", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearForm"), _class.prototype)), _class));
  _exports.default = QuotesFormMiscFee;
});