define("web/ui/components/forms/fields/converted/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Y7ssier",
    "block": "{\"symbols\":[\"@label\",\"@unitOfMeasure1\",\"@unitOfMeasure2\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\\t\\t\"],[11,\"input\"],[16,2,[34,0]],[24,0,\"form-control\"],[4,[38,2],[\"blur\",[30,[36,1],[[32,0,[\"handleInput\"]],[32,2]],null]],null],[12],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[32,2]],[13],[2,\"\\n\\t\\t\"],[11,\"input\"],[16,2,[34,3]],[24,0,\"form-control\"],[4,[38,2],[\"blur\",[30,[36,1],[[32,0,[\"handleInput\"]],[32,3]],null]],null],[12],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[32,3]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unitOfMeasure1\",\"fn\",\"on\",\"unitOfMeasure2\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/fields/converted/template.hbs"
    }
  });

  _exports.default = _default;
});