define("web/ui/components/picklists/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TANJPVJ4",
    "block": "{\"symbols\":[\"option\",\"option\",\"@searchField\",\"&default\",\"@selectedId\",\"@selected\",\"@remote\"],\"statements\":[[11,\"div\"],[4,[38,5],[[32,0,[\"handleSetup\"]],[32,0]],null],[4,[38,6],[[32,0,[\"handleUpdate\"]],[32,0],[32,6],[32,5]],null],[12],[2,\"\\n\"],[6,[37,7],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\"],[8,\"power-select\",[],[[\"@class\",\"@searchEnabled\",\"@selected\",\"@search\",\"@onChange\"],[\"form-control\",true,[34,2],[32,0,[\"handleSearch\"]],[30,[36,4],[[32,0,[\"handleChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[18,4,[[32,2]]],[2,\"\\n\\t\"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[8,\"power-select\",[],[[\"@class\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@options\",\"@onChange\"],[\"form-control\",[30,[36,1],[[30,[36,0],[[32,3]],null]],null],[32,3],[34,2],[34,3],[30,[36,4],[[32,0,[\"handleChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[18,4,[[32,1]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"is-empty\",\"not\",\"selected\",\"options\",\"fn\",\"did-insert\",\"did-update\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/picklists/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});