define("web/ui/components/users/list/filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B1QJ9/qE",
    "block": "{\"symbols\":[\"@onNameSearch\",\"@showDisabled\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"input-group-addon\"],[12],[2,\"Filter\"],[13],[2,\"\\n\\t\\t\\t\"],[11,\"input\"],[24,1,\"search_candidate\"],[24,0,\"form-control\"],[24,\"placeholder\",\"Filter by name or email\"],[4,[38,0],[\"input\",[32,1]],null],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-offset-2 col-md-6\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"text-right text-sm\"],[12],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[4,[38,0],[\"click\",[30,[36,1],[[32,0,[\"handleDisabledToggle\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,2],[[32,2],\"Hide\",\"Show\"],null]],[2,\" Disabled Accounts\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/users/list/filter/template.hbs"
    }
  });

  _exports.default = _default;
});