define("web/ui/components/quotes/active-form/navigation/standard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a2NaZcSG",
    "block": "{\"symbols\":[\"@activeSection\",\"@onChangeSection\",\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"nav nav-pills nav-stacked\"],[12],[2,\"\\n\\t\"],[18,3,[[30,[36,0],null,[[\"item\"],[[30,[36,1],[\"quotes/active-form/navigation/standard/item\"],[[\"onChangeSection\",\"activeSection\"],[[32,2],[32,1]]]]]]]]],[2,\"\\n\\n\"],[6,[37,3],[[35,2,[\"hasAdminRole\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"li\"],[14,0,\"cursor-pointer\"],[12],[2,\"\\n\\t\\t\"],[8,\"link-to\",[],[[\"@query\"],[[30,[36,0],null,[[\"isShowingQuote\"],[true]]]]],[[\"default\"],[{\"statements\":[[2,\"Change Quote\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"cursor-pointer\"],[12],[2,\"\\n\\t\\t\"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.quotes\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\tExit\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"component\",\"session\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/navigation/standard/template.hbs"
    }
  });

  _exports.default = _default;
});