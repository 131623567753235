define("web/ui/components/quotes/active-form/sections/case-notes/form/vitals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JKH9lpKV",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Vitals\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vitals_bp\",\"uppercase\",\"BP\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vitals_hr\",\"uppercase\",\"HR\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vitals_rr\",\"uppercase\",\"RR\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vitals_sat\",\"uppercase\",\"SAT\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vitals_rhythm\",\"uppercase\",\"RHYTHM\"]],null],[2,\"\\n\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@labelClass\",\"@field\",\"@label\"],[\"uppercase\",\"details.vitals_temp\",\"TEMP\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/vitals/template.hbs"
    }
  });

  _exports.default = _default;
});