define("web/ui/components/quotes/form/quote-summary/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let QuoteSummary = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class QuoteSummary extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "price", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "backhaulPrice", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "backhaulDate", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "invoicedPrice", _descriptor4, this);
    }

    get quote() {
      return this.args.quote;
    }

    parseDate(value) {
      if (Ember.isPresent(value)) return moment(value).toDate();
    }

    handleDateChange(field, value) {
      let dateValue = moment(value.firstObject).toDate();
      this.quote.backhaulDate = dateValue;
      this.args.onUpdate();
    }

    handleInput(field, e) {
      this.quote[field] = Number(e.target.value);
      this.args.onUpdate();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "price", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.price;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "backhaulPrice", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.backhaulPrice;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "backhaulDate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.backhaulDate;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "invoicedPrice", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.invoicedPrice;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "parseDate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "parseDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleDateChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleDateChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = QuoteSummary;
});