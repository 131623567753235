define("web/ui/components/forms/fields/height/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ieW7gZ39",
    "block": "{\"symbols\":[\"@field\",\"@model\",\"@onInput\"],\"statements\":[[8,\"forms/fields/converted\",[],[[\"@unitOfMeasure1\",\"@unitOfMeasure2\",\"@multiplier\",\"@label\",\"@field\",\"@value\",\"@onInput\"],[\"in\",\"cm\",2.54,\"Height\",[32,1],[30,[36,0],[[32,2],[32,1]],null],[32,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/fields/height/template.hbs"
    }
  });

  _exports.default = _default;
});