define("web/ui/authenticated/customers/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  const allChoice = "all";
  let CustomersIndexController = (_dec = Ember._tracked, _dec2 = Ember.computed("filters", function () {
    if (Ember.isEmpty(this.filters) || this.filters.length === 1 && this.filters.includes(allChoice)) return this.model;
    return this.model.filter(item => {
      return this.filters.includes(item.entityType) || this.filters.includes("inactive") && !item.active;
    });
  }), _dec3 = Ember._action, (_class = (_temp = class CustomersIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "filters", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "filteredContent", _descriptor2, this);
    }

    updateFilters(filters) {
      this.filters = filters;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filteredContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateFilters", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype)), _class));
  _exports.default = CustomersIndexController;
});