define("web/ui/components/can-do/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    const {
      model,
      policy,
      action
    } = this.args;
    let {
      modelClass
    } = this.args;
    const result = yield this.canDo.allowed(model, policy, action, modelClass);
    this.allowed = result;
  }).enqueue(), (_class2 = (_temp = class _class2 extends _component.default {
    handleUpdate(element, [component, _arg]) {
      this.allowed = false;
      this.load.perform();
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "allowed", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canDo", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "load", _descriptor4, this);
      this.load.perform(); // this.evaluate()
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "allowed", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "canDo", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleUpdate", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleUpdate"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "load", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});