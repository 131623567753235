define("web/ui/components/quotes/active-form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  const defaultSection = 'patient-info';
  let QuotesActiveForm = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = (_temp = class QuotesActiveForm extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "section", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
    }

    handleChangeSection(section, itemId = null, e) {
      if (isNaN(itemId)) itemId = null;
      this.section = section;
      this.router.transitionTo({
        queryParams: {
          section,
          itemId
        }
      });
    }

    get quote() {
      return this.args.quote;
    }

    get hops() {
      return this.quote.hops;
    }

    get pickup() {
      return this.hops.find(hop => hop.pickup);
    }

    get dropoff() {
      return this.hops.find(hop => hop.dropoff);
    }

    get contentComponent() {
      return `quotes/active-form/sections/${this.section}`;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "section", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.section || defaultSection;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleChangeSection", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleChangeSection"), _class.prototype)), _class));
  _exports.default = QuotesActiveForm;
});