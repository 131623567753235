define("web/ui/components/quotes/active-form/sections/case-notes/form/ventilator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nMdf+/LE",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Ventilator\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"ui/toggle-switch-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.vent_ac\",\"A/C\"]],null],[2,\"\\n\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.vent_bipap\",\"BIPAP\"]],null],[2,\"\\n\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.vent_cpap\",\"CPAP\"]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vent_tv\",\"uppercase\",\"TV\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vent_rate\",\"uppercase\",\"RATE\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vent_fiotwo\",\"uppercase\",\"FIO2\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.vent_peep\",\"uppercase\",\"PEEP\"]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/ventilator/template.hbs"
    }
  });

  _exports.default = _default;
});