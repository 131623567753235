define("web/ui/components/quotes/active-form/sections/passenger-info/passenger-form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/passenger-info/passenger-form/save.graphql", "web/ui/components/quotes/active-form/sections/passenger-info/passenger-form/delete.graphql", "web/constants"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _save, _delete, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      id: this.passenger.id
    };
    let result = yield this.apollo.mutate({
      mutation: _delete.default,
      variables
    }, "quotesTripPassengerDelete");
    Ember.set(this.args.quote, "passengers", result.quote.passengers);
  }).drop(), _dec6 = (0, _emberConcurrency.task)(function* () {
    const variables = this.variables;
    let result = yield this.apollo.mutate({
      mutation: _save.default,
      variables
    }, "quotesTripPassengerSave");
    Ember.set(this.args.quote, "passengers", result.quote.passengers);
    if (variables.passengerId === undefined) this.resetPassenger();
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "passenger", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "element", _descriptor3, this);
      (0, _defineProperty2.default)(this, "genderOptions", _constants.genderOptions);
      (0, _initializerDefineProperty2.default)(this, "delete", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor5, this);
      this.setup();
    }

    handleInsert(element, [component]) {
      component.element = element;
      component.focusFirst();
    }

    focusFirst() {
      if (this.passenger.id === undefined) this.element.querySelector("input").focus();
    }

    setup() {
      this.passenger = this.args.passenger ?? this.defaultPassenger;
    }

    get readOnly() {
      const {
        readOnly
      } = this.args;
      return Ember.isEmpty(readOnly) || readOnly ? true : false;
    }

    get defaultPassenger() {
      return {
        passportExpiresOn: null,
        gender: null,
        dateOfBirth: null,
        name: null
      };
    }

    resetPassenger() {
      this.passenger = this.defaultPassenger;
      Ember.run.later(this, function () {
        this.focusFirst();
      }, 250);
    }

    handleSave() {
      if (this.readOnly) return;
      this.save.perform();
    }

    handleDelete() {
      this.delete.perform();
    }

    get variables() {
      const attributes = this.saveAttributes;
      const {
        passenger
      } = this;
      let variables = {
        attributes
      };

      if (passenger.id) {
        variables.passengerId = passenger.id;
      } else {
        variables.id = this.args.quote.id;
      }

      return variables;
    }

    get saveAttributes() {
      const {
        passenger
      } = this;
      const {
        name,
        passportNumber,
        nationality,
        gender,
        passportExpiresOn,
        dateOfBirth,
        relationToPatient
      } = passenger;
      return {
        name,
        passportNumber,
        nationality,
        gender,
        dateOfBirth,
        passportExpiresOn,
        relationToPatient
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passenger", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "element", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSave", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSave"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleDelete", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDelete"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});