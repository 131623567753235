define("web/ui/components/quotes/active-form/sections/aircraft-crew/employee/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AircraftCrewEmployee extends _component.default {
    get isSelected() {
      return this.args.selected.includes(this.args.employee.id);
    }

  }

  _exports.default = AircraftCrewEmployee;
});