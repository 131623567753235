define("web/ui/authenticated/settings/users/show/personnel/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hJzYOaNM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/employee\",[],[[\"@model\",\"@onSubmit\",\"@saveIcon\",\"@isRunning\"],[[34,0],[30,[36,1],[[32,0,[\"handleSubmit\"]]],null],\"save\",[32,0,[\"update\",\"isRunning\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,5,\"margin-top: 8px;\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,2],[[35,0,[\"active\"]],\" btn-danger\",\"btn-success\"],null]]]],[4,[38,3],[\"click\",[30,[36,1],[[32,0,[\"handleToggleActiveStatus\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"active\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Deactivate\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Activate\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/users/show/personnel/index/template.hbs"
    }
  });

  _exports.default = _default;
});