define("web/ui/components/quotes/form/email-dialog-customer-quote/body/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "selectedOtherReps", _descriptor, this);
    }

    get quote() {
      return this.args.quote;
    }

    get customer() {
      return this.quote.customer;
    }

    get reps() {
      return this.customer.reps;
    }

    get rep() {
      return this.quote.rep;
    }

    updateSelections() {
      if (this.args.onSelectionsChange != undefined) {
        this.args.onSelectionsChange(this.otherRepIds);
      }
    }

    get otherReps() {
      return this.reps.filter(item => item.id != this.rep.id);
    }

    get otherRepIds() {
      return this.selectedOtherReps.map(item => item.id);
    }

    handleOtherRepClick(rep) {
      if (this.selectedOtherReps.includes(rep)) this.selectedOtherReps.removeObject(rep);else this.selectedOtherReps.pushObject(rep);
      this.updateSelections();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectedOtherReps", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateSelections", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "updateSelections"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleOtherRepClick", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOtherRepClick"), _class2.prototype)), _class2));

  _exports.default = _class;
});