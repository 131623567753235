define("web/ui/users/password/edit/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.inject.service, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = (0, _emberConcurrency.task)(function* () {
    const result = yield this.apollo.mutate({
      mutation: (0, _graphqlTag.default)`
				mutation ($token: String!, $password: String!) {
					resetUserPassword(token: $token, password: $password) {
						success
					}
				}
			`,
      variables: {
        token: this.reset_password_token,
        password: this.password
      }
    });
    this.transitionToRoute("login");
  }).drop(), (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", ['reset_password_token']);
      (0, _initializerDefineProperty2.default)(this, "reset_password_token", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "password", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "passwordConfirmation", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "showError", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isRunning", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "resetPassword", _descriptor7, this);
    }

    handleInput(field, e) {
      this[field] = e.target.value;
    }

    handleResetPassword(e) {
      e.preventDefault();

      if (this.password !== this.passwordConfirmation) {
        this.showError = true;
        return;
      }

      this.resetPassword.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "reset_password_token", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "password", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "passwordConfirmation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showError", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isRunning", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleInput", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleResetPassword", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleResetPassword"), _class2.prototype), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "resetPassword", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});