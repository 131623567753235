define("web/ui/users/confirmation/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "web/ui/users/confirmation/validate.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _validate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _temp;

  let _class = (_class2 = (_temp = class _class2 extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
    }

    async model(params) {
      let variables = {
        token: params.confirmation_token
      };
      const user = await this.apollo.query({
        fetchPolicy: "network-only",
        query: _validate.default,
        variables
      }, "userValidateConfirmationToken");

      if (user) {
        return {
          user,
          token: variables.token
        };
      } else {
        alert("Sorry it appears like this is an invalid invitation.");
        this.transitionTo("login");
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);

  _exports.default = _class;
});