define("web/ui/components/quotes/active-form/sections/case-notes/case-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e901mAqq",
    "block": "{\"symbols\":[\"@caseNote\",\"@onChangeSection\",\"@onDelete\",\"@readOnly\"],\"statements\":[[10,\"div\"],[14,0,\"col-md-12 mb-12 border-b border-gray-300 pb-8\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"flex justify-between w-full text-underline items-baseline\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"flex align-items-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"text-lg\"],[12],[2,\"\\n\\t\\t\\t\\t\\tDetails\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"text-right text-sm text-gray-700\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"em\"],[12],[2,\"Recorded At:\\n\\t\\t\\t\\t\\t\"],[8,\"ui/date-time-display\",[],[[\"@date\"],[[32,1,[\"recordedAt\"]]]],null],[2,\",\\n\\t\\t\\t\\t\"],[13],[2,\" by: \"],[1,[32,1,[\"obtainedBy\"]]],[2,\", from: \"],[1,[32,1,[\"receivedFrom\"]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"mt-4 mb-4\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"quotes/active-form/sections/case-notes/details\",[],[[\"@caseNote\"],[[32,1]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"text-right w-full\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,0,\"btn btn-default cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2],\"edit-case-note\",[32,1,[\"id\"]]],null]],null],[12],[2,\"Edit\"],[13],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,0,\"cursor-pointer btn btn-danger\"],[4,[38,1],[\"click\",[30,[36,0],[[32,3],[32,1,[\"id\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-trash-o\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/case-note/template.hbs"
    }
  });

  _exports.default = _default;
});