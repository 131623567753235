define("web/ui/components/facilities/lookup/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/facilities/lookup/search.graphql", "web/gql/queries/facilities/facilities.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _search, _facilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  const DEBOUNCE_MS = 400;
  let AirportsLookup = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = (0, _emberConcurrency.task)(function* () {
    let {
      city
    } = this.args;
    let variables = {
      city
    };
    let result = yield this.apollo.query({
      query: _facilities.default,
      variables,
      fetchPolicy: "network-only"
    }, "facilities");
    this.suggestions = result;
  }).drop(), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
    yield this.runSearch.perform();
  }).restartable(), _dec17 = (0, _emberConcurrency.task)(function* () {
    let {
      searchText
    } = this;
    let variables = {
      criteria: searchText,
      limit: 25
    };
    let result = yield this.apollo.query({
      query: _search.default,
      variables,
      fetchPolicy: "network-only"
    }, "facilities");
    this.searchResults = result;
  }).restartable(), (_class = (_temp = class AirportsLookup extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "searchText", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "searchResults", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isAdding", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "newFacility", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "suggestions", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "loadSuggestions", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "runSearch", _descriptor10, this);
    }

    handleSetup(element, [component]) {
      component.setup();
    }

    setup() {
      if (Ember.isPresent(this.args.city)) this.loadSuggestions.perform();
    }

    get listId() {
      return Ember.guidFor(this);
    }

    get selectedValue() {
      if (this.selected != undefined) return this.selected.name;else return "";
    }

    handleSuggestedSelection(selection) {
      this.doSelect(selection);
    }

    handleCancelAdd() {
      this.resetAdd();
    }

    handleAddModalClose() {
      this.closeModal();
    }

    handleAddFacility() {
      this.newFacility.name = this.searchText;
      this.isAdding = true;
    }

    async handleSearch(e) {
      this.searchText = e.target.value;

      if (Ember.isEmpty(this.searchText)) {
        this.searchResults = [];
        this.selected = null;
      } else {
        try {
          this.search.perform();
        } catch (e) {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        }
      }
    }

    async doSelect(selected) {
      if (this.args.onSelect != undefined) {
        let result = await this.args.onSelect(selected);

        if (result) {
          this.selected = null;
        }
      }
    }

    handleAddFacilityComplete(result) {
      this.doSelect(result.facility);
      this.resetAdd();
    }

    async handleSelect(e) {
      let {
        searchResults
      } = this;
      let selected = e.target.value;
      let match = searchResults.find(item => {
        return `${item.name} - ${item.city}` === selected;
      });

      if (match != undefined) {
        this.selected = match;
        this.doSelect(match);
      }
    }

    resetAdd() {
      this.resetNewFacilityObject();
      this.closeModal();
    }

    resetNewFacilityObject() {
      this.newFacility = this.newFacilityObject();
    }

    newFacilityObject() {
      return {};
    }

    closeModal() {
      this.isAdding = false;
    }

    get noMatch() {
      return !this.search.isRunning && !Ember.isEmpty(this.searchText) && Ember.isEmpty(this.searchResults) && Ember.isEmpty(this.selected);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchText", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchResults", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selected;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isAdding", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newFacility", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.newFacilityObject();
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "suggestions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSetup", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleSetup"), _class.prototype), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadSuggestions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSuggestedSelection", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleSuggestedSelection"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCancelAdd", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancelAdd"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAddModalClose", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleAddModalClose"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAddFacility", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "handleAddFacility"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSearch", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearch"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAddFacilityComplete", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "handleAddFacilityComplete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSelect", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelect"), _class.prototype), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "runSearch", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AirportsLookup;
});