define("web/ui/authenticated/settings/users/personnel/show/index/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _temp;

  let _class = (_class2 = (_temp = class _class2 extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
    }

    model() {
      return this.modelFor("authenticated.settings.users.personnel.show");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);

  _exports.default = _class;
});