define("web/ui/components/quotes/active-form/header/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QuotesActiveFormHeader extends _component.default {
    get title() {
      return 'Flight';
    }

  }

  _exports.default = QuotesActiveFormHeader;
});