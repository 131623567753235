define("web/ui/components/ui/radio-buttons/radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B1RqZ0Hc",
    "block": "{\"symbols\":[\"@onSelect\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"export-year \",[30,[36,1],[[35,0],\"active\"],null]]]],[12],[2,\"\\n\\t\"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,4],[\"click\",[30,[36,3],[[32,1],[35,2]],null]],null],[12],[2,\"\\n\\t\\t\"],[1,[34,5]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"if\",\"value\",\"fn\",\"on\",\"label\"]}",
    "meta": {
      "moduleName": "web/ui/components/ui/radio-buttons/radio-button/template.hbs"
    }
  });

  _exports.default = _default;
});