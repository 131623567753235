define("web/ui/components/facilities/cards/parts/phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k7aOxAEI",
    "block": "{\"symbols\":[\"@facility\"],\"statements\":[[2,\"Phone: \"],[1,[32,1,[\"phoneNumber\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/facilities/cards/parts/phone/template.hbs"
    }
  });

  _exports.default = _default;
});