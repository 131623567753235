define("web/ui/components/tables/filters/button-group/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  const allChoice = "all";
  let TablesFiltersButtonGroup = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class TablesFiltersButtonGroup extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor, this);
    }

    toggleChoice(value) {
      if (this.selected.includes(value)) {
        this.selected.removeObject(value);
      } else {
        if (value === allChoice) {
          this.selected = [value];
        } else {
          if (this.selected.includes(allChoice)) this.selected = [value];else this.selected.addObject(value);
        }
      }

      this.args.onUpdate(this.selected);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleChoice", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChoice"), _class.prototype)), _class));
  _exports.default = TablesFiltersButtonGroup;
});