define("web/ui/components/picklists/patient/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6OHjpbAz",
    "block": "{\"symbols\":[\"option\",\"@queryVariables\",\"@selected\",\"@selectedId\",\"@onChange\"],\"statements\":[[8,\"picklists/picklist\",[],[[\"@query\",\"@selectedQuery\",\"@selectedQueryResultName\",\"@searchField\",\"@queryResultName\",\"@queryVariables\",\"@selected\",\"@selectedId\",\"@remote\",\"@onChange\"],[[34,0],[34,1],\"patient\",\"name\",\"patients\",[32,2],[32,3],[32,4],true,[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"query\",\"selectedQuery\"]}",
    "meta": {
      "moduleName": "web/ui/components/picklists/patient/template.hbs"
    }
  });

  _exports.default = _default;
});