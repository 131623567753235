define("web/ui/components/picklists/customer/reps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QtH6PBDc",
    "block": "{\"symbols\":[\"option\",\"@selected\",\"@options\"],\"statements\":[[8,\"power-select\",[[24,0,\"form-control\"]],[[\"@selected\",\"@options\",\"@onChange\"],[[32,2],[32,3],[30,[36,0],[[32,0,[\"handleChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\" - \"],[1,[32,1,[\"phone\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "web/ui/components/picklists/customer/reps/template.hbs"
    }
  });

  _exports.default = _default;
});