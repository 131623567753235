define("web/ui/components/quotes/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+K7t8T6Z",
    "block": "{\"symbols\":[\"@onDelete\",\"@quote\"],\"statements\":[[8,\"quotes/actions/duplicate-button\",[],[[\"@quote\"],[[32,2]]],null],[2,\"\\n\\n\\t\\t\"],[8,\"quotes/form/operations/print\",[],[[\"@quote\"],[[32,2]]],null],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2,[\"status\"]],\"active\"],null]],null,[[\"default\"],[{\"statements\":[[8,\"quotes/actions/delete-button\",[],[[\"@save\",\"@onDelete\",\"@quote\"],[false,[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/actions/template.hbs"
    }
  });

  _exports.default = _default;
});