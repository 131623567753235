define("web/ui/components/airports/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WWvG76ww",
    "block": "{\"symbols\":[\"airport\"],\"statements\":[[10,\"div\"],[14,0,\" \"],[12],[2,\"\\n\\t\"],[11,\"input\"],[24,0,\"form-control\"],[24,\"placeholder\",\"Enter an airport code or city to view matching airports...\"],[24,4,\"text\"],[4,[38,4],[\"input\",[30,[36,3],[[32,0,[\"handleSearch\"]]],null]],null],[12],[13],[2,\"\\n\\n\"],[6,[37,9],[[30,[36,8],[[35,7],[30,[36,6],[[35,5,[\"isRunning\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Apt ID\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"City\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Country\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Latitude\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"th\"],[12],[2,\"Longitude\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"airports/list/item\",[],[[\"@airport\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"airports\",\"-track-array\",\"each\",\"fn\",\"on\",\"startSearch\",\"not\",\"searchValue\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/airports/list/template.hbs"
    }
  });

  _exports.default = _default;
});