define("web/ui/components/users/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T+5OHH6m",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\"],[8,\"users/list/filter\",[],[[\"@onNameSearch\",\"@onToggleDisabled\",\"@showDisabled\",\"@roleFilter\",\"@onRoleFilterChange\"],[[30,[36,0],[[32,0,[\"handleNameSearch\"]]],null],[30,[36,0],[[32,0,[\"handleToggleDisabled\"]]],null],[34,1],[34,2],[30,[36,0],[[32,0,[\"handleRoleFilterChange\"]]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"table\"],[14,0,\"table table-condensed table-striped\"],[12],[2,\"\\n\\t\"],[10,\"thead\"],[12],[2,\"\\n\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"\\n\\t\\t\\t\\tName\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"\\n\\t\\t\\t\\tRole\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"\\n\\t\\t\\t\\tEmail\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"th\"],[12],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[30,[36,4],[\"firstName\",[35,3]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"users/list/item\",[],[[\"@user\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"showDisabled\",\"roleFilter\",\"users\",\"sort-by\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "web/ui/components/users/list/template.hbs"
    }
  });

  _exports.default = _default;
});