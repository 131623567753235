define("web/ui/components/quotes/active-form/sections/case-notes/form/neuro/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {}

  _exports.default = _default;
});