define("web/ui/components/quotes/form/email-dialog-medical-crew/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eGnW9y9G",
    "block": "{\"symbols\":[\"@quote\",\"@me\",\"&default\"],\"statements\":[[18,3,[[30,[36,6],null,[[\"body\",\"sendToMe\",\"medicalCrew\",\"emailList\",\"noneSelected\",\"sendButton\"],[[30,[36,2],[\"quotes/form/email-dialog-medical-crew/body\"],[[\"sendToMe\",\"me\",\"quote\",\"onSelectionsChange\"],[[35,4],[32,2],[32,1],[30,[36,5],[[32,0,[\"handleOnSelectionsChange\"]]],null]]]],[35,4],[35,3],[32,0,[\"emailList\"]],[35,1],[30,[36,2],[\"quotes/form/email-dialog-medical-crew/send-button\"],[[\"disabled\",\"emailList\",\"quote\"],[[35,1],[35,0],[32,1]]]]]]]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"emailList\",\"noneSelected\",\"component\",\"medicalCrew\",\"sendToMe\",\"fn\",\"hash\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/email-dialog-medical-crew/template.hbs"
    }
  });

  _exports.default = _default;
});