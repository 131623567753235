define("web/ui/components/forms/customer/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/constants", "web/gql/mutations/customers/toggle-active.graphql", "ember-concurrency", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _constants, _toggleActive, _emberConcurrency, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let FormsCustomer = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    let active = !this.model.active;
    let variables = {
      id: this.model.id,
      attributes: {
        active
      }
    };
    yield this.apollo.mutate({
      mutation: _toggleActive.default,
      variables
    });
  }).drop(), (_class = (_temp = class FormsCustomer extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _defineProperty2.default)(this, "customerTypes", _constants.customerTypes);
      (0, _initializerDefineProperty2.default)(this, "toggleActiveStatus", _descriptor2, this);
    }

    get model() {
      return this.args.model;
    }

    toggleActive() {
      this.toggleActiveStatus.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleActive", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleActive"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleActiveStatus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormsCustomer;
});