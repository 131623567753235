define("web/ui/components/forms/save-notice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmOg5YDD",
    "block": "{\"symbols\":[\"&default\",\"@isIdle\",\"@isRunning\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[10,\"nav\"],[14,0,\"navbar navbar-inverse navbar-fixed-top save-bar\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"alert alert-warning\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-save\"],[12],[13],[2,\" \\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t  \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-warning\"],[12],[13],[2,\" \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"savingMessage\",\"defaultMessage\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/save-notice/template.hbs"
    }
  });

  _exports.default = _default;
});