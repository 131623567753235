define("web/ui/components/forms/flight-update-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K42PHONW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-ace\",[],[[\"@lines\",\"@value\",\"@update\",\"@useWrapMode\",\"@useWorker\",\"@useSoftTabs\",\"@tabSize\",\"@newLineMode\",\"@mode\",\"@editorClass\",\"@ready\"],[25,[34,2],[30,[36,0],[[32,0,[\"handleUpdate\"]]],null],true,true,true,2,\"auto\",\"ace/mode/ruby\",\"text-sm\",[30,[36,0],[[32,0,[\"setEditor\"]]],null]]],null],[2,\"\\n\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"Save\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"value\",\"canSave\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/flight-update-template/template.hbs"
    }
  });

  _exports.default = _default;
});