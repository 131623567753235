define("web/ui/components/forms/customer/rep/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/bLJNb38",
    "block": "{\"symbols\":[\"Form\",\"Actions\",\"@saveText\",\"@model\",\"@onSubmit\",\"@isRunning\",\"@saveIcon\",\"@onCancelEdit\"],\"statements\":[[8,\"forms/form\",[],[[\"@model\",\"@onSubmit\"],[[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@focus\",\"@field\",\"@placeholder\"],[true,\"name\",\"Name\"]],null],[2,\"\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"phone\",\"Phone\"]],null],[2,\"\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"email\",\"Email\"]],null],[2,\"\\n\\t\"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\"],[8,[32,2,[\"saveButton\"]],[],[[\"@isRunning\"],[[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,7]]]],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\tor \"],[11,\"a\"],[4,[38,2],[\"click\",[30,[36,1],[[32,8]],null]],null],[12],[2,\"cancel\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/customer/rep/template.hbs"
    }
  });

  _exports.default = _default;
});