define("web/ui/components/quotes/form/route-editor/advanced/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TXux7vUd",
    "block": "{\"symbols\":[\"@isActive\",\"@isRunning\"],\"statements\":[[11,\"div\"],[4,[38,2],[[32,0,[\"setupComponent\"]],[32,0],[32,1]],null],[12],[2,\"\\n\\t \"],[10,\"p\"],[14,0,\"strong\"],[12],[2,\"\\n\\t Please enter your route below(each airport code should be separated by -)\\n\\t \"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[24,\"placeholder\",\"Enter your route here\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]]],null]],null],[4,[38,1],[\"keypress\",[30,[36,0],[[32,0,[\"handleKeyPress\"]]],null]],null],[12],[13],[2,\"\\n\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"Apply\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"button\"],[14,0,\"btn btn-primary disabled\"],[12],[10,\"i\"],[14,0,\"fa fa-refresh fa-spin\"],[12],[13],[2,\" Applying\"],[13],[2,\" \\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"did-update\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/route-editor/advanced/template.hbs"
    }
  });

  _exports.default = _default;
});