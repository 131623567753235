define("web/ui/components/picklists/customer/reps/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let PicklistCustomerReps = (_dec = Ember._action, (_class = class PicklistCustomerReps extends _component.default {
    // @tracked selected = this.args.selected
    handleChange(value) {
      // this.selected = value
      this.args.onChange(value);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleChange", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class));
  _exports.default = PicklistCustomerReps;
});