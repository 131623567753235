define("web/ui/authenticated/settings/aircrafts/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AircraftNewRoute extends Ember.Route {
    model() {
      return Ember.Object.create();
    }

  }

  _exports.default = AircraftNewRoute;
});