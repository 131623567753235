define("web/ui/authenticated/settings/airports/show/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "ember-concurrency", "web/utils/attributes-from-model", "web/ui/authenticated/settings/airports/show/save-mutation.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _emberConcurrency, _attributesFromModel, _saveMutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _temp;

  let _class = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      id: this.model.id,
      attributes: this.modelAttributes
    };
    yield this.apollo.mutate({
      mutation: _saveMutation.default,
      variables
    });
  }).drop(), (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor2, this);
    }

    handleSubmit() {
      this.save.perform();
    }

    get modelAttributes() {
      let output = (0, _attributesFromModel.attributesFromModel)(this.model, "name", "city", "state", "country", "code");
      const {
        latitudeInDegrees,
        longitudeInDegrees
      } = this.model;
      return {
        latitude: Number(latitudeInDegrees),
        longitude: Number(longitudeInDegrees),
        ...output
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSubmit", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSubmit"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});