define("web/ui/components/ui/age/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UiAge extends _component.default {
    get dateOfBirth() {
      return this.args.dateOfBirth;
    }

    get compareDate() {
      return this.args.compareDate;
    }

    get age() {
      let dateOfBirth = moment(this.dateOfBirth);
      let compareDate = moment(this.compareDate);
      let years = compareDate.diff(dateOfBirth, "year");
      dateOfBirth.add(years, "years");
      let months = compareDate.diff(dateOfBirth, "months");
      dateOfBirth.add(months, "months");
      let days = compareDate.diff(dateOfBirth, "days");
      if (isNaN(years) || isNaN(months) || isNaN(days)) return "";
      return `${years} Years, ${months} Months, ${days} Days`;
    }

  }

  _exports.default = UiAge;
});