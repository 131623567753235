define("web/ui/components/forms/form/inputs/select/remote/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yh6QD6FE",
    "block": "{\"symbols\":[\"@selectedTransform\",\"@label\",\"@field\",\"@model\",\"@onHandleInput\"],\"statements\":[[11,\"div\"],[4,[38,3],[[32,0,[\"handleInsert\"]],[32,0]],null],[12],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"forms/form/inputs/select\",[],[[\"@selectedTransform\",\"@label\",\"@field\",\"@model\",\"@onHandleInput\",\"@options\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  Loading List...\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"loadData\",\"if\",\"options\",\"did-insert\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/inputs/select/remote/template.hbs"
    }
  });

  _exports.default = _default;
});