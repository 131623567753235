define("web/ui/components/quotes/form/cost-summary/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _temp;

  const groundsChargeNone = "None";
  const groundsChargeOptions = [groundsChargeNone, "Pickup Only", "Dropoff Only", "Both Cities"];
  const defaultGroundsChargeOption = groundsChargeNone;
  let QuotesCostSummary = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, (_class = (_temp = class QuotesCostSummary extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "costPerMile", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "groundsCharge", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "groundsFee", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "airlineFee", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "internationalFee", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "overnightFee", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "medicalMd", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "medicalRn1", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "medicalRn2", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "medicalEmtp1", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "medicalEmtp2", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "medicalRt", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "medicalFee", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "doctorFee", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "applyCreditCardFees", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "passengersAllowed", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "miscLabel1", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "miscAmount1", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "miscLabel2", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "miscAmount2", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "miscLabel3", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "miscAmount3", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "miscLabel4", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "miscAmount4", _descriptor24, this);
      (0, _defineProperty2.default)(this, "groundsChargeOptions", groundsChargeOptions);
    }

    get quote() {
      return this.args.quote;
    }

    handlePassengersAllowedChange(value) {
      this.passengersAllowed = value;
      this.handleChange("passengersAllowed", value);
    }

    handleToggleField(field) {
      this[field] = !this[field];
      this.handleChange(field, this[field]);
    }

    handleDropdownChange(field, value) {
      this[field] = value;
      this.handleChange(field, value);
    }

    handleTextInput(field, e) {
      let value = Number(e.target.value);
      if (isNaN(value)) return;
      this.handleChange(field, value);
    }

    handleFieldInput(field, value) {
      this.handleChange(field, value);
    }

    async handleChange(field, value) {
      this.quote[field] = value;
      await this.args.onUpdate();
      this.groundsFee = this.args.quote.groundsFee;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "costPerMile", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.costPerMile;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "groundsCharge", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.groundsCharge || defaultGroundsChargeOption;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "groundsFee", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.groundsFee;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "airlineFee", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.airlineFee;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "internationalFee", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.internationalFee;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "overnightFee", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.overnightFee;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalMd", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalMd;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalRn1", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalRn1;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalRn2", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalRn2;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalEmtp1", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalEmtp1;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalEmtp2", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalEmtp2;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalRt", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalRt;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "medicalFee", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.medicalFee;
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "doctorFee", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.doctorFee;
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "applyCreditCardFees", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.applyCreditCardFees;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "passengersAllowed", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.passengersAllowed;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscLabel1", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscLabel1;
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscAmount1", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscAmount1;
    }
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscLabel2", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscLabel2;
    }
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscAmount2", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscAmount2;
    }
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscLabel3", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscLabel3;
    }
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscAmount3", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscAmount3;
    }
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscLabel4", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscLabel4;
    }
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "miscAmount4", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.miscAmount4;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handlePassengersAllowedChange", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "handlePassengersAllowedChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleToggleField", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggleField"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleDropdownChange", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "handleDropdownChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleTextInput", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "handleTextInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleFieldInput", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "handleFieldInput"), _class.prototype)), _class));
  _exports.default = QuotesCostSummary;
});