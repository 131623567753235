define("web/ui/components/users/picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "316P6xOB",
    "block": "{\"symbols\":[\"item\",\"@selected\",\"@excludeIds\"],\"statements\":[[11,\"div\"],[24,0,\"w-full\"],[4,[38,5],[[32,0,[\"handleInsert\"]],[32,0]],null],[4,[38,6],[[32,0,[\"handleUpdate\"]],[32,0],[32,3],[32,2]],null],[12],[2,\"\\n\"],[6,[37,8],[[35,7,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t \"],[8,\"power-select\",[],[[\"@options\",\"@searchEnabled\",\"@searchField\",\"@selected\",\"@onChange\"],[[30,[36,1],[\"name\",[35,0]],null],true,\"name\",[34,2],[30,[36,4],[[32,0],[35,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t \"],[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n\\t\\t \"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t \"],[13],[2,\"\\n\\t \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"items\",\"sort-by\",\"selected\",\"handleChange\",\"action\",\"did-insert\",\"did-update\",\"loadData\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/users/picklist/template.hbs"
    }
  });

  _exports.default = _default;
});