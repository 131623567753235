define("web/ui/components/quotes/form/operations/print/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/HOFOpWM",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"a\"],[16,\"disabled\",[34,0]],[16,6,[34,1]],[24,\"target\",\"_blank\"],[16,0,[31,[\"btn btn-success cursor-pointer \",[30,[36,2],[[35,0],\"disabled\"],null]]]],[17,1],[12],[2,\"\\n\\t\"],[10,\"i\"],[14,0,\"fa fa-print\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"url\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/operations/print/template.hbs"
    }
  });

  _exports.default = _default;
});