define("web/ui/components/quotes/form/email-quote/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/ui/components/quotes/form/email-quote/send.graphql", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _send, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2, _temp;

  let _class = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* () {
    const {
      quote: {
        id
      },
      sendToMe,
      sendToRep,
      otherRepIds
    } = this;
    const variables = {
      id,
      attributes: {
        sendToMe,
        sendToRep,
        otherRepIds
      }
    };
    const result = yield this.apollo.mutate({
      mutation: _send.default,
      variables
    }, "quotesSend.result");
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _defineProperty2.default)(this, "sendEmailMutation", _send.default);
      (0, _initializerDefineProperty2.default)(this, "sendEmail", _descriptor2, this);
    }

    get quote() {
      return this.args.quote;
    }

    get customer() {
      return this.quote.customer;
    }

    get hops() {
      return this.quote.hops;
    }

    get reps() {
      return this.customer.reps;
    }

    get rep() {
      return this.quote.rep;
    }

    get otherReps() {
      return this.reps.filter(item => item.id != this.rep.id);
    }

    get otherRepIds() {
      return this.selectedOtherReps.map(item => item.id);
    }

    get isNotReadyToSend() {
      return !this.hasEmailsSelected;
    }

    get hasPickupAndDropoff() {
      return this.hasPickup && this.hasDropoff;
    }

    get hasPickup() {
      return this.hops.any(item => item.pickup);
    }

    get hasDropoff() {
      return this.hops.any(item => item.dropoff);
    }

    get hasEmailsSelected() {
      return this.sendToRep || this.sendToMe || this.hasSelectedOtherReps;
    }

    get hasSelectedOtherReps() {
      return this.selectedOtherReps.length > 0;
    }

    handleOtherRepClick(rep) {
      if (this.selectedOtherReps.includes(rep)) this.selectedOtherReps.removeObject(rep);else this.selectedOtherReps.pushObject(rep);
    }

    handleSend() {
      if (this.isNotReadyToSend) return;
      this.sendEmail.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleOtherRepClick", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOtherRepClick"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSend", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSend"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sendEmail", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});