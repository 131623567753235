define("web/ui/components/quotes/active-form/sections/case-notes/form/data-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ue6UsXH7",
    "block": "{\"symbols\":[\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@label\"],[\"receivedFrom\",\"Information Received From\"]],null],[2,\"\\n\\n\"],[2,\"\\n\\n\\t\"],[8,[32,1,[\"datePicker\"]],[],[[\"@enableTime\",\"@field\",\"@label\"],[true,\"recordedAt\",\"Information Recorded At\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/data-collection/template.hbs"
    }
  });

  _exports.default = _default;
});