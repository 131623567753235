define("web/ui/components/forms/form/inputs/date-picker/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  const dateFormatForDate = "m/d/Y";
  const dateFormatForDateTime = "m/d/Y H:i";
  const invalidDateValue = "Invalid date";
  let FormsFormInputsDatePicker = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class FormsFormInputsDatePicker extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "error", _descriptor, this);
    }

    get valueAsString() {
      if (this.args.valueAsString === undefined) {
        return false;
      } else {
        return this.args.valueAsString;
      }
    }

    get model() {
      return this.args.model;
    }

    get field() {
      return this.args.field;
    }

    handleReadOnly(_value, _b, flatPickr) {
      flatPickr.input.setAttribute('autocomplete', "off");
    }

    handleClose(value, dateStr, fp) {
      if (!this.args.enableTime) this.handleInput([(0, _moment.default)(fp._input.value)]);
    }

    get dateFormat() {
      return this.args.enableTime ? dateFormatForDateTime : dateFormatForDate;
    }

    get value() {
      if (this.model === undefined) return (0, _moment.default)();else return this.model[this.field] ?? (0, _moment.default)();
    }

    handleInput([value]) {
      let {
        field
      } = this.args;
      let dateValue = value.format === undefined || this.args.enableTime ? value : value.format("DD/MM/YYYY");
      if (this.valueAsString) dateValue = `${dateValue.getMonth() + 1}/${dateValue.getDate()}/${dateValue.getFullYear()} ${dateValue.getHours()}:${dateValue.getMinutes().toString().padStart(2, "0")}`;

      if (dateValue === invalidDateValue) {
        this.error = dateValue;
      } else {
        this.error = null;
      }

      this.args.onHandleInput(field, dateValue, this.error);
    }

    parseDate(value) {
      return (0, _moment.default)(value).toDate();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleReadOnly", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleReadOnly"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleClose", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleClose"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "parseDate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "parseDate"), _class.prototype)), _class));
  _exports.default = FormsFormInputsDatePicker;
});