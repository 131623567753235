define("web/ui/components/forms/user/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "web/ui/components/forms/user/mutation.graphql", "ember-concurrency", "web/gql/mutations/employees/create.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _mutation, _emberConcurrency, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  const roles = [{
    value: "admin",
    label: "Admin"
  }, {
    value: "flight",
    label: "Flight Crew"
  }, {
    value: "medical",
    label: "Medical"
  }];

  let _class = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* () {
    const {
      model: {
        firstName,
        lastName,
        email
      }
    } = this.args;
    const variables = {
      attributes: {
        firstName,
        lastName,
        email
      }
    };
    const result = yield this.apollo.mutate({
      mutation: _create.default,
      variables
    }, "employeesCreate");

    if (result.error) {
      alert(result.error.message);
      return;
    }

    this.router.transitionTo("authenticated.settings.users.personnel.show", result.employee.id);
  }).drop(), _dec7 = (0, _emberConcurrency.task)(function* () {
    const {
      model
    } = this.args;
    let variables = {
      attributes: {
        firstName: model.firstName,
        lastName: model.lastName,
        role: model.role
      }
    };
    const isNewRecord = model.id === undefined;
    if (model.id) variables.id = model.id;else variables.attributes.email = model.email;
    variables.userPrivileges = this.userPrivileges;
    const result = yield this.apollo.mutate({
      mutation: _mutation.default,
      variables
    }, "user");

    if (result.error) {
      alert(result.error.message);
      return;
    }

    if (isNewRecord) this.router.transitionTo("authenticated.settings.users.show", result.user.id);
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "userPrivileges", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "personnelOnly", _descriptor4, this);
      (0, _defineProperty2.default)(this, "roles", roles);
      (0, _initializerDefineProperty2.default)(this, "savePersonnel", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor6, this);
    }

    handlePermissionChange(userPrivileges) {
      this.userPrivileges = userPrivileges;
    }

    handleSubmit() {
      if (this.personnelOnly) this.savePersonnel.perform();else this.save.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "userPrivileges", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "personnelOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handlePermissionChange", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handlePermissionChange"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSubmit", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSubmit"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "savePersonnel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});