define("web/ui/authenticated/settings/airports/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5l5GpjQQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-right\"],[14,5,\"margin-bottom:20px;\"],[12],[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"ember-view btn btn_custom_2 btn-lg\"]],[[\"@route\"],[\"authenticated.settings.airports.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[12],[13],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"airports/list\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/airports/index/template.hbs"
    }
  });

  _exports.default = _default;
});