define("web/ui/components/quotes/active-form/sections/case-notes/form/wrapper/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yz+Rycr5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"h4\"],[12],[18,1,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/wrapper/header/template.hbs"
    }
  });

  _exports.default = _default;
});