define("web/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const adminRole = "admin";
  const officeRole = "office";

  class _default extends _session.default {
    get role() {
      return this.data.authenticated.role;
    }

    get hasAdminRole() {
      return this.role === adminRole;
    }

    get hasOfficeRole() {
      return this.role === officeRole;
    }

  }

  _exports.default = _default;
});