define("web/ui/components/quotes/actions/duplicate-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hBXXDL2f",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,0,\"btn btn_custom_2\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"i\"],[14,0,\"fa fa-copy\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/actions/duplicate-button/template.hbs"
    }
  });

  _exports.default = _default;
});