define("web/ui/authenticated/settings/users/show/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "web/ui/authenticated/settings/users/show/index/create.graphql", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _create, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _temp;

  let _class = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    const {
      id
    } = this.model.user;
    const result = yield this.apollo.mutate({
      mutation: _create.default,
      variables: {
        id
      }
    }, "usersCreateEmployee");

    if (result.error) {
      alert(result.error.message);
      return;
    }

    this.transitionToRoute("authenticated.settings.users.personnel", result.employee.id);
  }).drop(), (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "createEmployee", _descriptor2, this);
    }

    handleCreateEmployeeForUser() {
      this.createEmployee.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleCreateEmployeeForUser", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleCreateEmployeeForUser"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "createEmployee", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});