define("web/ui/components/facilities/cards/selectable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mFWm25lJ",
    "block": "{\"symbols\":[\"@facility\",\"@onSelect\"],\"statements\":[[10,\"div\"],[14,0,\"col-md-4 col-sm-12\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n\\t\\t\"],[8,\"facilities/cards/parts/title\",[],[[\"@facility\"],[[32,1]]],null],[2,\"\\n\\n\\t\\t\"],[8,\"facilities/cards/parts/address\",[],[[\"@facility\"],[[32,1]]],null],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"text-right mt-4\"],[12],[2,\"\\n\\t\\t\\t\"],[11,\"button\"],[24,0,\"btn-xs btn-success\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"Select\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/facilities/cards/selectable/template.hbs"
    }
  });

  _exports.default = _default;
});