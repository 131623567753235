define("web/ui/components/quotes/form/route-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WH6tkGU6",
    "block": "{\"symbols\":[\"hop\",\"index\",\"@isActive\",\"@quote\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[14,5,\"margin-bottom:20px;\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12 quote_field text-center\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"position\",[32,4,[\"hops\"]]],null]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"quotes/form/route-editor/hop\",[],[[\"@quote\",\"@hop\",\"@onRemoveHop\",\"@onInsertHop\"],[[32,4],[32,1],[30,[36,1],[[32,0,[\"handleRemoveHop\"]],[32,1,[\"id\"]]],null],[30,[36,1],[[32,0,[\"handleInsertHop\"]],[32,2]],null]]],null],[2,\"\\n\\n\"]],\"parameters\":[1,2]},{\"statements\":[[2,\"\\t\\t\"],[8,\"quotes/form/route-editor/advanced\",[],[[\"@isRunning\",\"@isActive\",\"@onSubmitRoute\"],[[32,0,[\"createAdvanced\",\"isRunning\"]],[32,3],[30,[36,1],[[32,0,[\"handleAdvancedRouteSubmit\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[32,4,[\"hops\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"h4\"],[14,0,\"text-center\"],[12],[2,\"Add another hop\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,5,\"margin-top: 10px;\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"quotes/form/route-editor/hop\",[],[[\"@onAddHop\"],[[30,[36,1],[[32,0,[\"handleAddHop\"]]],null]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[6,[37,5],[[32,4,[\"mapUrl\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"img\"],[14,0,\"thumbnail map_thumbnail\"],[15,\"src\",[34,0]],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mapUrl\",\"fn\",\"sort-by\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/route-editor/template.hbs"
    }
  });

  _exports.default = _default;
});