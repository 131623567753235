define("web/ui/components/quotes/active-form/sections/case-notes/form/lab-entry/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _temp;

  const labOneMobile = {
    wbc: {
      top: 50,
      left: -10
    },
    hgb: {
      top: 5,
      left: 140
    },
    hct: {
      top: 93,
      left: 140
    },
    plt: {
      top: 50,
      left: 280
    }
  };
  const labOneDesktop = {
    wbc: {
      top: 50,
      left: 0
    },
    hgb: {
      top: 5,
      left: 185
    },
    hct: {
      top: 93,
      left: 185
    },
    plt: {
      top: 50,
      left: 360
    }
  };

  let _class = (_dec = Ember.inject.service, (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "userAgent", _descriptor, this);
    }

    get glucosePosition() {
      return this.isMobile ? {
        top: 75,
        left: 292
      } : {
        top: 47,
        left: 340
      };
    }

    get formOnePositions() {
      return this.isMobile ? labOneMobile : labOneDesktop;
    }

    get isMobile() {
      return this.userAgent.device.isMobile;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "userAgent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});