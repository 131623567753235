define("web/ui/components/quotes/active-form/sections/case-notes/form/resp/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gy9RXZYi",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Resp\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"ui/toggle-switch-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\",\"@labelClass\"],[\"details.resp_ra\",\"RA\",\"uppercase\"]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@labelClass\",\"@label\"],[\"details.resp_nc\",\"uppercase\",\"NC\"]],null],[2,\"\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@labelClass\"],[\"details.resp_venti\",\"VENTI\",\"uppercase\"]],null],[2,\"\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@labelClass\"],[\"details.resp_nrbm\",\"NRBM\",\"uppercase\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@labelClass\"],[\"details.resp_ett\",\"ETT\",\"uppercase\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@labelClass\"],[\"details.resp_trach\",\"TRACH\",\"uppercase\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"textarea\"]],[],[[\"@field\",\"@label\"],[\"details.resp_notes\",\"Notes\"]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/resp/template.hbs"
    }
  });

  _exports.default = _default;
});