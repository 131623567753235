define("web/ui/users/password/edit/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _temp;

  let _class = (_dec = Ember.inject.service, (_class2 = (_temp = class _class2 extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _defineProperty2.default)(this, "queryParams", {
        reset_password_token: {
          refreshModel: true
        }
      });
    }

    async model(params) {
      const result = await this.apollo.query({
        query: (0, _graphqlTag.default)`
        query ($token: String!) {
          getUserByResetToken(token: $token) {
            id 
            email
          }
        }
      `,
        variables: {
          token: params.reset_password_token
        }
      });
      return result;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});