define("web/ui/components/forms/form/inputs/toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LEPAJKKQ",
    "block": "{\"symbols\":[\"@size\",\"&attrs\",\"@label\"],\"statements\":[[11,\"a\"],[16,0,[31,[\"cursor-pointer \",[30,[36,1],[[30,[36,2],[[32,1],\"small\"],null],\"p-1\",\"p-2  text-lg\"],null],\" label \",[30,[36,1],[[35,0],\"label-selected\",\"label-unselected\"],null]]]],[17,2],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"toggleValue\"]]],null]],null],[12],[2,\"\\n\\t\"],[1,[32,3]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"selected\",\"if\",\"eq\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/inputs/toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});