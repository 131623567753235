define("web/ui/components/quotes/active-form/sections/case-notes/form/wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7K9q7Ler",
    "block": "{\"symbols\":[\"@alternate\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,1],\"bg-gray-100 rounded\"],null],\" p-2\"]]],[12],[2,\"\\n\\n\\t\"],[18,2,[[30,[36,2],null,[[\"header\",\"toggleViewable\"],[[30,[36,1],[\"quotes/active-form/sections/case-notes/form/wrapper/header\"],null],[30,[36,1],[\"quotes/active-form/sections/case-notes/form/wrapper/toggle-viewable\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});