define("web/ui/components/forms/fields/calculated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0MZ1fXs3",
    "block": "{\"symbols\":[\"@fieldLabel\",\"@otherLabel\",\"@placeholder\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12 amount_label\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[1,[32,1]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row cost_summary_row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[14,0,\"sr-only\"],[12],[1,[32,2]],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"input-group-addon\"],[12],[2,\"$\"],[13],[2,\"\\n\\t\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,\"placeholder\",[32,3]],[16,2,[34,2]],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]]],null]],null],[4,[38,1],[\"keydown\",[30,[36,0],[[32,0,[\"handleEnter\"]]],null]],null],[12],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"input-group-addon\"],[12],[2,\".00\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"col-xs-12 calculate_formula_container\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-default btn-block\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleCalculate\"]]],null]],null],[12],[2,\"\\n\\t\\t\\tCalculate\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"value\",\"isFormula\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/fields/calculated/template.hbs"
    }
  });

  _exports.default = _default;
});