define("web/ui/components/quotes/active-form/sections/case-notes/details/component", ["exports", "@glimmer/component", "web/ui/components/quotes/active-form/sections/case-notes/details/summary"], function (_exports, _component, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const labTwoKeys = ["na", "cl", "bun", "k", "co2", "creat", "gluc"];
  const labOneKeys = ["wbc", "hgb", "hct", "plt"];

  class _default extends _component.default {
    get hasLabTwo() {
      return this.detailsContainField(this.labFieldMapping(labTwoKeys));
    }

    get hasLabOne() {
      return this.detailsContainField(this.labFieldMapping(labOneKeys));
    }

    get details() {
      return this.args.caseNote.details;
    }

    get detailsKeys() {
      return Object.keys(this.details);
    }

    detailsContainField(keys) {
      const {
        detailsKeys
      } = this;
      return keys.any(item => {
        return detailsKeys.includes(item) && Ember.isPresent(this.details[item]);
      });
    }

    labFieldMapping(keys) {
      return keys.map(item => `lab_${item}`);
    }

    get summary() {
      const {
        caseNote
      } = this.args;

      const output = _summary.summaryTemplate.groups.filter(group => {
        return group.attributes.any(item => {
          return Ember.isPresent(caseNote.details[item.field]);
        });
      });

      return output;
    }

  }

  _exports.default = _default;
});