define("web/ui/components/customers/reps/list/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "web/ui/components/customers/reps/list/save-mutation.graphql", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _saveMutation, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrency.task)(function* () {
    if (Ember.isEmpty(this.newRep.name)) {
      alert("Please provide a valid name for the rep.");
      return;
    }

    const variables = {
      attributes: { ...this.newRep,
        customerId: this.args.customerId
      }
    };
    yield this.apollo.mutate({
      mutation: _saveMutation.default,
      variables
    });
    this.resetNewRep();
    this.isAdding = false;
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "showInactive", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isAdding", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "newRep", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "saveRep", _descriptor5, this);
    }

    get reps() {
      return this.showInactive ? this.args.reps : this.filteredReps;
    }

    get filteredReps() {
      return this.args.reps.filterBy("active", true);
    }

    get hasInactiveReps() {
      return this.args.reps.any(item => !item.active);
    }

    handleSaveRep() {
      this.saveRep.perform();
    }

    handleToggleAdding() {
      this.isAdding = !this.isAdding;
    }

    toggleInactive() {
      this.showInactive = !this.showInactive;
    }

    resetNewRep() {
      this.newRep = {};
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showInactive", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isAdding", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "newRep", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSaveRep", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSaveRep"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleAdding", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleAdding"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleInactive", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleInactive"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "saveRep", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});