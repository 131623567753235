define("web/ui/components/quotes/active-form/sections/receiving-facility/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z6IoGLIV",
    "block": "{\"symbols\":[\"canDo\",\"@dropoff\",\"@quote\"],\"statements\":[[6,[37,3],[[35,2,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,\"can-do\",[],[[\"@policy\",\"@action\"],[\"FlightDetail\",\"update\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,\"quotes/active-form/sections/facility-form\",[],[[\"@city\",\"@mode\",\"@facility\",\"@quote\",\"@readOnly\"],[[32,2,[\"airport\",\"city\"]],\"receiving\",[34,0],[32,3],[30,[36,1],[[32,1,[\"allowed\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"facility\",\"not\",\"loadData\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/receiving-facility/template.hbs"
    }
  });

  _exports.default = _default;
});