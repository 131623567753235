define("web/ui/components/employees/review-item/position-assignment-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nmVWPZ/L",
    "block": "{\"symbols\":[\"@position\"],\"statements\":[[10,\"label\"],[14,0,\"mr-2\"],[12],[2,\"\\n\\t\"],[11,\"input\"],[16,\"checked\",[34,0]],[24,4,\"checkbox\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleItemToggle\"]]],null]],null],[12],[13],[2,\" \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isSelected\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/employees/review-item/position-assignment-item/template.hbs"
    }
  });

  _exports.default = _default;
});