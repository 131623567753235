define("web/ui/components/employees/review-item/scheduler/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment", "ember-concurrency", "web/gql/mutations/employees/review-items/next-review/create.graphql", "web/gql/mutations/employees/review-items/next-review/update.graphql", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment, _emberConcurrency, _create, _update, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let EmployeesReviewItemScheduler = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)(function* () {
    let {
      employee,
      reviewItem
    } = this.args;

    if (this.employeeReviewItem != undefined) {
      let variables = {
        id: this.employeeReviewItem.id,
        attributes: {
          nextReviewOn: this.nextReviewOn
        }
      };
      yield this.apollo.mutate({
        mutation: _update.default,
        variables
      });
    } else {
      let variables = {
        employeeId: employee.id,
        attributes: {
          nextReviewOn: this.nextReviewOn,
          reviewItemId: reviewItem.id
        }
      };
      yield this.apollo.mutate({
        mutation: _create.default,
        variables
      });
    }

    this.isSaved = true;
    Ember.run.later(this, function () {
      this.isSaved = false;
    }, 3000);
  }).drop(), _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class EmployeesReviewItemScheduler extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "nextReviewOn", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isSaved", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "saveReviewOn", _descriptor4, this);
    }

    handleInput(value) {
      this.nextReviewOn = value.firstObject;
      this.saveReviewOn.perform();
    }

    parseDate(value) {
      return (0, _moment.default)(value).toDate();
    }

    get employeeReviewItem() {
      let {
        employee,
        reviewItem
      } = this.args;
      return employee.employeeReviewItems.find(item => {
        return item.reviewItem.id === reviewItem.id;
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nextReviewOn", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.employeeReviewItem === undefined ? null : this.employeeReviewItem.nextReviewOn;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSaved", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveReviewOn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "parseDate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "parseDate"), _class.prototype)), _class));
  _exports.default = EmployeesReviewItemScheduler;
});