define("web/ui/components/users/toggle-status-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZySA5D4",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"ui/async-button\",[[16,0,[31,[[34,0]]]],[17,1]],[[\"@pending\",\"@onClick\",\"@defaultText\",\"@pendingText\"],[[32,0,[\"toggle\",\"isRunning\"]],[30,[36,1],[[32,0,[\"handleClick\"]]],null],[34,2],\"Pending\"]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"buttonClass\",\"fn\",\"buttonText\"]}",
    "meta": {
      "moduleName": "web/ui/components/users/toggle-status-button/template.hbs"
    }
  });

  _exports.default = _default;
});