define("web/ui/components/quotes/form/customer-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rTjUQllN",
    "block": "{\"symbols\":[\"@quote\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,0,\"mobile_label\"],[12],[2,\"Customer\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"picklists/customer\",[],[[\"@queryVariables\",\"@onChange\",\"@selected\"],[[34,0],[30,[36,1],[[32,0,[\"handleCustomerChange\"]]],null],[32,1,[\"customer\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,0,\"mobile_label\"],[12],[2,\"Rep\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"picklists/customer/reps\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[32,1,[\"customer\",\"reps\"]],[32,1,[\"rep\"]],[30,[36,1],[[32,0,[\"handleRepChange\"]]],null]]],null],[2,\"\\n\\n\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\n\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,\"for\",\"aircraft\"],[12],[2,\"Case #\"],[13],[2,\"\\n\\t\\t\\t\"],[11,\"input\"],[16,2,[34,2]],[24,0,\"form-control\"],[24,4,\"text\"],[4,[38,3],[\"input\",[30,[36,1],[[32,0,[\"handleCaseNumberInput\"]]],null]],null],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"queryVariables\",\"fn\",\"caseNumber\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/customer-details/template.hbs"
    }
  });

  _exports.default = _default;
});