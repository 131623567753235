define("web/ui/components/quotes/active-form/sections/patient-info/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/patient-info/save.graphql", "moment", "web/constants"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _save, _moment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  const DEBOUNCE_MS = 500;
  let PatientInfo = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
    yield this.runSave.perform();
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* () {
    let {
      name,
      height,
      weight,
      dateOfBirth,
      gender,
      mainDiagnosis,
      nationality,
      passportNumber,
      passportExpiresOn
    } = this.patientInfo;
    let {
      id,
      tripOn,
      tripReference
    } = this.quoteInfo;
    let attributes = {
      name,
      height,
      weight,
      dateOfBirth,
      gender,
      mainDiagnosis,
      nationality,
      passportNumber,
      passportExpiresOn
    };
    let quoteAttributes = {
      tripOn,
      tripReference
    };
    let variables = {
      id,
      attributes,
      quoteAttributes
    };
    yield this.apollo.mutate({
      mutation: _save.default,
      variables
    });
  }).restartable(), (_class = (_temp = class PatientInfo extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "patientInfo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "quoteInfo", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor3, this);
      (0, _defineProperty2.default)(this, "genderOptions", _constants.genderOptions);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "runSave", _descriptor5, this);
    }

    async handleFieldChange(errors) {
      if (errors.length > 0) return;

      try {
        await this.save.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) throw e;
      }
    }

    get quote() {
      return this.args.quote;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "patientInfo", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.quote.patientInfo || {};
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "quoteInfo", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.quote;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleFieldChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleFieldChange"), _class.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "runSave", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PatientInfo;
});