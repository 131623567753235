define("web/ui/components/quotes/active-form/sections/aircraft-crew/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/ui/components/quotes/active-form/sections/aircraft-crew/query.graphql", "web/gql/queries/employees/employees.graphql", "web/gql/queries/aircrafts/aircrafts.graphql", "ember-concurrency", "ember-apollo-client", "lodash", "web/ui/components/quotes/active-form/sections/aircraft-crew/add-aircraft.graphql", "web/ui/components/quotes/active-form/sections/aircraft-crew/add-crew.graphql", "web/ui/components/quotes/active-form/sections/aircraft-crew/remove-aircraft.graphql", "web/ui/components/quotes/active-form/sections/aircraft-crew/remove-crew.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _query, _employees, _aircrafts, _emberConcurrency, _emberApolloClient, _lodash, _addAircraft, _addCrew, _removeAircraft, _removeCrew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  const addOperation = "add";
  const removeOperation = "remove";
  const flightCrewPositions = ["Flight", "Medical"];
  let AircraftCrew = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = (0, _emberConcurrency.task)(function* (aircraft, operation) {
    const {
      quote: {
        id
      }
    } = this.args;
    const variables = {
      id,
      aircraftId: aircraft.id
    };
    const mutation = operation === addOperation ? _addAircraft.default : _removeAircraft.default;
    yield this.apollo.mutate({
      mutation,
      variables
    });
  }).drop(), _dec10 = (0, _emberConcurrency.task)(function* (employee, operation) {
    const {
      quote: {
        id
      }
    } = this.args;
    const variables = {
      id,
      employeeId: employee.id
    };
    const mutation = operation === addOperation ? _addCrew.default : _removeCrew.default;
    yield this.apollo.mutate({
      mutation,
      variables
    });
  }).drop(), _dec11 = (0, _emberConcurrency.task)(function* () {
    const {
      quote: {
        id
      }
    } = this.args;
    const variables = {
      id
    };
    let result = yield this.apollo.watchQuery({
      query: _query.default,
      variables
    }, "quote");
    this.selectedAircrafts = result.aircrafts;
    this.selected = result.crew;
  }).drop(), _dec12 = (0, _emberConcurrency.task)(function* () {
    let variables = {
      positions: flightCrewPositions
    };
    let result = yield this.apollo.query({
      query: _employees.default,
      variables,
      fetchPolicy: "network-only"
    }, "employees");
    this.employeeGroups = this.groupedByPosition(result);
  }).drop(), _dec13 = (0, _emberConcurrency.task)(function* () {
    let result = yield this.apollo.query({
      query: _aircrafts.default,
      fetchPolicy: "network-only"
    }, "aircrafts");
    this.aircrafts = result;
  }).drop(), (_class = (_temp = class AircraftCrew extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "employeeGroups", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "aircrafts", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAircrafts", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "modifyAircraft", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "modifyCrew", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "getAdditionalQuoteInfo", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "loadEmployees", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "loadAircrafts", _descriptor10, this);
    }

    handleSetup(element, [component]) {
      component.setup();
    }

    setup() {
      this.loadEmployees.perform();
      this.loadAircrafts.perform();
      this.getAdditionalQuoteInfo.perform();
    }

    handleRemove(employee) {
      this.selected.removeObject(employee);
      this.modifyCrew.perform(employee, removeOperation);
    }

    handleRemoveAircraft(aircraft) {
      this.selectedAircrafts.removeObject(aircraft);
      this.modifyAircraft.perform(aircraft, removeOperation);
    }

    handleChange(employee) {
      this.selected.addObject(employee);
      this.modifyCrew.perform(employee, addOperation);
    }

    handleAircraftSelected(aircraft) {
      this.selectedAircrafts.pushObject(aircraft);
      this.modifyAircraft.perform(aircraft, addOperation);
    }

    get availableAircrafts() {
      const selectedIds = this.selectedAircrafts.mapBy("id");
      return this.aircrafts.filter(aircraft => {
        return !selectedIds.includes(aircraft.id);
      });
    }

    get selectedGroups() {
      return this.groupedByPosition(this.selected);
    }

    groupedByPosition(collection) {
      return (0, _lodash.groupBy)(collection, item => {
        return item.position.name;
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "employeeGroups", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "aircrafts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAircrafts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleRemove", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleRemove"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleRemoveAircraft", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleRemoveAircraft"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAircraftSelected", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleAircraftSelected"), _class.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifyAircraft", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "modifyCrew", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getAdditionalQuoteInfo", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadEmployees", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadAircrafts", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AircraftCrew;
});