define("web/ui/components/quotes/active-form/sections/case-notes/form/diet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/+6RQTnj",
    "block": "{\"symbols\":[\"W\",\"@alternate\",\"@form\"],\"statements\":[[8,\"quotes/active-form/sections/case-notes/form/wrapper\",[],[[\"@alternate\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[8,[32,1,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Abdominal\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,\"ui/toggle-switch-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.diet_ngt\",\"NGT\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.diet_ogt\",\"OGT\"]],null],[2,\"\\n\\t\\t\"],[8,[32,3,[\"toggleSwitch\"]],[[24,0,\"mr-1\"]],[[\"@field\",\"@label\"],[\"details.diet_gtube\",\"GTUBE\"]],null],[2,\"\\n\\t\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\\t\"],[8,[32,3,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@labelClass\"],[\"details.diet_tf\",\"TF\",\"uppercase\"]],null],[2,\"\\n\\n\\t\"],[8,[32,3,[\"textarea\"]],[],[[\"@field\",\"@label\"],[\"details.diet_notes\",\"Notes\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/diet/template.hbs"
    }
  });

  _exports.default = _default;
});