define("web/ui/authenticated/quotes/show/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "neG9ClYe",
    "block": "{\"symbols\":[\"canDoAc\"],\"statements\":[[8,\"can-do\",[],[[\"@policy\",\"@action\"],[\"FlightListing\",\"all_active_and_completed\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,9],[[35,10,[\"hasAdminRole\"]],[32,1,[\"allowed\"]]],null],[30,[36,9],[[35,3],[30,[36,8],[[30,[36,7],[[35,0,[\"status\"]],[30,[36,6],[\"active\",\"flown\"],null]],null]],null]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"quotes/form\",[],[[\"@quote\"],[[34,0]]],null],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3],[30,[36,2],[[35,0,[\"status\"]],\"active\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@query\"],[[30,[36,1],null,[[\"isShowingQuote\"],[false]]]]],[[\"default\"],[{\"statements\":[[2,\"Back to Flight Details\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"quotes/active-form\",[],[[\"@quote\",\"@section\",\"@itemId\"],[[34,0],[32,0,[\"section\"]],[32,0,[\"itemId\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"hash\",\"eq\",\"isShowingQuote\",\"and\",\"if\",\"array\",\"contains\",\"not\",\"or\",\"session\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/quotes/show/index/template.hbs"
    }
  });

  _exports.default = _default;
});