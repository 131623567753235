define("web/utils/attributes-from-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attributesFromModel = attributesFromModel;

  function attributesFromModel(model, ...attributes) {
    let attrs = {};
    attributes.forEach(attr => {
      const [field, fieldType = "string"] = attr.split(":");
      let value = model[field];
      if (fieldType === "number") value = Number(value);
      attrs[field] = value;
    });
    return attrs;
  }
});