define("web/ui/components/ui/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BCUYUZOA",
    "block": "{\"symbols\":[\"@page\",\"&attrs\",\"@model\"],\"statements\":[[11,\"div\"],[17,2],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,\"link-to\",[[24,0,\"cursor-pointer btn btn-primary\"]],[[\"@query\"],[[30,[36,2],null,[[\"page\"],[[30,[36,3],[[32,1]],null]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\tPrevious\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[32,3,[\"collection\",\"pageInfo\",\"hasNextPage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"link-to\",[[24,0,\"cursor-pointer btn btn-primary\"]],[[\"@query\"],[[30,[36,2],null,[[\"page\"],[[30,[36,1],[[32,1]],null]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\tNext\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[10,\"div\"],[14,5,\"margin-top: 8px;\"],[12],[2,\"\\n\\t\\tTotal Records: \"],[1,[32,3,[\"collection\",\"totalCount\"]]],[2,\"\\n\\t\\t\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,3,[\"collection\",\"totalCount\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\tPage \"],[10,\"strong\"],[12],[1,[32,1]],[13],[2,\" of \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"totalPages\",\"inc\",\"hash\",\"dec\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/ui/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});