define("web/ui/components/users/list/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  const allFilter = "All";
  const filterLookup = {
    "Admins": "admin"
  };

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "textFilter", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "showDisabled", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "roleFilter", _descriptor3, this);
    }

    handleNameSearch(e) {
      this.textFilter = e.target.value;
      if (this.args.onFilter != undefined) this.args.onFilter(this.textFilter);
    }

    handleRoleFilterChange(value) {
      this.roleFilter = value;
    }

    handleToggleDisabled(value) {
      this.showDisabled = !this.showDisabled;
      if (this.args.onToggleDisabled != undefined) this.args.onToggleDisabled(this.showDisabled);
    }

    get users() {
      let {
        args: {
          users
        },
        textFilter,
        showDisabled,
        roleFilter
      } = this;
      let output = users;
      if (!showDisabled) output = output.filterBy("disabled", false);
      if (roleFilter != allFilter) output = output.filterBy("role", filterLookup[roleFilter]);

      if (Ember.isPresent(textFilter)) {
        textFilter = textFilter.toLowerCase();
        output = output.filter(user => {
          return user.firstName.toLowerCase().includes(textFilter) || user.lastName.toLowerCase().includes(textFilter) || user.email.toLowerCase().includes(textFilter);
        });
      }

      return output;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "textFilter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showDisabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "roleFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return allFilter;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleNameSearch", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleNameSearch"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleRoleFilterChange", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRoleFilterChange"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleDisabled", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleDisabled"), _class2.prototype)), _class2));

  _exports.default = _class;
});