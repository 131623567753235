define("web/ui/components/quotes/active-form/sections/case-notes/form/lab-entry/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sAYcjswT",
    "block": "{\"symbols\":[\"@form\",\"@field\",\"@left\",\"@top\"],\"statements\":[[8,[32,1,[\"textInput\"]],[[16,5,[31,[\"top: \",[32,4],\"px;left: \",[32,3],\"px;\"]]],[24,0,\"lab-entry-input input-sm\"]],[[\"@field\"],[[32,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/form/lab-entry/input/template.hbs"
    }
  });

  _exports.default = _default;
});