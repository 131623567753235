define("web/ui/authenticated/settings/facilities/show/controller", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { action } from '@ember/object';
  // import { tracked } from '@glimmer/tracking';
  class _default extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", ["returnToQuote", "returnMode"]);
    }

  }

  _exports.default = _default;
});