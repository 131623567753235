define("web/ui/components/tables/filters/button-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VYqfBXyz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n\\t\"],[18,1,[[30,[36,2],null,[[\"button\"],[[30,[36,1],[\"tables/filters/button-group/button\"],[[\"onToggle\",\"selected\"],[[30,[36,0],[[32,0,[\"toggleChoice\"]]],null],[32,0,[\"selected\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "web/ui/components/tables/filters/button-group/template.hbs"
    }
  });

  _exports.default = _default;
});