define("web/ui/authenticated/quotes/index/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "web/gql/queries/quotes/quotes.graphql", "web/gql/queries/quotes/active-quotes.graphql", "web/ui/authenticated/quotes/index/pagination.graphql", "web/constants"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _quotes, _activeQuotes, _pagination, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  const allRecordsFilter = "all";
  let QuotesIndex = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class QuotesIndex extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canDo", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "status", _descriptor4, this);
      (0, _defineProperty2.default)(this, "queryParams", {
        statusFilter: {
          refreshModel: true,
          replace: false
        },
        patientFilter: {
          refreshModel: true,
          replace: false
        },
        customerFilter: {
          refreshModel: true,
          replace: false
        },
        pickupFilter: {
          refreshModel: true,
          replace: false
        },
        dropoffFilter: {
          refreshModel: true,
          replace: false
        },
        archived: {
          refreshModel: true,
          replace: false
        },
        page: {
          refreshModel: true,
          replace: false
        },
        ticks: {
          refreshModel: true,
          replace: false
        }
      });
    }

    async model(params) {
      const {
        statusFilter,
        customerFilter,
        patientFilter,
        pickupFilter,
        dropoffFilter,
        page,
        archived
      } = params;
      const {
        before,
        after
      } = await this.apollo.query({
        query: _pagination.default,
        variables: {
          page,
          pageSize: _constants.quotesPageSize
        },
        fetchPolicy: "network-only"
      }, "cursors");
      const {
        hasAdminRole
      } = this.session;
      let status;

      if (hasAdminRole) {
        this.status = statusFilter === allRecordsFilter ? "all" : statusFilter;
      } else {
        const result = await this.canDo.allowed(null, "FlightListing", "all_active_and_completed", null);

        if (result) {
          status = ["active", "flown"].includes(statusFilter) ? statusFilter : "active";
          this.status = status;
        } else {
          this.status = "active";
        }
      }

      const queryToUse = this.status == "active" ? _activeQuotes.default : _quotes.default;
      let variables = {
        before,
        after,
        pageSize: _constants.quotesPageSize,
        attributes: {
          status: this.status,
          customerId: customerFilter,
          patientId: patientFilter,
          pickupAirportCode: pickupFilter,
          dropoffAirportCode: dropoffFilter,
          archived
        }
      };
      const result = await this.apollo.watchQuery({
        query: queryToUse,
        fetchPolicy: "network-only",
        variables
      }, "quotes");
      window.scrollTo(0, 0);
      return result;
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.statusFilter = this.status;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canDo", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "status", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QuotesIndex;
});