define("web/ui/authenticated/customers/route", ["exports", "web/mixins/admin-route"], function (_exports, _adminRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route.extend(_adminRoute.default) {}

  _exports.default = _default;
});