define("web/ui/components/picklists/patient/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component", "web/ui/components/picklists/patient/search.graphql", "web/ui/components/picklists/patient/selected-query.graphql"], function (_exports, _defineProperty2, _component, _search, _selectedQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {tracked} from "@glimmer/tracking"
  class PatientPicklist extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "query", _search.default);
      (0, _defineProperty2.default)(this, "selectedQuery", _selectedQuery.default);
    }

  }

  _exports.default = PatientPicklist;
});