define("web/ui/components/quotes/export-data-button/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "lodash", "ember-concurrency", "web/ui/components/quotes/export-data-button/export-mutation.graphql", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _lodash, _emberConcurrency, _exportMutation, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  const defaultSendTo = "christina@globaljetcare.com,bart@globaljetcare.com";

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrency.task)(function* () {
    const {
      sendTo,
      selectedYears
    } = this;
    const variables = {
      sendTo,
      selectedYears
    };
    const mutation = yield this.apollo.mutate({
      mutation: _exportMutation.default,
      variables
    }, "quotesExport");
    alert(mutation.result.message);
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "isModalOpen", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "sendTo", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "selectedYears", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "exportData", _descriptor5, this);
    }

    handleSelectYear(year) {
      if (this.selectedYears.includes(year)) this.selectedYears.removeObject(year);else this.selectedYears.addObject(year);
    }

    handleToggleDialog() {
      this.isModalOpen = !this.isModalOpen;
    }

    handleRunExport() {
      this.exportData.perform();
    }

    get years() {
      const firstYear = 2015;
      const thisYear = new Date().getFullYear();
      return (0, _lodash.range)(firstYear, thisYear + 1);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isModalOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sendTo", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return defaultSendTo;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selectedYears", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSelectYear", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSelectYear"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleDialog", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleDialog"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleRunExport", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRunExport"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "exportData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});