define("web/ui/components/forms/form/inputs/toggle-switch/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2;

  let _class = (_dec = Ember._action, (_class2 = class extends _component.default {
    get value() {
      const {
        model,
        field
      } = this.args;
      if (model === undefined) return false;
      return Ember.get(model, field);
    }

    get selected() {
      return this.value;
    }

    toggleValue() {
      const {
        args: {
          onHandleInput,
          field
        },
        value
      } = this;
      onHandleInput(field, value ? null : true);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleValue", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleValue"), _class2.prototype)), _class2));

  _exports.default = _class;
});