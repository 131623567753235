define("web/ui/components/accounts/forms/forgot-password/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency", "graphql-tag"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let AccountsFormsForgotPassword = (_dec = Ember._tracked, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)(function* () {
    const {
      email
    } = this;
    yield this.apollo.mutate({
      mutation: (0, _graphqlTag.default)`
				mutation($email: String!) {
					accountsForgotPassword(email: $email) {
					  result
					}
				}
			`,
      variables: {
        email
      }
    });
    alert("Your forgot password request has been received");
    this.router.transitionTo("login");
  }).drop(), (_class = (_temp = class AccountsFormsForgotPassword extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "email", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "forgotPassword", _descriptor4, this);
    }

    handleInput(e) {
      this.email = e.target.value;
    }

    handleSubmit(e) {
      e.preventDefault();
      this.forgotPassword.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "email", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "forgotPassword", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountsFormsForgotPassword;
});