define("web/ui/components/quotes/form/email-quote/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YCB1zjhN",
    "block": "{\"symbols\":[\"EmailDialog\",\"@quote\"],\"statements\":[[8,\"quotes/form/email-dialog-customer-quote\",[],[[\"@quote\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[8,[32,1,[\"body\"]],[],[[\"@me\"],[\"quote@globaljetcare.com\"]],null],[2,\"\\n\"],[8,[32,1,[\"sendButton\"]],[],[[\"@buttonText\",\"@mutation\"],[\"Send Quote by Email\",[34,0]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sendEmailMutation\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/email-quote/template.hbs"
    }
  });

  _exports.default = _default;
});