define("web/ui/login/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  let LoginController = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* (username, password) {
    const transition = this.session.attemptedTransition;

    try {
      yield this.session.authenticate('authenticator:graphql', username, password);
      this.set("errorMessage", null);

      if (transition) {
        transition.retry();
      } else {
        this.transitionToRoute("authenticated");
      }
    } catch (e) {
      this.set("errorMessage", "Invalid username or password!");
      this.set("password", "");
    }
  }).drop(), (_class = (_temp = class LoginController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "authenticate", _descriptor2, this);
    }

    async login(username, password) {
      this.authenticate.perform(username, password);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "login", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "authenticate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginController;
});