define("web/ui/components/ui/async-button/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "buttonText", _descriptor, this);
      this.setDefaultButtonText();
    }

    get defaultButtonText() {
      return this.args.defaultText;
    }

    async handleClick() {
      const {
        onClick
      } = this.args;
      await this.args.onClick();
      this.displayAfterCompleteMessage();
    }

    displayAfterCompleteMessage() {
      const {
        afterCompleteMessage
      } = this.args;
      if (Ember.isEmpty(afterCompleteMessage)) return;
      this.buttonText = afterCompleteMessage;
      Ember.run.later(this, this.setDefaultButtonText, 1000);
    }

    setDefaultButtonText() {
      this.buttonText = this.defaultButtonText;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "buttonText", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleClick", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2));

  _exports.default = _class;
});