define("web/ui/components/quotes/form/route-editor/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "web/ui/components/quotes/form/route-editor/remove-hop.graphql", "web/ui/components/quotes/form/route-editor/create-advanced.graphql", "web/ui/components/quotes/form/route-editor/insert-hop.graphql", "web/ui/components/quotes/form/route-editor/create-hop.graphql", "ember-apollo-client", "web/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _removeHop, _createAdvanced, _insertHop, _createHop, _emberApolloClient, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let QuotesFormRouteEditor = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* (id) {
    let variables = {
      id
    };
    yield this.apollo.mutate({
      mutation: _removeHop.default,
      variables
    });
    this.setMapUrl();
  }).drop(), _dec7 = (0, _emberConcurrency.task)(function* (hops) {
    let {
      id
    } = this.quote;
    let attributes = {
      hops: hops.split("-")
    };
    let variables = {
      id,
      attributes
    };
    const result = yield this.apollo.mutate({
      mutation: _createAdvanced.default,
      variables
    }, "quotesHopsCreateAdvanced");

    if (result.error) {
      alert(result.error.message);
      return;
    }

    this.setMapUrl();
  }).drop(), _dec8 = (0, _emberConcurrency.task)(function* (position) {
    let {
      id
    } = this.quote;
    let variables = {
      id,
      position
    };
    yield this.apollo.mutate({
      mutation: _insertHop.default,
      variables
    });
    this.setMapUrl();
  }).drop(), _dec9 = (0, _emberConcurrency.task)(function* (airport) {
    let {
      id
    } = this.quote;
    let variables = {
      id,
      airportId: airport.id
    };
    yield this.apollo.mutate({
      mutation: _createHop.default,
      variables
    });
    this.setMapUrl();
  }).drop(), (_class = (_temp = class QuotesFormRouteEditor extends _component.default {
    handleRemoveHop(id) {
      this.removeHop.perform(id);
    }

    setMapUrl() {
      this.mapUrl = `${_environment.default.APP.host}/map/${this.args.quote.id}?${this.ticks}`;
    }

    get ticks() {
      return new Date().getTime();
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "mapUrl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "removeHop", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "createAdvanced", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "insertHop", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "createHop", _descriptor6, this);
      this.setMapUrl();
    }

    handleAddHop(airport) {
      this.createHop.perform(airport);
    }

    handleInsertHop(position) {
      this.insertHop.perform(position + 1);
    }

    handleAdvancedRouteSubmit(hops) {
      this.createAdvanced.perform(hops);
    }

    get quote() {
      return this.args.quote;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "mapUrl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleRemoveHop", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleRemoveHop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAddHop", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleAddHop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInsertHop", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertHop"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAdvancedRouteSubmit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleAdvancedRouteSubmit"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeHop", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createAdvanced", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "insertHop", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createHop", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QuotesFormRouteEditor;
});