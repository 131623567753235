define("web/ui/components/ui/async-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gzwWAIQ7",
    "block": "{\"symbols\":[\"@pendingText\",\"@pending\",\"&attrs\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,2]],[17,3],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleClick\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"buttonText\",\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/ui/async-button/template.hbs"
    }
  });

  _exports.default = _default;
});