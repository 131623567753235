define("web/services/can-do", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency", "ember-apollo-client", "web/gql/queries/accounts/can.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency, _emberApolloClient, _can) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _temp;

  let CanDo = (_dec = (0, _emberConcurrency.task)(function* (model, policy, action, modelClass) {
    if (Ember.isBlank(modelClass)) {
      modelClass = model != undefined ? model.__typename : null;
    }

    const modelId = model != undefined ? model.id : null;
    const result = yield this.apollo.query({
      query: _can.default,
      variables: {
        modelClass,
        modelId,
        policy,
        action
      },
      fetchPolicy: "network-only"
    }, "can");
    return result;
  }).enqueue(), (_class = (_temp = class CanDo extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "load", _descriptor2, this);
    }

    async allowed(model, policy, action, modelClass) {
      const result = await this.load.perform(model, policy, action, modelClass);
      return result;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "load", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CanDo;
});