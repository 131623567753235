define("web/ui/components/quotes/form/route-editor/advanced/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  let RouteEditorAdvanced = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class RouteEditorAdvanced extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "route", _descriptor, this);
    }

    setupComponent(element, [component], isActive) {
      if (component.args.isActive) component.focus(element);
    }

    focus(element) {
      Ember.run.later(this, () => {
        element.querySelector("input").focus();
      }, 250);
    }

    handleInput(e) {
      this.route = e.target.value;
    }

    handleKeyPress(e) {
      if (e.key === "Enter") this.args.onSubmitRoute(this.route);
    }

    async handleSubmit() {
      this.args.onSubmitRoute(this.route);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "route", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setupComponent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setupComponent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleKeyPress", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyPress"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype)), _class));
  _exports.default = RouteEditorAdvanced;
});