define("web/ui/components/quotes/active-form/sections/case-notes/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/case-notes/query.graphql", "web/ui/components/quotes/active-form/sections/case-notes/delete-mutation.graphql", "web/ui/components/quotes/active-form/sections/case-notes/send-email.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _query, _deleteMutation, _sendEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  let _class = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* (id) {
    const result = yield this.apollo.mutate({
      mutation: _deleteMutation.default,
      variables: {
        id
      }
    }, "quotesTripCaseNoteDelete.quote");
    this.setCaseNotes(result);
  }).drop(), _dec3 = (0, _emberConcurrency.task)(function* () {
    let {
      quote: {
        id
      }
    } = this.args;
    const variables = {
      id
    };
    const result = yield this.apollo.watchQuery({
      query: _query.default,
      variables,
      fetchPolicy: "network-only"
    }, "quote");
    this.setCaseNotes(result);
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _defineProperty2.default)(this, "sendEmailMutation", _sendEmail.default);
      (0, _initializerDefineProperty2.default)(this, "delete", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor3, this);
      this.setup();
    }

    setup() {
      this.loadData.perform();
    }

    handleDelete(id) {
      if (!confirm("Are you sure you want to delete this note?")) return;
      this.delete.perform(id);
    }

    setCaseNotes(quotePayload) {
      let {
        quote
      } = this.args;
      Ember.set(quote, "caseNotes", quotePayload.caseNotes); // set(
      // 	quote,
      // 	"crew",
      // 	quotePayload.crew
      // )
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleDelete", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDelete"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "delete", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});