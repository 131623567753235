define("web/ui/components/airports/lookup/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/airports/lookup/search.graphql", "web/ui/components/airports/lookup/airport-lookup.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _search, _airportLookup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  const DEBOUNCE_MS = 400;
  let AirportsLookup = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrency.task)(function* (element) {
    const result = yield this.apollo.query({
      query: _airportLookup.default,
      variables: {
        id: this.args.selectedId
      },
      fetchPolicy: "network-and-cache"
    }, "airport");
    element.value = `${result.code}`;
  }).drop(), _dec8 = Ember._action, _dec9 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
    yield this.runSearch.perform();
  }).restartable(), _dec10 = (0, _emberConcurrency.task)(function* () {
    let {
      searchText
    } = this;
    let variables = {
      code: searchText,
      limit: 25
    };
    let result = yield this.apollo.query({
      query: _search.default,
      variables
    }, "airports");
    this.searchResults = result;
  }).restartable(), (_class = (_temp = class AirportsLookup extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "searchText", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "searchResults", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selected", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "fetchAirport", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "search", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "runSearch", _descriptor7, this);
    }

    get listId() {
      return Ember.guidFor(this);
    }

    get selectedValue() {
      if (this.selected != undefined) return this.selected.code;else return "";
    }

    async handleSearch(e) {
      this.searchText = e.target.value;

      if (Ember.isEmpty(this.searchText)) {
        this.searchResults = [];
      } else {
        try {
          this.search.perform();
        } catch (e) {
          if (!(0, _emberConcurrency.didCancel)(e)) {
            throw e;
          }
        }
      }
    }

    handleInsert(element, [component, selectedId]) {
      if (selectedId) {
        const airport = component.loadSelected(element);
      }
    }

    handleUpdate(element, [component, selectedId]) {
      component.doSelect();
    }

    loadSelected(element) {
      this.fetchAirport.perform(element);
    }

    doSelect() {
      if (this.args.selected === null || this.args.selectedId === null) this.selected = null;
    }

    async handleSelect(e) {
      let {
        searchResults
      } = this;
      let selected = e.target.value;
      let match = searchResults.find(item => {
        return `${item.code} - ${item.city}` === selected;
      });

      if (match != undefined) {
        this.selected = match;
        let result = await this.args.onSelect(match);

        if (result) {
          this.selected = null;
        }
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchText", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchResults", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selected;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSearch", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearch"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInsert", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsert"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleUpdate", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdate"), _class.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchAirport", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSelect", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelect"), _class.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "search", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "runSearch", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AirportsLookup;
});