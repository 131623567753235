define("web/ui/components/forms/fields/converted/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
    }

    get multiplier() {
      return this.args.multiplier;
    }

    get unitOfMeasure1() {
      try {
        return (this.value / this.multiplier).toFixed(0);
      } catch {
        return 0;
      }
    }

    get unitOfMeasure2() {
      try {
        return this.value.toFixed(2);
      } catch {
        return 0;
      }
    }

    handleInput(units, e) {
      let {
        value
      } = e.target;

      if (isNaN(value)) {
        return;
      } else {
        value = Number(value);
      }

      if (units == this.args.unitOfMeasure1) this.value = value * this.multiplier;else this.value = value;
      if (this.args.onInput != undefined) this.args.onInput(this.args.field, Number(this.value.toFixed(2)));
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.value || null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleInput", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype)), _class2));

  _exports.default = _class;
});