define("web/ui/components/quotes/form/operations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OuY6CwxJ",
    "block": "{\"symbols\":[\"@quote\"],\"statements\":[[10,\"div\"],[14,0,\"row mobile-buttons-row\"],[14,5,\"margin-top:20px;margin-bottom:50px;\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-xs-4\"],[12],[2,\"\\n\\t\\t\"],[8,\"quotes/form/operations/print\",[[24,0,\"btn-block\"]],[[\"@quote\"],[[32,1]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-xs-4\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,0,\"btn btn-warning btn-block cursor-pointer\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleClose\"]]],null]],null],[12],[10,\"i\"],[14,0,\"fa fa-times-circle\"],[12],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-xs-4\"],[12],[2,\"\\n\\t\\t\"],[8,\"quotes/delete-button\",[],[[\"@quote\",\"@format\"],[[32,1],\"block\"]],null],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/operations/template.hbs"
    }
  });

  _exports.default = _default;
});