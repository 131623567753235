define("web/ui/authenticated/settings/review/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      return null;
    }

    resetController(controller) {
      controller.name = "";
    }

  }

  _exports.default = _default;
});