define("web/ui/components/forms/fee/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ul2JLIPN",
    "block": "{\"symbols\":[\"MyForm\",\"@fee\"],\"statements\":[[8,\"forms/form\",[],[[\"@model\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"flex align-items-center\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"w-75-percent\"],[12],[2,\"\\n\\t\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@label\"],[\"amount\",[30,[36,0],[[32,2,[\"name\"]]],null]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"w-25-percent pl-2 pt-1\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"handleSave\"]]],null]],null],[12],[2,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/fee/template.hbs"
    }
  });

  _exports.default = _default;
});