define("web/ui/components/forms/form/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/nRuYdFu",
    "block": "{\"symbols\":[\"@isValid\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"form-actions flex justify-between\"],[12],[2,\"\\n\\t\"],[18,2,[[30,[36,1],null,[[\"cancelButton\",\"saveButton\"],[[30,[36,0],[\"forms/form/actions/cancel-button\"],null],[30,[36,0],[\"forms/form/actions/save-button\"],[[\"isValid\"],[[32,1]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/actions/template.hbs"
    }
  });

  _exports.default = _default;
});