define("web/constants/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.facilitiesPageSize = _exports.quotesPageSize = void 0;
  const quotesPageSize = 50;
  _exports.quotesPageSize = quotesPageSize;
  const facilitiesPageSize = 20;
  _exports.facilitiesPageSize = facilitiesPageSize;
});