define("web/ui/components/quotes/active-form/sections/updates/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/updates/query.graphql", "lodash", "web/ui/components/quotes/active-form/sections/updates/complete.graphql", "web/ui/components/quotes/active-form/sections/updates/delete.graphql", "web/ui/components/quotes/active-form/sections/updates/insert.graphql", "web/ui/components/quotes/active-form/sections/updates/send-email.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _query, _lodash, _complete, _delete, _insert, _sendEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {}).drop(), _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* (id) {
    let result = yield this.apollo.mutate({
      mutation: _complete.default,
      variables: {
        id
      }
    }, "quotesFlightUpdatesToggleComplete");
    Ember.set(this.args.quote, "lastCompletedFlightUpdate", result.quote.lastCompletedFlightUpdate);
  }).drop(), _dec7 = Ember._action, _dec8 = (0, _emberConcurrency.task)(function* (position) {
    const {
      id
    } = this.args.quote;
    const result = yield this.apollo.mutate({
      mutation: _insert.default,
      variables: {
        id,
        position: position + 1
      }
    }, "quotesFlightUpdatesInsert");
    this.flightUpdates = result.quote.flightUpdates;
    this.resetQuoteValuesFromMutationResult(result.quote);
  }).drop(), _dec9 = Ember._action, _dec10 = (0, _emberConcurrency.task)(function* (id) {
    const result = yield this.apollo.mutate({
      mutation: _delete.default,
      variables: {
        id
      }
    }, "quotesFlightUpdatesDelete");
    this.flightUpdates = result.quote.flightUpdates;
    this.resetQuoteValuesFromMutationResult(result.quote);
  }).drop(), _dec11 = (0, _emberConcurrency.task)(function* () {
    let {
      quote: {
        id
      }
    } = this.args;
    const variables = {
      id
    };
    let result = yield this.apollo.watchQuery({
      query: _query.default,
      variables,
      fetchPolicy: "network-only"
    }, "quote");
    this.flightUpdates = result.flightUpdates;
    this.resetQuoteValuesFromMutationResult(result);
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "flightUpdates", _descriptor2, this);
      (0, _defineProperty2.default)(this, "sendEmailMutation", _sendEmail.default);
      (0, _initializerDefineProperty2.default)(this, "sendEmail", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "toggleComplete", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "insertFlightUpdate", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "deleteUpdate", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor7, this);
      this.setup();
    }

    setup() {
      this.loadData.perform();
    }

    handleSent(result) {
      this.flightUpdates = result.quotesFlightUpdatesSendUpdate.quote.flightUpdates;
    }

    handleSend(emailList) {}

    async handleToggleComplete(id) {
      let result = await this.toggleComplete.perform(id);
      return true;
    }

    handleInsertUpdate(position) {
      this.insertFlightUpdate.perform(position);
    }

    handleDeleteItem(id) {
      this.deleteUpdate.perform(id);
    }

    resetQuoteValuesFromMutationResult(result) {
      let {
        quote
      } = this.args;
      Ember.set(quote, "lastCompletedFlightUpdate", result.lastCompletedFlightUpdate);
      Ember.set(quote, "flightUpdates", result.flightUpdates);
      Ember.set(quote, "aircrafts", result.aircrafts);
      Ember.set(quote, "aircraftInFlight", result.aircraftInFlight);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "flightUpdates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSent", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSent"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSend", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSend"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sendEmail", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleComplete", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleComplete"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggleComplete", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleInsertUpdate", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInsertUpdate"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "insertFlightUpdate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleDeleteItem", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDeleteItem"), _class2.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "deleteUpdate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});