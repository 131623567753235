define("web/ui/components/forms/form/inputs/label/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {tracked} from "@glimmer/tracking"
  class FormsFormInputsLabel extends _component.default {
    get label() {
      if (this.args.label != undefined) return this.args.label;
      return this.args.field;
    }

  }

  _exports.default = FormsFormInputsLabel;
});