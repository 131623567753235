define("web/ui/components/quotes/form/route-editor/hop/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency", "web/ui/components/quotes/form/route-editor/hop/update.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency, _update) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = (0, _emberConcurrency.task)(function* () {
    let {
      id
    } = this.hop;
    let {
      pickup,
      dropoff,
      airportId
    } = this;
    let attributes = {
      airportId,
      pickup,
      dropoff
    };
    let variables = {
      id,
      attributes
    };
    yield this.apollo.mutate({
      mutation: _update.default,
      variables
    });
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "pickup", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "dropoff", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "airportId", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor5, this);
    }

    get hop() {
      return this.args.hop;
    }

    get quote() {
      return this.args.quote;
    }

    get canPickup() {
      if (this.hop === undefined) return false;
      return !this.quoteHasPickup;
    }

    get canDropoff() {
      if (this.hop === undefined) return false;
      return !this.quoteHasDropoff;
    }

    get quoteHasPickup() {
      return this.quote.hops.any(hop => {
        return hop.pickup;
      });
    }

    get quoteHasDropoff() {
      return this.quote.hops.any(hop => {
        return hop.dropoff;
      });
    }

    get showToggle() {
      if (this.hop === undefined) return false;
      return this.canDropoff || this.canPickup || this.pickup || this.dropoff;
    }

    handleTogglePickup() {
      this.pickup = !this.pickup;
      this.update.perform();
    }

    handleToggleDropoff() {
      this.dropoff = !this.dropoff;
      this.update.perform();
    }

    async handleAirportSelect(airport) {
      if (this.args.onAddHop != undefined) {
        await this.args.onAddHop(airport);
        return true;
      } else {
        this.airportId = airport.id;
        this.update.perform();
        return false;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "pickup", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.hop.pickup;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dropoff", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.hop.dropoff;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "airportId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.hop.airport.id;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleTogglePickup", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleTogglePickup"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleDropoff", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleDropoff"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleAirportSelect", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAirportSelect"), _class2.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "update", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});