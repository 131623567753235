define("web/ui/authenticated/settings/users/show/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "92fzvC2f",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"reviewItemPositionAssignments\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[8,\"employees/review-item/scheduler\",[],[[\"@reviewItem\",\"@employee\"],[[32,1,[\"reviewItem\"]],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/users/show/review/template.hbs"
    }
  });

  _exports.default = _default;
});