define("web/ui/components/layout/header/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  let LayoutHeader = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    const customerAccess = yield this.loadPolicy.perform("Customer");
    const databaseAccess = yield this.loadPolicy.perform("Database");
    this.customerAccess = customerAccess;
    this.databaseAccess = databaseAccess;
  }).drop(), _dec9 = (0, _emberConcurrency.task)(function* (policy) {
    const result = yield this.canDo.allowed(null, policy, "access", null);
    return result;
  }).drop(), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class LayoutHeader extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "userAgent", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "canDo", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "collapsed", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "customerAccess", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "databaseAccess", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "loadPolicies", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "loadPolicy", _descriptor10, this);
      this.loadPolicies.perform();
    }

    get isMobile() {
      return this.userAgent.device.isMobile;
    }

    handleLogout() {
      this.session.invalidate();
      document.location.href = "/";
    }

    handleNavigation(route) {
      this.collapsed = true;
      this.router.transitionTo(route);
    }

    toggleCollapse(value = null) {
      this.collapsed = value != null ? value : !this.collapsed;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userAgent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canDo", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "collapsed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customerAccess", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "databaseAccess", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadPolicies", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadPolicy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleLogout", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "handleLogout"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleNavigation", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleNavigation"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleCollapse", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCollapse"), _class.prototype)), _class));
  _exports.default = LayoutHeader;
});