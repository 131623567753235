define("web/ui/authenticated/settings/aircrafts/show/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency", "web/gql/mutations/aircrafts/delete.graphql", "web/gql/mutations/aircrafts/update.graphql", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency, _delete, _update, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let AircraftsShowController = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    let variables = {
      id: this.model.id
    };
    let result = yield this.apollo.mutate({
      mutation: _delete.default,
      variables
    }, "aircraftsDelete");
    this.transitionToRoute("authenticated.settings.aircrafts");
  }).drop(), _dec5 = (0, _emberConcurrency.task)(function* () {
    let {
      registration,
      description,
      feePerMile,
      speed,
      shortHopFee
    } = this.model;
    let variables = {
      id: this.model.id,
      attributes: {
        registration,
        description,
        feePerMile: Number(feePerMile),
        speed: Number(speed),
        shortHopFee: Number(shortHopFee)
      }
    };
    let result = yield this.apollo.mutate({
      mutation: _update.default,
      variables
    }, "aircraftsUpdate");
    this.transitionToRoute("authenticated.settings.aircrafts.index");
  }).drop(), (_class = (_temp = class AircraftsShowController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "deleteAircraft", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor3, this);
    }

    handleInput(field, e) {
      Ember.set(this.model, field, e.target.value);
    }

    async handleDeleteAircraft() {
      let result = confirm("Are you sure?");
      if (!result) return;
      this.deleteAircraft.perform();
    }

    async handleSubmit() {
      this.update.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleDeleteAircraft", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleDeleteAircraft"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteAircraft", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "update", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AircraftsShowController;
});