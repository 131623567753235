define("web/ui/components/forms/airport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7oX9WPGl",
    "block": "{\"symbols\":[\"Form\",\"Actions\",\"@saveText\",\"@model\",\"@onSubmit\",\"@isRunning\",\"@saveIcon\"],\"statements\":[[8,\"forms/form\",[],[[\"@model\",\"@onSubmit\"],[[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"code\",\"Airport code\"]],null],[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"name\",\"Airport name\"]],null],[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"city\",\"City\"]],null],[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"state\",\"State\"]],null],[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@placeholder\"],[\"country\",\"Country\"]],null],[2,\"\\n\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@placeholder\"],[\"latitudeInDegrees\",\"Latitude\",\"Latitude\"]],null],[2,\"\\n\\n\\t\"],[8,[32,1,[\"inputGroup\"]],[],[[\"@field\",\"@label\",\"@placeholder\"],[\"longitudeInDegrees\",\"Longitude\",\"Longitude\"]],null],[2,\"\\n\\n\\t\"],[8,[32,1,[\"actions\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"div\"],[12],[2,\"\\n\\t\\t\\t\"],[8,[32,2,[\"saveButton\"]],[],[[\"@isRunning\"],[[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,7]]]],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[8,[32,2,[\"cancelButton\"]],[],[[\"@cancelRoute\"],[\"authenticated.settings.airports\"]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/airport/template.hbs"
    }
  });

  _exports.default = _default;
});