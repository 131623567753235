define("web/ui/components/ui/auto-upgrade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/33sZNvr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-right text-muted text-xs\"],[12],[2,\"\\n\\tApp Version \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"appVersion\"]}",
    "meta": {
      "moduleName": "web/ui/components/ui/auto-upgrade/template.hbs"
    }
  });

  _exports.default = _default;
});