define("web/ui/components/quotes/active-form/sections/facility-form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/facility-form/update.graphql", "web/ui/components/quotes/active-form/facilities", "web/ui/components/quotes/active-form/sections/facility-form/save-comment.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _update, _facilities, _saveComment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  let FacilityForm = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = (0, _emberConcurrency.task)(function* () {
    const {
      quote
    } = this.args;
    let variables = {
      id: quote.id,
      content: this.commentContent
    };

    if (this.commentId) {
      variables.commentId = this.commentId;
    } else {
      variables.context = this.args.mode;
    }

    let result = yield this.apollo.mutate({
      mutation: _saveComment.default,
      variables
    }, "quotesCommentSave");

    if (this.commentId === null) {
      this.commentId = result.comment.id;
    }
  }).drop(), _dec9 = Ember._action, _dec10 = (0, _emberConcurrency.task)(function* () {
    yield this.apollo.mutate({
      mutation: _update.default,
      variables: this.variables
    }, "quotesUpdate");
  }).drop(), (_class = (_temp = class FacilityForm extends _component.default {
    get readOnlyNoFacility() {
      return this.readOnly && this.facility == null;
    }

    get readOnly() {
      return Ember.isBlank(this.args.readOnly) ? false : this.args.readOnly;
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "facility", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "commentContent", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "commentId", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "saveComment", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor6, this);
      const {
        facility,
        quote
      } = this.args;
      this.facility = facility ?? null;

      if (quote.comment) {
        const {
          content,
          id
        } = quote.comment;
        this.commentContent = content;
        this.commentId = id;
      }
    }

    handleSelect(facility) {
      this.setFacility(facility);
    }

    handleClear() {
      this.setFacility(null);
    }

    handleCommentsChange(e) {
      this.commentContent = e.target.value;
    }

    handleCommentSave() {
      this.saveComment.perform();
    }

    get commentChanges() {
      const {
        quote
      } = this.args;
      const {
        commentContent
      } = this;

      if (quote.comment) {
        return quote.comment.content != commentContent;
      } else {
        return Ember.isPresent(commentContent);
      }
    }

    setFacility(facility) {
      this.facility = facility;
      if (facility != null) this.update.perform();
    }

    handleSave() {
      this.update.perform();
    }

    get variables() {
      const {
        id
      } = this.args.quote;
      let output = {
        id,
        attributes: {}
      };
      output.attributes[`${this.args.mode}FacilityId`] = this.facility.id;
      output.attributes = { ...output.attributes,
        ...this.contactAttributes
      };
      return output;
    }

    get contactAttributes() {
      const {
        mode,
        quote
      } = this.args;
      let fields = (0, _facilities.facilityContactFieldsByType)(mode);
      return fields.reduce((accu, item) => {
        accu[item] = quote[item];
        return accu;
      }, {});
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "facility", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commentContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "commentId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSelect", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelect"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleClear", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleClear"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCommentsChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleCommentsChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCommentSave", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleCommentSave"), _class.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveComment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSave", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "update", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FacilityForm;
});