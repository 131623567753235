define("web/ui/components/quotes/form/footer/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {tracked} from "@glimmer/tracking"
  class QuotesFormFooter extends _component.default {}

  _exports.default = QuotesFormFooter;
});