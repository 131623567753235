define("web/ui/components/quotes/form/email-dialog-customer-quote/send-button/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _temp;

  const defaultButtonText = "Send";

  let _class = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    const {
      args: {
        quote: {
          id
        },
        mutation,
        emailList: {
          sendToMe,
          sendToRep,
          otherReps
        }
      }
    } = this;
    const variables = {
      id,
      attributes: {
        sendToMe,
        sendToRep,
        otherRepIds: otherReps
      }
    };
    const result = yield this.apollo.mutate({
      mutation,
      variables
    });
    alert("Your email has been sent");
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "sendEmail", _descriptor2, this);
    }

    get buttonText() {
      const {
        buttonText
      } = this.args;
      return buttonText === undefined ? defaultButtonText : buttonText;
    }

    handleSend() {
      if (this.args.disabled) return;
      this.sendEmail.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSend", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSend"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "sendEmail", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});