define("web/ui/components/forms/form/actions/save-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {tracked} from "@glimmer/tracking"
  const defaultPendingText = "Saving...";
  const defaultText = "Save";

  class FormsFormActionsSaveButton extends _component.default {
    get disabled() {
      return !this.args.isValid;
    }

    get submitPendingText() {
      return this.args.pendingText === undefined ? defaultPendingText : this.args.pendingText;
    }

  }

  _exports.default = FormsFormActionsSaveButton;
});