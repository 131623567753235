define("web/ui/components/forms/form/actions/save-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IHvUO5lg",
    "block": "{\"symbols\":[\"&default\",\"@isRunning\"],\"statements\":[[10,\"button\"],[15,\"disabled\",[32,0,[\"disabled\"]]],[14,0,\"btn btn_custom_2\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"defaultText\",\"if\",\"submitPendingText\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/actions/save-button/template.hbs"
    }
  });

  _exports.default = _default;
});