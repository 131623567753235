define("web/ui/forgot-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3WFcMpXu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row first_row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-md-8 col-md-offset-2\"],[12],[2,\"\\n\\n\\t\\t\"],[8,\"accounts/forms/forgot-password\",[],[[],[]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n\\t\"],[10,\"small\"],[12],[2,\"\\n\\t\\t\"],[10,\"strong\"],[12],[2,\"\\n\\t\\tNever mind, \\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[8,\"link-to\",[],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\tsend me back to the sign-in screen\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/forgot-password/template.hbs"
    }
  });

  _exports.default = _default;
});