define("web/ui/components/airports/lookup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z6uUM2FP",
    "block": "{\"symbols\":[\"result\",\"@placeholder\",\"@selectedId\"],\"statements\":[[11,\"input\"],[16,\"placeholder\",[31,[[30,[36,3],[[32,2],[32,2],\"Enter an airport code or city\"],null]]]],[16,2,[34,4]],[16,\"list\",[32,0,[\"listId\"]]],[24,\"autocomplete\",\"off\"],[24,0,\"form-control\"],[24,4,\"text\"],[4,[38,6],[\"input\",[30,[36,5],[[32,0,[\"handleSearch\"]]],null]],null],[4,[38,6],[\"change\",[30,[36,5],[[32,0,[\"handleSelect\"]]],null]],null],[4,[38,7],[[32,0,[\"handleInsert\"]],[32,0],[32,3]],null],[4,[38,8],[[32,0,[\"handleUpdate\"]],[32,0],[32,3]],null],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"datalist\"],[15,1,[32,0,[\"listId\"]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"option\"],[15,\"data-value\",[32,1,[\"id\"]]],[12],[1,[32,1,[\"code\"]]],[2,\" - \"],[1,[32,1,[\"city\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"searchResults\",\"-track-array\",\"each\",\"if\",\"selectedValue\",\"fn\",\"on\",\"did-insert\",\"did-update\"]}",
    "meta": {
      "moduleName": "web/ui/components/airports/lookup/template.hbs"
    }
  });

  _exports.default = _default;
});