define("web/ui/components/quotes/active-form/sections/case-notes/lab-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qH29hrH7",
    "block": "{\"symbols\":[\"@left\",\"@top\",\"@field\",\"@caseNote\"],\"statements\":[[10,\"span\"],[15,5,[31,[\"top: \",[32,2],\"px;left: \",[32,1],\"px;\"]]],[14,0,\"absolute lab-entry-display\"],[12],[2,\"\\n\\t\"],[1,[30,[36,0],[[32,4],[32,3]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/lab-entry/template.hbs"
    }
  });

  _exports.default = _default;
});