define("web/ui/components/quotes/active-form/sections/case-notes/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REeXf+0S",
    "block": "{\"symbols\":[\"@value\",\"@key\"],\"statements\":[[10,\"div\"],[14,5,\"margin-left: 1em;margin-right: 2em;\"],[12],[2,\"\\n\\t\"],[10,\"strong\"],[15,0,[31,[\"case-note-detail-key \",[30,[36,1],[[35,2],\"active\"],null],\" \",[30,[36,1],[[35,0],\"inactive\"],null]]]],[14,5,\"margin-right:4px;\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"text-underline\"],[14,5,\"color:#000;\"],[12],[2,\"\\n\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"inactive\",\"if\",\"booleanValue\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/detail/template.hbs"
    }
  });

  _exports.default = _default;
});