define("web/ui/authenticated/settings/users/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class2, _descriptor, _descriptor2, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showDisabled", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "filter", _descriptor2, this);
    }

    handleToggleDisabled(value) {
      this.showDisabled = value;
    }

    handleFilter(value) {
      this.filter = value;
    }

    get employees() {
      const {
        filter,
        showDisabled,
        model: {
          employees
        }
      } = this;
      let records = employees; // console.log(records,filter,showDisabled)

      if (!showDisabled) records = records.filter(item => item.active);

      if (!Ember.isEmpty(filter)) {
        let f = filter.toLowerCase();
        records = records.filter(item => {
          return item.firstName.toLowerCase().includes(f) || item.lastName.toLowerCase().includes(f) || item.email.toLowerCase().includes(f);
        });
      }

      return records;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "showDisabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "filter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleDisabled", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleDisabled"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleFilter", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFilter"), _class2.prototype)), _class2));

  _exports.default = _class;
});