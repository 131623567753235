define("web/constants/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customerTypes = void 0;
  const customerTypes = [{
    value: "operator",
    label: "Operator"
  }, {
    value: "broker",
    label: "Broker"
  }, {
    value: "federal",
    label: "Federal"
  }, {
    value: "assistance",
    label: "Assistance"
  }, {
    value: "caseManager",
    label: "Case Manager"
  }, {
    value: "direct",
    label: "Direct"
  }];
  _exports.customerTypes = customerTypes;
});