define("web/ui/components/forms/employee/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "web/gql/queries/employees/positions.graphql"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _positions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  let FormsCustomer = (_dec = Ember._action, _dec2 = Ember._action, (_class = (_temp = class FormsCustomer extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "positionsQuery", _positions.default);
    }

    loadPosition(element, [component]) {
      let {
        model
      } = component.args;
      if (Ember.isPresent(model.position)) model.position = model.position.id;
    }

    transformPosition(value) {
      if (value === undefined || value === null) return null;
      return {
        value: value.id,
        label: value.name
      };
    }

  }, _temp), ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadPosition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loadPosition"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transformPosition", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "transformPosition"), _class.prototype)), _class));
  _exports.default = FormsCustomer;
});