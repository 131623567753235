define("web/ui/components/tables/filters/button-group/button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {tracked} from "@glimmer/tracking"
  class TablesFiltersButtonGroupButton extends _component.default {
    get isSelected() {
      return this.args.selected.includes(this.args.value);
    }

  }

  _exports.default = TablesFiltersButtonGroupButton;
});