define("web/ui/components/users/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHzbMeeW",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[10,\"tr\"],[14,0,\"bg-white odd:bg-gray-100\"],[12],[2,\"\\n\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\n\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"authenticated.settings.users.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[32,1,[\"firstName\"]]],[2,\" \"],[1,[32,1,[\"lastName\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[[32,1,[\"role\"]]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\"],[1,[32,1,[\"email\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\"],[8,\"users/toggle-status-button\",[],[[\"@user\"],[[32,1]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize\"]}",
    "meta": {
      "moduleName": "web/ui/components/users/list/item/template.hbs"
    }
  });

  _exports.default = _default;
});