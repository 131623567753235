define("web/ui/components/forms/user/permissions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZWbKQkCp",
    "block": "{\"symbols\":[\"actions\",\"policy\",\"act\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,10],[[35,9,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,8],[[30,[36,7],[\"name\",[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[[32,2]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"\\n            \"],[11,\"input\"],[16,\"checked\",[30,[36,1],[[30,[36,0],[[32,0,[\"isSelected\"]]],null],[32,2],[32,3,[\"action\"]]],null]],[24,4,\"checkbox\"],[4,[38,2],[\"click\",[30,[36,0],[[32,0,[\"handleToggleSelection\"]],[32,2],[32,3,[\"action\"]]],null]],null],[12],[13],[2,\"\\n            \"],[1,[32,3,[\"action\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"compute\",\"on\",\"humanize\",\"-track-array\",\"each\",\"policies\",\"group-by\",\"-each-in\",\"loadPolicies\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/user/permissions/template.hbs"
    }
  });

  _exports.default = _default;
});