define("web/ui/authenticated/settings/review/new/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "ember-concurrency", "web/ui/authenticated/settings/review/new/mutation.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _emberConcurrency, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      name: this.name
    };
    yield this.apollo.mutate({
      mutation: _mutation.default,
      variables
    });
    this.transitionToRoute("authenticated.settings.review");
  }).drop(), (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "name", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor3, this);
    }

    handleSubmit(e) {
      e.preventDefault();

      if (Ember.isEmpty(this.name)) {
        alert('Please fill out a name for this review');
        return;
      }

      this.save.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSubmit", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSubmit"), _class2.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "save", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});