define("web/ui/components/forms/fields/weight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YH1LmwP+",
    "block": "{\"symbols\":[\"@field\",\"@model\",\"@onInput\"],\"statements\":[[8,\"forms/fields/converted\",[],[[\"@unitOfMeasure1\",\"@unitOfMeasure2\",\"@multiplier\",\"@value\",\"@label\",\"@field\",\"@onInput\"],[\"lbs\",\"kg\",0.454545455,[30,[36,0],[[32,2],[32,1]],null],\"Weight\",[32,1],[32,3]]],null]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/fields/weight/template.hbs"
    }
  });

  _exports.default = _default;
});