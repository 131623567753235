define("web/ui/components/quotes/active-form/sections/aircraft-crew/aircraft/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vycRLL8x",
    "block": "{\"symbols\":[\"@onRemove\",\"@employee\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n\\t\"],[10,\"td\"],[14,5,\"width:15%;\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-times-circle active fa-2x\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"td\"],[14,5,\"width:85%\"],[12],[2,\"\\n\\t\\t\"],[1,[32,2,[\"registration\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/aircraft-crew/aircraft/template.hbs"
    }
  });

  _exports.default = _default;
});