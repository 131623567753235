define("web/ui/authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Si/uukrY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/header\",[],[[],[]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"container main_container\"],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,5,\"margin-top:10px;margin-bottom:10px;\"],[12],[2,\"\\n\\t\\t\"],[8,\"ui/auto-upgrade\",[],[[],[]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/template.hbs"
    }
  });

  _exports.default = _default;
});