define("web/ui/components/forms/form/inputs/text-input/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "web/ui/components/forms/form/inputs/base-input"], function (_exports, _applyDecoratedDescriptor2, _component, _baseInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const defaultType = "text";
  let FormsFormTextInputGroup = (_dec = Ember._action, (_class = class FormsFormTextInputGroup extends _baseInput.default {
    get inputType() {
      if (this.args.type != undefined) return this.args.type;
      return defaultType;
    }

    handleInput(field, e) {
      this.args.onHandleInput(field, e.target.value);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = FormsFormTextInputGroup;
});