define("web/ui/components/tables/filters/button-group/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cp6A/wfY",
    "block": "{\"symbols\":[\"@value\",\"@onToggle\",\"&default\"],\"statements\":[[11,\"a\"],[16,0,[31,[\"btn btn_custom_2 btn-md cursor-pointer \",[30,[36,1],[[35,0],\"active\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,2],[32,1]],null]],null],[12],[18,3,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isSelected\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/tables/filters/button-group/button/template.hbs"
    }
  });

  _exports.default = _default;
});