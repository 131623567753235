define("web/ui/authenticated/customers/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0UmXupx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-xs-12 col-md-8\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h2\"],[12],[2,\"Edit Customer\"],[13],[2,\"\\n\\t\\t\\t\"],[8,\"forms/customer\",[],[[\"@model\",\"@onSubmit\",\"@saveIcon\",\"@isRunning\"],[[34,0],[30,[36,1],[[32,0,[\"handleSubmit\"]]],null],\"save\",[32,0,[\"save\",\"isRunning\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-xs-12 col-md-4\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"customers/reps/list\",[],[[\"@customerId\",\"@reps\"],[[34,0,[\"id\"]],[34,0,[\"reps\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/customers/show/template.hbs"
    }
  });

  _exports.default = _default;
});