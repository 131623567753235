define("web/helpers/hours-and-minutes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hoursAndMinutes([duration]) {
    if (duration != undefined) {
      let hours = Math.floor(duration / 60);
      let minutes = duration % 60;
      return `${hours} hrs ${minutes} min`;
    } else {
      return 0;
    }
  }

  var _default = Ember.Helper.helper(hoursAndMinutes);

  _exports.default = _default;
});