define("web/ui/components/ui/radio-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YS+woMdd",
    "block": "{\"symbols\":[\"option\",\"@onSelect\",\"@selected\",\"@options\"],\"statements\":[[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"ui/radio-buttons/radio-button\",[],[[\"@option\",\"@onSelect\",\"@selected\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "web/ui/components/ui/radio-buttons/template.hbs"
    }
  });

  _exports.default = _default;
});