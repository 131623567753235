define("web/ui/components/quotes/active-form/sections/case-notes/details/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.summaryTemplate = void 0;
  const summaryTemplate = {
    groups: [{
      name: "Transport Note",
      widthClass: "w-full",
      attributes: [{
        field: "transport",
        label: ""
      }]
    }, // {
    // 	name: "PMH",
    // 	attributes: [
    // 		{
    // 			field: "pmh_cad",
    // 			label: "CAD"
    // 		},
    // 		{
    // 			field: "pmh_copd",
    // 			label: "COPD"
    // 		},
    // 		{
    // 			field: "pmh_dementia",
    // 			label: "Dementia"
    // 		},
    // 		{
    // 			field: "pmh_dm",
    // 			label: "DM"
    // 		},
    // 		{
    // 			field: "pmh_htn",
    // 			label: "HTN"
    // 		},
    // 		{
    // 			field: "pmh_hyperlipidemia",
    // 			label: "Hyperlipidemia"
    // 		}
    // 	]
    // },
    {
      name: "PMH",
      attributes: [{
        field: "pmh",
        label: ""
      }]
    }, {
      name: "Allergies",
      attributes: [{
        field: "allergies",
        label: ""
      }]
    }, {
      name: "Neuro",
      attributes: [{
        field: "neuro",
        label: ""
      }]
    }, {
      name: "Respiratory",
      attributes: [{
        field: "resp_ra",
        label: "RA",
        dataType: "boolean"
      }, {
        field: "resp_nc",
        label: "NC"
      }, {
        field: "resp_venti",
        label: "VENTI"
      }, {
        field: "resp_nrbm",
        label: "NRBM"
      }, {
        field: "resp_ett",
        label: "ETT"
      }, {
        field: "resp_trach",
        label: "TRACH"
      }, {
        field: "resp_notes",
        label: ""
      }]
    }, {
      name: "Ventilator",
      attributes: [{
        field: "vent_ac",
        label: "A/C",
        dataType: "boolean"
      }, {
        field: "vent_bipap",
        label: "BIPAP",
        dataType: "boolean"
      }, {
        field: "vent_cpap",
        label: "CPAP",
        dataType: "boolean"
      }, {
        field: "vent_tv",
        label: "TV"
      }, {
        field: "vent_rate",
        label: "RATE"
      }, {
        field: "vent_fiotwo",
        label: "FIO2"
      }, {
        field: "vent_peep",
        label: "PEEP"
      }]
    }, {
      name: "Cardiac",
      attributes: [{
        field: "cardiac",
        label: ""
      }]
    }, {
      name: "Vitals",
      attributes: [{
        field: "vitals_bp",
        label: "BP"
      }, {
        field: "vitals_hr",
        label: "HR"
      }, {
        field: "vitals_rr",
        label: "RR"
      }, {
        field: "vitals_sat",
        label: "SAT"
      }, {
        field: "vitals_rhythm",
        label: "RHYTHM"
      }, {
        field: "vitals_temp",
        label: "TEMP"
      }]
    }, {
      name: "IV Access",
      attributes: [{
        field: "iv_central",
        label: "Central",
        dataType: "boolean"
      }, {
        field: "iv_picc",
        label: "PICC",
        dataType: "boolean"
      }, {
        field: "iv_piv",
        label: "PIV",
        dataType: "boolean"
      }, {
        field: "iv_art",
        label: "ART",
        dataType: "boolean"
      }, {
        field: "iv_location",
        label: "Location"
      }, {
        field: "iv_size",
        label: "Size"
      }]
    }, {
      name: "Meds/Drips",
      attributes: [{
        field: "meds_drips",
        label: ""
      }]
    }, {
      name: "Abdominal",
      attributes: [{
        field: "diet_ngt",
        label: "NGT",
        dataType: "boolean"
      }, {
        field: "diet_ogt",
        label: "OGT",
        dataType: "boolean"
      }, {
        field: "diet_gtube",
        label: "GTUBE",
        dataType: "boolean"
      }, {
        field: "diet_tf",
        label: "TF"
      }, {
        field: "diet_notes",
        label: ""
      }]
    }, {
      name: "GU",
      attributes: [{
        field: "gu_voids",
        label: "Voids",
        dataType: "boolean"
      }, {
        field: "gu_incontinent",
        label: "Incontinent",
        dataType: "boolean"
      }, {
        field: "gu_foley",
        label: "Foley",
        dataType: "boolean"
      }, {
        field: "gu_condom_cath",
        label: "Condom Cath",
        dataType: "boolean"
      }, {
        field: "gu_notes",
        label: ""
      }]
    }, {
      name: "Skin",
      attributes: [{
        field: "skin",
        label: ""
      }]
    }, {
      name: "Misc",
      attributes: [{
        field: "misc",
        label: ""
      }]
    }]
  };
  _exports.summaryTemplate = summaryTemplate;
});