define("web/ui/components/accounts/forms/login/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  let AccountsLoginForm = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class AccountsLoginForm extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "username", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "password", _descriptor2, this);
    }

    async handleSubmit(e) {
      e.preventDefault();
      await this.args.onSubmit(this.username, this.password);
    }

    handleInput(field, e) {
      this[field] = e.target.value;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "username", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "password", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = AccountsLoginForm;
});