define("web/ui/components/quotes/delete-button/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/gql/mutations/quotes/destroy.graphql", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _destroy, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let QuotesDeleteButton = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* () {
    let {
      id
    } = this.quote;
    yield this.apollo.mutate({
      mutation: _destroy.default,
      variables: {
        id
      }
    });
    this.gotoQuotesIndex();
  }).drop(), (_class = (_temp = class QuotesDeleteButton extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "destroy", _descriptor3, this);
    }

    get quote() {
      return this.args.quote;
    }

    handleDelete() {
      let confirmed = confirm("Are you sure you want to delete this quote?");
      if (!confirmed) return;
      this.destroy.perform();
    }

    gotoQuotesIndex() {
      this.router.transitionTo("authenticated.quotes");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleDelete", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleDelete"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "destroy", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QuotesDeleteButton;
});