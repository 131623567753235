define("web/ui/components/airports/list/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/airports/list/search.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  const searchTimeout = 500;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(searchTimeout);
    yield this.doSearch.perform();
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      code: this.searchValue
    };
    const result = yield this.apollo.query({
      query: _search.default,
      variables,
      fetchPolicy: "network-only"
    }, "airports");
    this.airports = result;
  }).restartable(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "searchValue", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "airports", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "startSearch", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "doSearch", _descriptor5, this);
    }

    handleSearch(e) {
      this.searchValue = e.target.value;
      this.startSearch.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "searchValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "airports", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleSearch", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearch"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "startSearch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "doSearch", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});