define("web/ui/authenticated/settings/facilities/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b/ufihZ4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Edit a Facility\"],[13],[2,\"\\n\"],[8,\"forms/facility\",[],[[\"@returnToQuote\",\"@returnMode\",\"@model\",\"@cancelRoute\"],[[32,0,[\"returnToQuote\"]],[32,0,[\"returnMode\"]],[34,0],\"authenticated.settings.facilities\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/facilities/show/template.hbs"
    }
  });

  _exports.default = _default;
});