define("web/ui/components/quotes/status-changer/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TPj/oLaU",
    "block": "{\"symbols\":[\"@icon\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"btn btn-default \",[30,[36,1],[[35,0],\"active\"],null]]]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleToggleStatus\"]]],null]],null],[12],[2,\"\\n\\t\"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,1]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/status-changer/button/template.hbs"
    }
  });

  _exports.default = _default;
});