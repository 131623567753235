define("web/ui/components/forms/save-notice/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultSavingMessage = "Saving...";
  const defaultMessage = "Changes have not been saved. Autosave in progress";

  class SaveNotice extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "defaultMessage", defaultMessage);
    }

    get savingMessage() {
      return this.args.savingMessage || defaultSavingMessage;
    }

  }

  _exports.default = SaveNotice;
});