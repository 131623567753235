define("web/ui/components/quotes/active-form/navigation/standard/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2xNRdat",
    "block": "{\"symbols\":[\"@section\",\"@activeSection\",\"@onChangeSection\",\"&default\"],\"statements\":[[10,\"li\"],[15,0,[31,[\"cursor-pointer \",[30,[36,1],[[30,[36,0],[[32,2],[32,1]],null],\"active\"],null]]]],[12],[2,\"\\n\\t\"],[11,\"a\"],[4,[38,3],[\"click\",[30,[36,2],[[32,3],[32,1]],null]],null],[12],[18,4,null],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/navigation/standard/item/template.hbs"
    }
  });

  _exports.default = _default;
});