define("web/ui/components/quotes/form/customer-details/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  const pendingStatus = "pending";
  let CustomerDetails = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class CustomerDetails extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "caseNumber", _descriptor, this);
    }

    handleCustomerChange(value) {
      this.setFieldValue("customerId", value.id);
      this.update();
    }

    handleCaseNumberInput(e) {
      this.setFieldValue("caseNumber", e.target.value);
      this.update();
    }

    setFieldValue(field, value) {
      this.quote[field] = value;
    }

    update() {
      this.args.onUpdate();
    }

    handleRepChange(value) {
      this.setFieldValue("repId", value.id);
      Ember.set(this.quote, "rep", value);
      this.update();
    }

    get quote() {
      return this.args.quote;
    }

    get queryVariables() {
      return this.quote.status === pendingStatus ? {
        activeOnly: true
      } : {};
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "caseNumber", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.caseNumber;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCustomerChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleCustomerChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCaseNumberInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleCaseNumberInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleRepChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleRepChange"), _class.prototype)), _class));
  _exports.default = CustomerDetails;
});