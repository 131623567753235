define("web/ui/components/quotes/active-form/section-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7TI2aKr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,2],[[30,[36,1],[[35,0]],null]],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"humanize\",\"titleize\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/section-title/template.hbs"
    }
  });

  _exports.default = _default;
});