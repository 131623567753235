define("web/ui/components/quotes/form/operations/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  let QuotesFormOpertaions = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class QuotesFormOpertaions extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
    }

    get quote() {
      return this.args.quote;
    }

    handleClose() {
      this.gotoQuotesIndex();
    }

    gotoQuotesIndex() {
      this.router.transitionTo("authenticated.quotes");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleClose", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleClose"), _class.prototype)), _class));
  _exports.default = QuotesFormOpertaions;
});