define("web/ui/components/quotes/active-form/sections/aircraft-crew/employee-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ilxvHwIZ",
    "block": "{\"symbols\":[\"option\",\"@onChange\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@onChange\"],[[34,0],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"firstName\"]]],[2,\" \"],[1,[32,1,[\"lastName\"]]],[2,\" - \"],[1,[32,1,[\"jobTitle\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"employees\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/aircraft-crew/employee-select/template.hbs"
    }
  });

  _exports.default = _default;
});