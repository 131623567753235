define("web/ui/components/ui/auto-upgrade/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "web/utils/fetch", "web/config/environment", "ember-concurrency", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fetch, _environment, _emberConcurrency, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _temp;

  const normalPoll = 10000;
  const reminderPoll = 180000;
  const versionRegExp = /\d+[.]\d+[.]\d+/; // Match any number of 3 sections of digits separated by .

  let _class = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)(function* () {
    try {
      const url = `/latest.txt?timestamp=${new Date().getTime()}`;
      const result = yield (0, _fetch.fetchText)(url);
      const latestVersion = result.trim();
      if (this.appVersion != latestVersion) this.notify();else this.restartChecker(normalPoll);
    } catch (e) {
      this.restartChecker(normalPoll);
    }
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "checker", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "checkVersion", _descriptor2, this);
      this.startVersionCheck();
    }

    willDestroy() {
      this.stopVersionCheck();

      this._super(...arguments);
    }

    startVersionCheck() {
      if (_environment.default.environment === "development") return;
      this.checkVersion.perform();
    }

    stopVersionCheck() {
      if (this.checker != null) {
        Ember.run.cancel(checker);
        this.checker = null;
      }
    }

    get appVersion() {
      return _environment.default.APP.version.match(versionRegExp)[0];
    }

    notify() {
      const getNewVersion = confirm("There is a new version of the software available. Would you like to get it now?");
      if (getNewVersion) document.location.reload(true);else this.restartChecker(reminderPoll);
    }

    restartChecker(pollingInterval) {
      const checker = Ember.run.later(this, () => {
        this.checkVersion.perform();
      }, pollingInterval);
      this.checker = checker;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "checker", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "checkVersion", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});