define("web/ui/components/quotes/form/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GLY6qFrg",
    "block": "{\"symbols\":[\"@quote\"],\"statements\":[[10,\"nav\"],[14,0,\"navbar navbar-inverse navbar-fixed-bottom\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flight_summary_footer container\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"row\"],[14,5,\"padding:5px;\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-xs-2 text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[2,\"\\n\\t\\t\\t\\t\\tMiles \"],[10,\"br\"],[12],[13],[10,\"span\"],[14,0,\"flight_summary_stat\"],[12],[1,[32,1,[\"totalMileage\"]]],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-xs-3 text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[2,\"\\n\\t\\t\\t\\t\\tFlight Time\"],[10,\"br\"],[12],[13],[10,\"span\"],[14,0,\"flight_time_stat\"],[12],[1,[30,[36,0],[[32,1,[\"flightTime\"]]],null]],[13],[2,\"\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-xs-3 text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[2,\"\\n\\t\\t\\t\\t\\tPatient Time\"],[10,\"br\"],[12],[13],[10,\"span\"],[14,0,\"flight_time_stat\"],[12],[2,\" \"],[1,[30,[36,0],[[32,1,[\"patientFlightTime\"]]],null]],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-xs-4 text-center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[2,\"\\n\\t\\t\\t\\t\\tCalculated $\"],[10,\"br\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"flight_summary_stat\"],[12],[1,[30,[36,1],[[32,1,[\"calculatedPrice\"]]],null]],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hours-and-minutes\",\"format-money\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/footer/template.hbs"
    }
  });

  _exports.default = _default;
});