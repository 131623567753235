define("web/ui/components/employees/review-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ByVBxfaZ",
    "block": "{\"symbols\":[\"position\",\"@reviewItem\",\"@positions\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\"],[10,\"strong\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t  \"],[8,\"employees/review-item/position-assignment-item\",[],[[\"@reviewItem\",\"@position\"],[[32,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "web/ui/components/employees/review-item/template.hbs"
    }
  });

  _exports.default = _default;
});