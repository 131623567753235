define("web/ui/components/ui/date-time-display/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const timeAndDateFormat = "D MMM YYYY, HH:mm ZZ";
  const dateFormat = "D MMM YYYY";

  class _default extends _component.default {
    get format() {
      if (this.args.dateOnly != undefined && this.args.dateOnly) {
        return dateFormat;
      } else {
        return timeAndDateFormat;
      }
    }

  }

  _exports.default = _default;
});