define("web/ui/components/ui/radio-buttons/radio-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get option() {
      return this.args.option;
    }

    get simpleOption() {
      return typeof this.option != "object";
    }

    get selected() {
      return this.args.selected.includes(this.value);
    }

    get value() {
      return this.simpleOption ? this.option : this.option.value;
    }

    get label() {
      return this.simpleOption ? this.option : this.option.label;
    }

  }

  _exports.default = _default;
});