define("web/ui/components/forms/facility/contact-info/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  const sendingMode = "sending";
  const bedsideOptions = ["Yes", "No", "M/C"];
  let FacilityContactInfo = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class FacilityContactInfo extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "isShowingSecondaryContactForm", _descriptor, this);
      (0, _defineProperty2.default)(this, "bedsideOptions", bedsideOptions.map(item => {
        return {
          value: item,
          label: item
        };
      }));
    }

    handleOpenSecondaryFormToggle() {
      this.isShowingSecondaryContactForm = !this.isShowingSecondaryContactForm;
    }

    get showSecondaryContact() {
      return this.hasSecondaryContactInfo || this.isShowingSecondaryContactForm;
    }

    get hasSecondaryContactInfo() {
      const {
        quote,
        mode
      } = this.args;
      const contactName = quote[`${mode}ContactNameSecondary`];
      const contactTitle = quote[`${mode}ContactTitleSecondary`];
      const contactPhone = quote[`${mode}ContactPhoneSecondary`];
      return Ember.isPresent(contactName) || Ember.isPresent(contactTitle) || Ember.isPresent(contactPhone);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isShowingSecondaryContactForm", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleOpenSecondaryFormToggle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleOpenSecondaryFormToggle"), _class.prototype)), _class));
  _exports.default = FacilityContactInfo;
});