define("web/ui/components/forms/facility/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/forms/facility/save.graphql", "web/ui/components/forms/facility/toggle.graphql", "web/utils/attributes-from-model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _save, _toggle, _attributesFromModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  const requiredFields = ["name"];
  let FormsFacility = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)(function* () {
    const {
      returnToQuote,
      returnMode
    } = this.args;
    let variables = this.mutationVariables;
    let result = yield this.apollo.mutate({
      mutation: _save.default,
      variables
    }, "facilitiesSave");

    if (result.error === undefined) {
      if (this.args.onComplete === undefined) {
        if (returnToQuote != undefined) this.router.transitionTo("authenticated.quotes.show", returnToQuote, {
          queryParams: {
            section: `${returnMode}-facility`
          }
        });else this.router.transitionTo("authenticated.settings.facilities");
      } else this.args.onComplete(result);
    }
  }).drop(), _dec6 = (0, _emberConcurrency.task)(function* () {
    let variables = {
      id: this.model.id
    };
    let result = yield this.apollo.mutate({
      mutation: _toggle.default,
      variables
    }, "facilitiesSave");
  }).drop(), (_class = (_temp = class FormsFacility extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _defineProperty2.default)(this, "requiredFields", requiredFields);
      (0, _initializerDefineProperty2.default)(this, "save", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "toggleActive", _descriptor4, this);
    }

    handleSave() {
      this.save.perform();
    }

    handleNotAFacilityClick(e) {
      let {
        model
      } = this.args;
      model.residence = e.target.checked;
    }

    handleToggleActiveStatus() {
      this.toggleActive.perform();
    }

    get model() {
      return this.args.model;
    }

    get mutationVariables() {
      let {
        id
      } = this.model;
      let attributes = this.modelAttributes;
      return {
        id,
        attributes
      };
    }

    get modelAttributes() {
      return (0, _attributesFromModel.attributesFromModel)(this.model, "name", "addressOne", "addressTwo", "city", "stateProvince", "postalCode", "country", "residence", "phoneNumber");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSave", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleNotAFacilityClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleNotAFacilityClick"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleToggleActiveStatus", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggleActiveStatus"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleActive", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormsFacility;
});