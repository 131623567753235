define("web/ui/authenticated/settings/users/personnel/show/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency", "web/gql/mutations/employees/update.graphql", "web/ui/authenticated/settings/users/personnel/show/index/toggle-active.graphql", "ember-apollo-client", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency, _update, _toggleActive, _emberApolloClient, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let SettingsPersonnelShow = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* () {
    const {
      id
    } = this.model;
    const result = yield this.apollo.mutate({
      mutation: _toggleActive.default,
      variables: {
        id
      }
    });
  }).drop(), _dec4 = (0, _emberConcurrency.task)(function* () {
    let model = this.model;
    let variables = {
      id: model.id,
      attributes: {
        firstName: model.firstName,
        lastName: model.lastName,
        dateOfBirth: (0, _moment.default)(model.dateOfBirth).toDate(),
        address: model.address,
        addressTwo: model.addressTwo,
        city: model.city,
        state: model.state,
        zipCode: model.zipCode,
        cellPhone: model.cellPhone,
        notes: model.notes,
        email: model.email,
        jobTitle: model.jobTitle,
        passportNumber: model.passportNumber,
        passportExpiresOn: (0, _moment.default)(model.passportExpiresOn).toDate(),
        positionId: Number(model.position)
      }
    };
    yield this.apollo.mutate({
      mutation: _update.default,
      variables
    });
  }).drop(), (_class = (_temp = class SettingsPersonnelShow extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "toggleActiveStatus", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor3, this);
    }

    handleToggleActiveStatus() {
      this.toggleActiveStatus.perform();
    }

    handleSubmit() {
      this.update.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleToggleActiveStatus", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggleActiveStatus"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleActiveStatus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "update", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SettingsPersonnelShow;
});