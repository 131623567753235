define("web/ui/components/users/toggle-status-button/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency", "web/ui/components/users/toggle-status-button/mutation.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency, _mutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _temp;

  const enabledText = "Disable Account";
  const enabledClasses = "btn-danger";
  const disabledText = "Enable Account";
  const disabledClasses = "btn-primary";

  let _class = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    const variables = {
      id: this.user.id
    };
    const result = yield this.apollo.mutate({
      mutation: _mutation.default,
      variables
    }, "accountsToggleDisable");

    if (result.error != undefined) {
      alert(result.error.message);
    }
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "toggle", _descriptor2, this);
    }

    handleClick() {
      this.toggle.perform();
    }

    get buttonClass() {
      return this.enabled ? enabledClasses : disabledClasses;
    }

    get buttonText() {
      return this.enabled ? enabledText : disabledText;
    }

    get enabled() {
      return !this.user.disabled;
    }

    get user() {
      return this.args.user;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleClick", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "toggle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});