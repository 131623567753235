define("web/ui/components/picklists/aircraft/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3MyYeGCf",
    "block": "{\"symbols\":[\"option\",\"@selected\",\"@onChange\"],\"statements\":[[8,\"picklists/picklist\",[],[[\"@query\",\"@queryResultName\",\"@selected\",\"@onChange\"],[[34,0],\"aircrafts\",[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"registration\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"query\"]}",
    "meta": {
      "moduleName": "web/ui/components/picklists/aircraft/template.hbs"
    }
  });

  _exports.default = _default;
});