define("web/ui/components/quotes/delete-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uGoyhdxc",
    "block": "{\"symbols\":[\"@format\"],\"statements\":[[2,\"\\n\\t\\t\"],[11,\"a\"],[16,0,[31,[\"btn btn-danger \",[30,[36,1],[[30,[36,0],[[32,1],\"block\"],null],\"btn-block\"],null],\" cursor-pointer\"]]],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleDelete\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/delete-button/template.hbs"
    }
  });

  _exports.default = _default;
});