define("web/ui/components/quotes/form/misc-fee/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fLawwjbO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[14,5,\"padding-top:10px\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-6\"],[12],[2,\"\\n\\t\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,0]],[24,\"placeholder\",\"Misc\"],[24,4,\"text\"],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],\"label\"],null]],null],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-xs-6\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[14,0,\"sr-only\"],[12],[2,\"Amount (in dollars)\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"input-group-addon\"],[12],[2,\"$\"],[13],[2,\"\\n\\t\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[34,3]],[24,\"placeholder\",\"Amount\"],[24,4,\"text\"],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],\"amount\"],null]],null],[12],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"input-group-addon\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[11,\"a\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"clearForm\"]]],null]],null],[12],[10,\"i\"],[14,0,\"fa fa-times-circle\"],[12],[13],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"fn\",\"on\",\"amount\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/misc-fee/template.hbs"
    }
  });

  _exports.default = _default;
});