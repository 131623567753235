define("web/ui/components/quotes/active-form/facilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadQuoteFieldsFromQueryResult = loadQuoteFieldsFromQueryResult;
  _exports.sendingFacilityFields = sendingFacilityFields;
  _exports.facilityContactFieldsByType = facilityContactFieldsByType;
  _exports.baseFacilityContactFields = void 0;
  const baseFacilityContactFields = ["Doctor", "At", "RoomNumber", "DistanceFromAirport", "ContactName", "ContactTitle", "ContactPhone", "ContactNameSecondary", "ContactTitleSecondary", "ContactPhoneSecondary", "GroundTransportation", "Bedside"];
  _exports.baseFacilityContactFields = baseFacilityContactFields;
  const facilityTypeSending = "sending";
  const facilityTypeReceiving = "receiving";

  function loadQuoteFieldsFromQueryResult(quote, queryResult, formType) {
    facilityContactFieldsByType(formType).forEach(field => {
      Ember.set(quote, field, queryResult[field]);
    });
  }

  function sendingFacilityFields() {
    return facilityContactFieldsByType(facilityTypeSending);
  }

  function facilityContactFieldsByType(typeOfFacility) {
    return baseFacilityContactFields.map(field => {
      return `${typeOfFacility}${field}`;
    });
  }
});