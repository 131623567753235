define("web/ui/components/quotes/form/email-dialog-customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uPg5ZeBY",
    "block": "{\"symbols\":[\"@quote\",\"@me\",\"&default\"],\"statements\":[[18,3,[[30,[36,7],null,[[\"body\",\"sendToMe\",\"sendToRep\",\"otherReps\",\"emailList\",\"noneSelected\",\"sendButton\"],[[30,[36,2],[\"quotes/form/email-dialog-customer/body\"],[[\"me\",\"sendToMe\",\"sendToRep\",\"quote\",\"onSelectionsChange\"],[[32,2],[35,5],[35,4],[32,1],[30,[36,6],[[32,0,[\"handleOnSelectionsChange\"]]],null]]]],[35,5],[35,4],[35,3],[32,0,[\"emailList\"]],[35,1],[30,[36,2],[\"quotes/form/email-dialog-customer/send-button\"],[[\"disabled\",\"emailList\",\"quote\"],[[35,1],[35,0],[32,1]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"emailList\",\"noneSelected\",\"component\",\"otherReps\",\"sendToRep\",\"sendToMe\",\"fn\",\"hash\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/email-dialog-customer/template.hbs"
    }
  });

  _exports.default = _default;
});