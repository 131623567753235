define("web/ui/components/forms/form/actions/cancel-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oE6QLgX7",
    "block": "{\"symbols\":[\"&default\",\"@onCancel\",\"@cancelRoute\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"or \"],[8,\"link-to\",[],[[\"@route\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"or \"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"text\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/actions/cancel-button/template.hbs"
    }
  });

  _exports.default = _default;
});