define("web/ui/authenticated/settings/facilities/index/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "web/ui/authenticated/settings/facilities/index/query.graphql", "web/ui/authenticated/settings/facilities/index/pagination.graphql", "web/constants"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _query, _pagination, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _temp;

  let _class = (_class2 = (_temp = class _class2 extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _defineProperty2.default)(this, "queryParams", {
        activeOnly: {
          refreshModel: true,
          replace: false
        },
        page: {
          refreshModel: true,
          replace: false
        },
        nameFilter: {
          refreshModel: true,
          replace: false
        }
      });
    }

    async model(params) {
      const {
        activeOnly,
        page,
        nameFilter
      } = params;
      const {
        before,
        after
      } = await this.apollo.query({
        query: _pagination.default,
        variables: {
          page,
          pageSize: _constants.facilitiesPageSize
        },
        fetchPolicy: "network-only"
      }, "cursors");
      const variables = {
        before,
        after,
        pageSize: _constants.facilitiesPageSize,
        active: activeOnly,
        residence: false,
        nameFilter
      };
      const result = await this.apollo.watchQuery({
        query: _query.default,
        variables,
        fetchPolicy: "network-only"
      }, "pagedFacilities");
      window.scrollTo(0, 0);
      return result;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);

  _exports.default = _class;
});