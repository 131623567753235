define("web/ui/components/ui/pagination/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get totalPages() {
      const {
        pageSize,
        model
      } = this.args;
      return Math.ceil(model.collection.totalCount / pageSize);
    }

  }

  _exports.default = _default;
});