define("web/ui/authenticated/settings/aircrafts/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r+0bOWJy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,5,\"padding-bottom:20px;\"],[12],[2,\"\\n\\n\\t\"],[10,\"h2\"],[12],[2,\"Edit \"],[1,[35,0,[\"registration\"]]],[13],[2,\"\\n\\n\\t\"],[8,\"forms/aircraft\",[],[[\"@model\",\"@onSubmit\",\"@saveIcon\",\"@isRunning\",\"@onDelete\"],[[34,0],[30,[36,1],[[32,0,[\"handleSubmit\"]]],null],\"save\",[32,0,[\"update\",\"isRunning\"]],[30,[36,1],[[32,0,[\"handleDeleteAircraft\"]]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/aircrafts/show/template.hbs"
    }
  });

  _exports.default = _default;
});