define("web/ui/components/quotes/form/email-dialog-customer-quote/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2FgscIo3",
    "block": "{\"symbols\":[\"otherRep\",\"@sendToRep\",\"@quote\",\"@sendToMe\",\"@me\"],\"statements\":[[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[32,4]]],null],[2,\"\\n\\t\\tSend this email to me (\"],[1,[32,5]],[2,\")\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[32,3,[\"rep\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[32,2]]],null],[2,\"\\n\\t\\tSend this email to the rep (\"],[1,[32,3,[\"rep\",\"name\"]]],[2,\" - \"],[1,[32,3,[\"rep\",\"email\"]]],[2,\")\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"Send to other reps\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"input\",[[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"handleOtherRepClick\"]],[32,1]],null]],null]],[[\"@type\"],[\"checkbox\"]],null],[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\" - \"],[1,[32,1,[\"email\"]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"otherReps\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/email-dialog-customer-quote/body/template.hbs"
    }
  });

  _exports.default = _default;
});