define("web/ui/components/picklists/customer/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component", "web/gql/queries/customers/customers.graphql"], function (_exports, _defineProperty2, _component, _customers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {tracked} from "@glimmer/tracking"
  class CustomerPicklist extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "query", _customers.default);
    }

  }

  _exports.default = CustomerPicklist;
});