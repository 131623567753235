define("web/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "web/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route("login");
    this.route("forgot-password");
    this.route("users", function () {
      this.route("password", function () {
        this.route("edit");
      });
      this.route('confirmation');
    });
    this.route("authenticated", {
      path: "/"
    }, function () {
      this.route("settings", function () {
        this.route("index", {
          path: "/"
        });
        this.route("flight-update-template");
        this.route("fees", function () {});
        this.route("facilities", function () {
          this.route("index", {
            path: "/"
          });
          this.route("new");
          this.route("show", {
            path: "/:id"
          });
        });
        this.route("aircrafts", function () {
          this.route("index", {
            path: "/"
          });
          this.route("new");
          this.route("show", {
            path: "/:id"
          });
        }); //airports

        this.route("airports", function () {
          this.route("index", {
            path: "/"
          });
          this.route("new");
          this.route("show", {
            path: "/:id"
          });
        });
        this.route("review", function () {
          this.route("index", {
            path: "/"
          });
          this.route("new");
        });
        this.route("users", function () {
          this.route("index", {
            path: "/"
          });
          this.route("show", {
            path: "/:id"
          }, function () {
            this.route('review');
            this.route("personnel", function () {});
            this.route("permissions");
          });
          this.route("new");
          this.route("personnel", function () {
            this.route("show", {
              path: "/:id"
            }, function () {
              this.route("review");
            }); // this.route("new")
          });
        });
      }); //quotes

      this.route("quotes", function () {
        this.route("index", {
          path: "/"
        });
        this.route("show", {
          path: "/:id"
        }, function () {});
      }); //customers

      this.route("customers", function () {
        this.route("index", {
          path: "/"
        });
        this.route("show", {
          path: "/:id"
        });
        this.route("new");
      });
    });
  });
});