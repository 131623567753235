define("web/authenticators/graphql", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-simple-auth/authenticators/base", "graphql-tag"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _base, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  const mutation = (0, _graphqlTag.default)`
  mutation login($email: String!, $password: String!) {
    login: login(email: $email, password: $password) {
      user {
        id
        email
        token
        role
      }
      error
    }
  }
`;
  let GraphQLAuthenticator = (_dec = Ember.inject.service, (_class = (_temp = class GraphQLAuthenticator extends _base.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
    }

    async restore(data) {
      return data;
    }

    async authenticate(identification, password) {
      const result = await this.apollo.mutate({
        mutation,
        variables: {
          email: identification,
          password
        }
      }, 'login');

      if (result.error) {
        throw new Error(result.error);
      }

      return {
        token: result.user.token,
        email: result.user.email,
        role: result.user.role
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GraphQLAuthenticator;
});