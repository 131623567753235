define("web/ui/components/forms/form/inputs/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7h4c76QA",
    "block": "{\"symbols\":[\"@field\",\"@model\",\"@label\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[11,\"input\"],[16,\"checked\",[30,[36,0],[[32,2],[32,1]],null]],[24,4,\"checkbox\"],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]],[32,1]],null]],null],[12],[13],[2,\"\\n\\t\\t\"],[1,[32,3]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/inputs/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});