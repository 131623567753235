define("web/ui/authenticated/settings/fees/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ylug85U",
    "block": "{\"symbols\":[\"fee\"],\"statements\":[[10,\"h2\"],[12],[2,\"Fee Management\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"forms/fee\",[],[[\"@fee\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/fees/index/template.hbs"
    }
  });

  _exports.default = _default;
});