define("web/ui/components/forms/form/inputs/no-cal-date-picker/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  let FormsFormInputsDatePicker = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class FormsFormInputsDatePicker extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "errantValue", _descriptor2, this);

      try {
        if (Ember.isPresent(this.model[this.field])) {
          this.value = (0, _moment.default)(this.model[this.field]);
        }
      } catch (e) {
        console.log(e.message);
      }
    }

    get allowBlanks() {
      return this.args.allowBlanks === undefined || this.args.allowBlanks;
    }

    get inputValue() {
      if (this.value) {
        if (this.value._isValid) return this.value.format("MM/DD/YYYY");else return this.errantValue;
      } else {
        return "";
      }
    }

    get model() {
      return this.args.model;
    }

    get field() {
      return this.args.field;
    }

    handleInput(e) {
      let {
        field
      } = this.args;
      this.value = (0, _moment.default)(e.target.value);

      if (this.allowBlanks && Ember.isEmpty(e.target.value) || this.value._isValid) {
        this.args.onHandleInput(field, this.value);
      } else {
        this.errantValue = e.target.value;
      }
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "errantValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = FormsFormInputsDatePicker;
});