define("web/services/apollo", ["exports", "ember-apollo-client/services/apollo", "apollo-link-context", "apollo-link-error", "apollo-upload-client", "apollo-cache-inmemory", "fetch"], function (_exports, _apollo, _apolloLinkContext, _apolloLinkError, _apolloUploadClient, _apolloCacheInmemory, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fragmentMatcher = new _apolloCacheInmemory.IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [] // no types provided

      }
    }
  });

  var _default = _apollo.default.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    overrideToken: null,

    setOverrideToken(token) {
      Ember.set(this, 'overrideToken', token);
    },

    clientOptions: Ember.computed(function () {
      return {
        link: this.get("link"),
        cache: new _apolloCacheInmemory.InMemoryCache({
          fragmentMatcher
        })
      };
    }),
    link: Ember.computed(function () {
      let httpLink = (0, _apolloUploadClient.createUploadLink)({
        uri: this.options.apiURL,
        fetch: _fetch.default
      });
      let authLink = (0, _apolloLinkContext.setContext)((request, context) => {
        let token;

        if (this.overrideToken) {
          token = this.overrideToken;
        } else {
          token = this.get('session.data.authenticated.token');
        }

        let headers = {};

        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }

        return {
          headers
        };
      });
      const resetToken = (0, _apolloLinkError.onError)(({
        networkError
      }) => {
        if (networkError && networkError.statusCode === 401) {
          // remove cached token on 401 from the server
          this.session.invalidate();
          this.router.replaceWith('login');
        }
      });
      const flowLink = authLink.concat(resetToken);
      return flowLink.concat(httpLink);
    })
  });

  _exports.default = _default;
});