define("web/ui/authenticated/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Authenticated extends Ember.Route.extend(_authenticatedRouteMixin.default) {
    beforeModel(transition) {
      if (!this.session.isAuthenticated) this.transitionTo("login");
    } // @action
    // error(e){
    // 	console.log(e)
    // }


  }

  _exports.default = Authenticated;
});