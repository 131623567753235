define("web/mixins/admin-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminRouteMixin = Ember.Mixin.create({
    session: Ember.inject.service(),

    beforeModel(transition) {
      if (this.session.hasOfficeRole) this.transitionTo("authenticated");
    }

  });
  var _default = AdminRouteMixin;
  _exports.default = _default;
});