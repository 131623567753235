define("web/ui/components/accounts/forms/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6AGZzBrS",
    "block": "{\"symbols\":[\"@errorMessage\"],\"statements\":[[11,\"form\"],[24,0,\"login-form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Email\"],[13],[2,\"\\n\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"username\"],null]],null]],[[],[]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"Password\"],[13],[2,\"\\n\\t\\t\"],[8,\"input\",[[24,0,\"form-control\"],[24,4,\"password\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]],\"password\"],null]],null]],[[\"@value\"],[[34,3]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"button\"],[14,0,\"btn btn-success\"],[12],[2,\"Sign In\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\",\"password\"]}",
    "meta": {
      "moduleName": "web/ui/components/accounts/forms/login/template.hbs"
    }
  });

  _exports.default = _default;
});