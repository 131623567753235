define("web/ui/authenticated/quotes/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency", "web/constants"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  const DEFAULT_PAGE = 1;
  let QuotesIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class QuotesIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", ["statusFilter", "customerFilter", "patientFilter", "pickupFilter", "archived", "before", "after", "ticks"]);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "statusFilter", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "customerFilter", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "patientFilter", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "pickupFilter", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "dropoffFilter", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "archived", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "page", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "ticks", _descriptor10, this);
      (0, _defineProperty2.default)(this, "pageSize", _constants.quotesPageSize);
    }

    get isActiveStatus() {
      return this.statusFilter === "active";
    }

    get sortField() {
      const field = this.isActiveStatus ? "tripOn" : "quotedOn";
      return `quote.${field}:desc`;
    }

    handleQuoteStatusChange(quote) {}

    handleStatusChange(status) {
      this.resetPaging();
      this.router.transitionTo({
        queryParams: {
          statusFilter: status
        }
      });
    }

    handleDelete() {
      this.ticks = new Date().getTime();
    }

    handleToggleArchive() {
      this.resetPaging();
      this.archived = !this.archived;
    }

    handleAirportSelect(field, value) {
      this.resetPaging();
      const fieldName = `${field}Filter`;
      if (value != null) this[fieldName] = value.id;else this[fieldName] = null;
    }

    handleCustomerChange(value) {
      this.resetPaging();
      if (value != null) this.customerFilter = value.id;else this.customerFilter = null;
    }

    handlePatientChange(value) {
      this.resetPaging();
      if (value != null) this.patientFilter = value.id;else this.patientFilter = null;
    }

    resetPaging() {
      this.page = DEFAULT_PAGE;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "statusFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "all";
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customerFilter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "patientFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pickupFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dropoffFilter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "archived", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "page", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_PAGE;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ticks", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleQuoteStatusChange", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleQuoteStatusChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleStatusChange", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "handleStatusChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleDelete", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "handleDelete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleToggleArchive", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggleArchive"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAirportSelect", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "handleAirportSelect"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleCustomerChange", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "handleCustomerChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handlePatientChange", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "handlePatientChange"), _class.prototype)), _class));
  _exports.default = QuotesIndexController;
});