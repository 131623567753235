define("web/ui/authenticated/settings/facilities/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TDdc1s0Q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Add a Facility\"],[13],[2,\"\\n\"],[8,\"forms/facility\",[],[[\"@model\",\"@cancelRoute\"],[[34,0],\"authenticated.settings.facilities\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/facilities/new/template.hbs"
    }
  });

  _exports.default = _default;
});