define("web/ui/components/employees/review-item/scheduler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PBQo1I/4",
    "block": "{\"symbols\":[\"@reviewItem\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[8,\"ember-flatpickr\",[[24,0,\"form-control\"]],[[\"@allowInput\",\"@enableTime\",\"@dateFormat\",\"@onChange\",\"@parseDate\",\"@placeholder\",\"@date\"],[false,false,\"m/d/Y\",[30,[36,0],[[32,0,[\"handleInput\"]]],null],[30,[36,0],[[32,0,[\"parseDate\"]]],null],\"Next Review on\",[32,0,[\"nextReviewOn\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"h-14 text-right\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\tSaving...\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\tSaved\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"saveReviewOn\",\"if\",\"isSaved\"]}",
    "meta": {
      "moduleName": "web/ui/components/employees/review-item/scheduler/template.hbs"
    }
  });

  _exports.default = _default;
});