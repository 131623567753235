define("web/ui/components/quotes/form/notes-form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  let CompanyNotes = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class CompanyNotes extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "value", _descriptor, this);
    }

    get field() {
      return this.args.field;
    }

    get quote() {
      return this.args.quote;
    }

    handleInput(e) {
      this.quote[this.field] = e.target.value;
      this.args.onUpdate();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote[this.field];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = CompanyNotes;
});