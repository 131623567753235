define("web/ui/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends Ember.Route.extend(_unauthenticatedRouteMixin.default) {
    resetController(controller) {
      controller.setProperties({
        errorMessage: ""
      });
    }

  }

  _exports.default = LoginRoute;
});