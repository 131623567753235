define("web/ui/components/quotes/active-form/sections/receiving-facility/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/receiving-facility/query.graphql", "web/ui/components/quotes/active-form/facilities"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _query, _facilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _descriptor3, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)(function* () {
    let {
      quote: {
        id
      }
    } = this.args;
    const variables = {
      id
    };
    let result = yield this.apollo.watchQuery({
      query: _query.default,
      variables,
      fetchPolicy: "network-only"
    }, "quote");
    this.loadNewQuoteFieldsFromResult(result);
    this.facility = result.receivingFacility;
    let {
      quote
    } = this.args;
    if (result.comment) quote.comment = result.comment;else quote.comment = null;
  }).drop(), (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "facility", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor3, this);
      this.setup();
    }

    setup() {
      this.loadData.perform();
    }

    loadNewQuoteFieldsFromResult(result) {
      let {
        quote
      } = this.args;
      (0, _facilities.loadQuoteFieldsFromQueryResult)(quote, result, "receiving");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "facility", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});