define("web/ui/components/quotes/form/email-dialog-customer-quote/send-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ua8W1skZ",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,1]],[16,0,[31,[\"btn btn-block btn-lg btn-primary \",[30,[36,1],[[32,1],\"disabled\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"handleSend\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"sendEmail\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"i\"],[14,0,\"fa fa-spin fa-refresh\"],[12],[13],[2,\"\\n\\tSending...\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"buttonText\",\"if\",\"fn\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/email-dialog-customer-quote/send-button/template.hbs"
    }
  });

  _exports.default = _default;
});