define("web/ui/components/forms/form/inputs/text-input-group/component", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "web/ui/components/forms/form/inputs/base-input"], function (_exports, _applyDecoratedDescriptor2, _component, _baseInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  const defaultType = "text";
  let FormsFormTextInputGroup = (_dec = Ember._action, _dec2 = Ember._action, (_class = class FormsFormTextInputGroup extends _baseInput.default {
    // get saveOnBlurOnly() {
    // 	const { saveOnBlurOnly } = this.args
    // 	if (isBlank(saveOnBlurOnly))
    // 		return false
    // 	else
    // 		return saveOnBlurOnly
    // }
    get inputType() {
      if (this.args.type != undefined) return this.args.type;
      return defaultType;
    }

    handleBlur(field, e) {
      this.args.onHandleInput(field, e.target.value);
    } // @action
    // handleInput(field, e) {
    // 	if (this.saveOnBlurOnly)
    // 		return
    // 	this.args.onHandleInput(
    // 		field,
    // 		e.target.value
    // 	)
    // }


    handleInsert(element, [component]) {
      if (this.args.focus != undefined && this.args.focus) element.focus();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleBlur", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleBlur"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInsert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsert"), _class.prototype)), _class));
  _exports.default = FormsFormTextInputGroup;
});