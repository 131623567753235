define("web/ui/components/accounts/forms/forgot-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V7UfAn/p",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"well\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-8 col-md-offset-2\"],[14,5,\"padding-top:20px;\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h3\"],[12],[2,\"Forgot your password?\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[14,0,\"text-muted\"],[12],[2,\"Enter your email address below and we'll send you password reset instructions.\"],[13],[2,\"\\n\\t\\t\\t\"],[11,\"form\"],[4,[38,1],[\"submit\",[30,[36,0],[[32,0,[\"handleSubmit\"]]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\\t\\t \"],[10,\"label\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t Enter your email address\\n\\t\\t\\t\\t\\t \"],[13],[2,\"\\n\\t\\t\\t\\t\"],[11,\"input\"],[24,0,\"form-control\"],[24,4,\"text\"],[4,[38,1],[\"input\",[30,[36,0],[[32,0,[\"handleInput\"]]],null]],null],[12],[13],[2,\"\\n\\t\\t\\t\\t \"],[13],[2,\"\\n\\t\\t\\t\\t \"],[10,\"button\"],[14,0,\"btn btn-default\"],[12],[2,\"\\n\\t\\t\\t\\t\\t Send me reset instructions\\n\\t\\t\\t\\t \"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[14,5,\"border-top: 2px solid #33a02c;padding-top:15px;padding-bottom:20px;margin-top:25px;font-size:12px;\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-8 col-md-offset-2\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"A note about spam filters\"],[13],[13],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[2,\"If you don't get an email from us within a few minutes please be sure to check your spam filter. The email will be coming from no-reply@globaljetcare.com.\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/accounts/forms/forgot-password/template.hbs"
    }
  });

  _exports.default = _default;
});