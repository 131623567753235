define("web/ui/components/quotes/active-form/section-title/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get title() {
      if (this.args.section === "aircraft-crew") {
        return "Aircraft & Crew";
      } else {
        return this.args.section;
      }
    }

  }

  _exports.default = _default;
});