define("web/ui/components/quotes/form/notes-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Xxn8li8",
    "block": "{\"symbols\":[],\"statements\":[[11,\"textarea\"],[24,0,\"form-control auto-focus\"],[16,2,[34,0]],[24,\"placeholder\",\"Add notes here...\"],[24,\"rows\",\"3\"],[4,[38,2],[\"input\",[30,[36,1],[[32,0,[\"handleInput\"]]],null]],null],[12],[2,\"\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/form/notes-form/template.hbs"
    }
  });

  _exports.default = _default;
});