define("web/ui/authenticated/settings/users/personnel/show/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "web/ui/authenticated/settings/users/personnel/show/create.graphql", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _create, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  const roles = [{
    value: "admin",
    label: "Admin"
  }, {
    value: "flight",
    label: "Flight Crew"
  }, {
    value: "medical",
    label: "Medical"
  }];

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* () {
    const {
      id
    } = this.model;
    const result = yield this.apollo.mutate({
      mutation: _create.default,
      variables: {
        id,
        role: this.userRole.value
      }
    }, "employeesCreateUser");

    if (result.error) {
      alert(result.error.message);
      return;
    }

    this.transitionToRoute("authenticated.settings.users.show", result.user.id);
  }).drop(), (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isShowingCreateUserModal", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "userRole", _descriptor3, this);
      (0, _defineProperty2.default)(this, "roles", roles);
      (0, _initializerDefineProperty2.default)(this, "createUser", _descriptor4, this);
    }

    handleToggleUserForm() {
      this.isShowingCreateUserModal = !this.isShowingCreateUserModal;
    }

    handleRoleSelect(role) {
      this.userRole = role;
    }

    handleCreateUser() {
      this.createUser.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isShowingCreateUserModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "userRole", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleUserForm", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleUserForm"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleRoleSelect", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRoleSelect"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleCreateUser", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleCreateUser"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "createUser", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});