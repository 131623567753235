define("web/ui/components/forms/form/inputs/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wi4E5LzS",
    "block": "{\"symbols\":[\"@label\",\"@field\",\"@disabled\",\"@model\",\"@placeholder\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\"],[8,\"forms/form/inputs/label\",[],[[\"@label\",\"@field\"],[[32,1],[32,2]]],null],[2,\"\\n\\t\"],[8,\"textarea\",[[16,0,[31,[\"form-control auto-focus \",[30,[36,2],[[32,3],\"disabled\"],null]]]],[16,\"placeholder\",[32,5]],[4,[38,4],[\"input\",[30,[36,3],[[32,0,[\"handleInput\"]],[32,2]],null]],null]],[[\"@disabled\",\"@value\",\"@rows\"],[[32,3],[30,[36,0],[[32,4],[32,2]],null],[34,1]]],null],[2,\"\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"rows\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/inputs/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});