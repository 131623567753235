define("web/ui/components/forms/form/inputs/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0uoNLR+B",
    "block": "{\"symbols\":[\"option\",\"@options\",\"@field\",\"@model\",\"@label\",\"@readOnly\"],\"statements\":[[11,\"div\"],[24,0,\"form-group\"],[4,[38,2],[[32,0,[\"handleUpdate\"]],[32,4],[32,0]],null],[12],[2,\"\\n\\t\"],[8,\"forms/form/inputs/label\",[],[[\"@label\",\"@field\"],[[32,5],[32,3]]],null],[2,\"\\n\"],[6,[37,3],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,1],[[32,4],[32,3]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[8,\"power-select\",[[24,0,\"form-control\"]],[[\"@selected\",\"@options\",\"@onChange\"],[[32,0,[\"selected\"]],[32,2],[30,[36,0],[[32,0,[\"handleInput\"]],[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[32,1,[\"label\"]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"get\",\"did-update\",\"if\"]}",
    "meta": {
      "moduleName": "web/ui/components/forms/form/inputs/select/template.hbs"
    }
  });

  _exports.default = _default;
});