define("web/ui/components/forms/form/actions/cancel-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {tracked} from "@glimmer/tracking"
  const defaultText = "cancel";

  class FormsFormActionsCancelButton extends _component.default {
    get text() {
      return this.args.buttonText === undefined ? defaultText : this.args.buttonText;
    }

  }

  _exports.default = FormsFormActionsCancelButton;
});