define("web/ui/components/employees/review-item/position-assignment-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/gql/mutations/employees/review-items/toggle-position-assignment.graphql", "ember-apollo-client", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _togglePositionAssignment, _emberApolloClient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let EmployeesReviewItemPositionAssignmentItem = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    let {
      reviewItem,
      position
    } = this.args;
    let variables = {
      reviewItemId: reviewItem.id,
      positionId: position.id
    };
    yield this.apollo.mutate({
      mutation: _togglePositionAssignment.default,
      variables
    });
  }).drop(), (_class = (_temp = class EmployeesReviewItemPositionAssignmentItem extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "toggle", _descriptor2, this);
    }

    handleItemToggle() {
      this.toggle.perform();
    }

    get isSelected() {
      let {
        reviewItem,
        position
      } = this.args;
      return reviewItem.reviewItemPositionAssignments.find(item => {
        return item.position.id === position.id;
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleItemToggle", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleItemToggle"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeesReviewItemPositionAssignmentItem;
});