define("web/ui/components/quotes/form/operations/print/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "web/utils/download-file", "web/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _downloadFile, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  let QuotePrint = (_dec = Ember.inject.service, _dec2 = Ember.computed("quote.hops.@each.dropoff"), _dec3 = Ember.computed("quote.hops.@each.pickup"), _dec4 = Ember._action, (_class = (_temp = class QuotePrint extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
    }

    get quote() {
      return this.args.quote;
    }

    get hops() {
      return this.quote.hops;
    }

    get hasDropoff() {
      return this.hops.any(item => item.dropoff);
    }

    get hasPickup() {
      return this.hops.any(item => item.pickup);
    }

    get disabled() {
      return !this.hasPickup || !this.hasDropoff;
    }

    get url() {
      let {
        id,
        number
      } = this.quote;
      return `${_environment.default.APP.host}/print_quote/${id}.pdf`;
    }

    handlePrint() {
      let {
        id,
        number
      } = this.quote;
      let url = `${_environment.default.APP.host}/print_quote/${id}.pdf`;
      (0, _downloadFile.downloadFile)(url, `quote-${number}`, this.session);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasDropoff", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "hasDropoff"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasPickup", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasPickup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handlePrint", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handlePrint"), _class.prototype)), _class));
  _exports.default = QuotePrint;
});