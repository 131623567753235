define("web/ui/components/customers/reps/card/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client", "web/ui/components/customers/reps/card/toggle-mutation.graphql", "web/ui/components/customers/reps/card/save-mutation.graphql", "web/utils/attributes-from-model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient, _toggleMutation, _saveMutation, _attributesFromModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let CustomersRepCard = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* () {
    if (Ember.isEmpty(this.rep.name)) {
      alert("Please provide a valid name for the rep");
      return;
    }

    const variables = {
      id: this.repId,
      attributes: this.modelAttributes
    };
    const mutationResult = yield this.apollo.mutate({
      mutation: _saveMutation.default,
      variables
    });
    this.isEditing = false;
  }).drop(), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* () {
    const mutationResult = yield this.apollo.mutate({
      mutation: _toggleMutation.default,
      variables: {
        id: this.repId
      }
    }, "customersRepsToggle.rep");
  }).drop(), (_class = (_temp = class CustomersRepCard extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isEditing", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "updateRep", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "toggleRep", _descriptor4, this);
    }

    handleUpdateRep(rep) {
      this.updateRep.perform();
    }

    get rep() {
      return this.args.rep;
    }

    get repId() {
      return this.rep.id;
    }

    toggleRepEdit() {
      this.isEditing = !this.isEditing;
    }

    handleToggleRep() {
      this.toggleRep.perform();
    }

    get modelAttributes() {
      return (0, _attributesFromModel.attributesFromModel)(this.rep, "name", "email", "phone");
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isEditing", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleUpdateRep", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdateRep"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateRep", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleRepEdit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRepEdit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleToggleRep", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleToggleRep"), _class.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleRep", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomersRepCard;
});