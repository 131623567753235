define("web/ui/authenticated/settings/users/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      return {};
    }

  }

  _exports.default = _default;
});