define("web/ui/components/quotes/active-form/sections/case-notes/print/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fIuXLo3q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"strong\"],[12],[2,\"View Medical Transport Info\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"font-size:0.5em;\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"flex\"],[14,5,\"margin-top:1em;align-items:baseline;\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,3],[[30,[36,2],[[35,1],[35,0]],null],\"btn-danger\",\"btn-default\"],null]]]],[24,5,\"margin-right:1em;\"],[4,[38,5],[\"click\",[30,[36,4],[[32,0,[\"handleToggleNotes\"]],[35,0]],null]],null],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\tWith All Notes\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,5,\"margin-top:1em;align-items:baseline;\"],[14,0,\"flex\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,3],[[30,[36,2],[[35,1],[35,6]],null],\"btn-danger\",\"btn-default\"],null]]]],[24,5,\"margin-right:1em;\"],[4,[38,5],[\"click\",[30,[36,4],[[32,0,[\"handleToggleNotes\"]],[35,6]],null]],null],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\tFace Sheet Only\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,5,\"margin-top:0.75em\"],[14,0,\"text-right\"],[12],[2,\"\\n\\t\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,5],[\"click\",[30,[36,4],[[32,0,[\"handleView\"]]],null]],null],[12],[2,\"View\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"allNotes\",\"operation\",\"eq\",\"if\",\"fn\",\"on\",\"formOnly\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/case-notes/print/template.hbs"
    }
  });

  _exports.default = _default;
});