define("web/ui/components/forms/form/inputs/select/remote/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-concurrency", "ember-apollo-client"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrency, _emberApolloClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let FormInputsSelectRemove = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = (0, _emberConcurrency.task)(function* () {
    let {
      query,
      queryName
    } = this.args;
    let result = yield this.apollo.query({
      query,
      variables: this.variables
    }, queryName);
    this.options = result.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    });
  }).drop(), (_class = (_temp = class FormInputsSelectRemove extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "options", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "loadData", _descriptor3, this);
    }

    handleInsert(element, [component]) {
      component.loadData.perform();
    }

    get variables() {
      return this.args.variables != undefined ? this.args.variables : null;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "options", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInsert", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsert"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormInputsSelectRemove;
});