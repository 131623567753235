define("web/ui/components/quotes/status-changer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Hrbgpfh",
    "block": "{\"symbols\":[\"@quote\"],\"statements\":[[10,\"div\"],[14,0,\"btn-group\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"...a\"],[14,5,\"width:170px;\"],[12],[2,\"\\n\\t\"],[8,\"quotes/status-changer/button\",[],[[\"@quote\",\"@icon\",\"@status\"],[[32,1],\"question-circle\",\"pending\"]],null],[2,\"\\n\\n\\t\"],[8,\"quotes/status-changer/button\",[],[[\"@quote\",\"@icon\",\"@status\"],[[32,1],\"fighter-jet\",\"active\"]],null],[2,\"\\n\\n\\t\"],[8,\"quotes/status-changer/button\",[],[[\"@quote\",\"@icon\",\"@status\"],[[32,1],\"ban\",\"closed\"]],null],[2,\"\\n\\t\\n\\t\"],[8,\"quotes/status-changer/button\",[],[[\"@quote\",\"@icon\",\"@status\"],[[32,1],\"check-circle\",\"flown\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/status-changer/template.hbs"
    }
  });

  _exports.default = _default;
});