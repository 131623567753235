define("web/constants/gender-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.genderOptions = void 0;
  const genderOptions = [{
    value: "male",
    label: "Male"
  }, {
    value: "female",
    label: "Female"
  }];
  _exports.genderOptions = genderOptions;
});