define("web/ui/components/quotes/active-form/sections/aircraft-crew/employee-select/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EmployeeSelect extends _component.default {
    get employees() {
      let {
        employees,
        selected
      } = this.args;
      const selectedIds = selected.mapBy("id");
      return employees.filter(e => {
        return !selectedIds.includes(e.id);
      });
    }

  }

  _exports.default = EmployeeSelect;
});