define("web/ui/components/quotes/active-form/sections/updates/insert-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nchuV8k3",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[10,\"div\"],[14,0,\"w-full\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,0,\"cursor-pointer\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-plus-circle fa-2x\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "web/ui/components/quotes/active-form/sections/updates/insert-trigger/template.hbs"
    }
  });

  _exports.default = _default;
});