define("web/ui/components/quotes/form/general-info/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  let GeneralInfo = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class GeneralInfo extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "quotedOn", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "tripOn", _descriptor2, this);
    }

    get quote() {
      return this.args.quote;
    }

    parseDate(value) {
      return moment(value).toDate();
    }

    handleInput(field, value) {
      let dateValue = moment(value.firstObject).toDate();
      this[field] = dateValue;
      this.quote[field] = dateValue;
      if (!this.invalidTripDate) this.args.onUpdate();
    }

    handleAircraftChange(value) {
      this.quote.aircraftId = value.id;
      this.args.onUpdate();
    }

    get invalidTripDate() {
      return this.tripOn < this.quotedOn;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "quotedOn", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.quotedOn != undefined ? moment(this.quote.quotedOn).toDate() : null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tripOn", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.quote.tripOn != undefined ? moment(this.quote.tripOn).toDate() : null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "parseDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "parseDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleAircraftChange", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleAircraftChange"), _class.prototype)), _class));
  _exports.default = GeneralInfo;
});