define("web/ui/authenticated/settings/review/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cOibtPHD",
    "block": "{\"symbols\":[\"reviewItem\"],\"statements\":[[10,\"h4\"],[12],[2,\"Review\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n\\n\\t\"],[8,\"link-to\",[[24,0,\"btn btn_custom_2 btn-lg\"]],[[\"@route\"],[\"authenticated.settings.review.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[12],[13],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"reviewItems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[8,\"employees/review-item\",[],[[\"@positions\",\"@reviewItem\"],[[34,0,[\"positions\"]],[32,1]]],null],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/review/index/template.hbs"
    }
  });

  _exports.default = _default;
});