define("web/ui/authenticated/settings/users/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H6/oVjqd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"\\n  User Profile :: \"],[1,[35,0,[\"firstName\"]]],[2,\" \"],[1,[35,0,[\"lastName\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"nav\"],[14,0,\"nav settings-detail-subnav\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.settings.users.show.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Profile\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.settings.users.show.personnel\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Personnel Profile\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.settings.users.show.review\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Review Dates\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"authenticated.settings.users.show.permissions\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Permissions\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/users/show/template.hbs"
    }
  });

  _exports.default = _default;
});