define("web/ui/components/quotes/active-form/sections/attachments/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "web/ui/components/quotes/active-form/sections/attachments/upload.graphql", "web/ui/components/quotes/active-form/sections/attachments/delete.graphql", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _upload, _delete, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.inject.service, _dec7 = Ember._action, _dec8 = (0, _emberConcurrency.task)(function* () {
    const result = yield this.canDo.allowed(null, "Phi", "read", null);
    this.hasPhiAccess = result;
    this.phiForUpload = result;
  }).drop(), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = (0, _emberConcurrency.task)(function* (id) {
    const result = yield this.apollo.mutate({
      mutation: _delete.default,
      variables: {
        id
      }
    }, "attachmentDelete");
    this.attachments = result.quote.attachments;
  }).drop(), _dec13 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "uploadsInProgress", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "attachments", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "hasPhiAccess", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "uploadTrigger", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "phiForUpload", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "canDo", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "loadPhi", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "deleteAttachment", _descriptor9, this);
      this.loadPhi.perform();
      const {
        quote: {
          attachments
        }
      } = this.args;
      this.attachments = attachments;
    }

    togglePhiForUpload() {
      this.phiForUpload = !this.phiForUpload;
    }

    handleUploadButtonInsert(element, [component]) {
      component.uploadTrigger = element;
    }

    handleTriggerUploadDialog() {
      this.uploadTrigger.click();
    }

    handleDelete(attachment) {
      const confirmed = confirm("Are you sure you want to delete this attachment?");
      if (!confirmed) return;
      this.deleteAttachment.perform(attachment.id);
    }

    async upload(e) {
      const {
        phiForUpload
      } = this;
      const files = Object.entries(e.target.files);
      const {
        id
      } = this.args.quote;
      const fileGroup = files.map(item => {
        let file = item[1];
        let url = URL.createObjectURL(file);
        return {
          file,
          url
        };
      });
      fileGroup.forEach(item => {
        this.uploadsInProgress.addObject(item.url);
      });
      fileGroup.forEach(async item => {
        const {
          file
        } = item;
        const result = await this.apollo.mutate({
          mutation: _upload.default,
          variables: {
            id,
            file,
            phi: phiForUpload
          }
        }, "attachmentUpload");

        if (result.error != null) {
          alert(result.error.message);
        }

        if (result.quote != null) this.attachments = result.quote.attachments;
        this.phiForUpload = this.hasPhiAccess;
        this.uploadsInProgress.removeObject(item.url);
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "uploadsInProgress", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "attachments", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "hasPhiAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "uploadTrigger", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "phiForUpload", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "canDo", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "togglePhiForUpload", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "togglePhiForUpload"), _class2.prototype), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadPhi", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleUploadButtonInsert", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleUploadButtonInsert"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleTriggerUploadDialog", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "handleTriggerUploadDialog"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleDelete", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDelete"), _class2.prototype), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "deleteAttachment", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "upload", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "upload"), _class2.prototype)), _class2));

  _exports.default = _class;
});