define("web/ui/components/users/list/filter/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _temp;

  const activeClasses = "whitespace-no-wrap pb-4 px-1 border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700 cursor-pointer";
  const inactiveClasses = "whitespace-no-wrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 cursor-pointer";

  let _class = (_dec = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "activeClasses", activeClasses);
      (0, _defineProperty2.default)(this, "inactiveClasses", inactiveClasses);
    }

    handleDisabledToggle() {
      this.args.onToggleDisabled();
    }

  }, _temp), ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleDisabledToggle", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDisabledToggle"), _class2.prototype)), _class2));

  _exports.default = _class;
});