define("web/ui/authenticated/customers/show/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency", "ember-apollo-client", "web/gql/mutations/customers/update.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrency, _emberApolloClient, _update) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let CustomersShowController = (_dec = Ember._action, _dec2 = (0, _emberConcurrency.task)(function* () {
    let {
      name,
      entityType,
      address,
      addressTwo,
      city,
      state,
      zipCode,
      country,
      phone,
      fax
    } = this.model;
    let attributes = {
      name,
      entityType,
      address,
      addressTwo,
      city,
      state,
      zipCode,
      country,
      phone,
      fax
    };
    let variables = {
      id: this.model.id,
      attributes
    };
    let result = yield this.apollo.mutate({
      mutation: _update.default,
      variables
    }, "customersUpdate");
    alert("Saved");
  }).drop(), (_class = (_temp = class CustomersShowController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor2, this);
    }

    async handleSubmit() {
      this.update.perform();
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleSubmit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "update", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomersShowController;
});