define("web/ui/components/quotes/form/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency", "web/gql/mutations/quotes/update.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency, _update) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  const DEBOUNCE_MS = 500;
  let QuotesForm = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
    yield this.runUpdate.perform();
  }).restartable(), _dec5 = (0, _emberConcurrency.task)(function* () {
    let variables = this.updateVariables;
    yield this.apollo.mutate({
      mutation: _update.default,
      variables
    });
  }).restartable(), (_class = (_temp = class QuotesForm extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "selectedPanel", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "update", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "runUpdate", _descriptor4, this);
    }

    handlePanelChange(value) {
      this.selectedPanel = value;
    }

    async handleUpdate() {
      try {
        await this.update.perform();
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) throw e;
      }
    }

    get quote() {
      return this.args.quote;
    }

    get title() {
      return `Quote #${this.quote.number}`;
    }

    get updateVariables() {
      let {
        id,
        internalNotes,
        customerNotes,
        aircraftId,
        customerId,
        repId,
        quotedOn,
        caseNumber,
        tripOn,
        price,
        costPerMile,
        backhaulDate,
        backhaulPrice,
        invoicedPrice,
        groundsFee,
        groundsCharge,
        airlineFee,
        overnightFee,
        internationalFee,
        medicalRn1,
        medicalRn2,
        medicalEmtp1,
        medicalEmtp2,
        medicalMd,
        medicalRt,
        medicalFee,
        doctorFee,
        passengersAllowed,
        applyCreditCardFees,
        miscLabel1,
        miscAmount1,
        miscLabel2,
        miscAmount2,
        miscLabel3,
        miscAmount3,
        miscLabel4,
        miscAmount4
      } = this.quote;
      return {
        id,
        attributes: {
          customerNotes,
          internalNotes,
          aircraftId,
          customerId,
          costPerMile,
          repId,
          quotedOn,
          tripOn,
          caseNumber,
          price,
          backhaulDate,
          backhaulPrice,
          groundsFee,
          groundsCharge,
          airlineFee,
          overnightFee,
          internationalFee,
          medicalRn1,
          medicalRn2,
          medicalEmtp1,
          medicalEmtp2,
          medicalMd,
          medicalRt,
          medicalFee,
          doctorFee,
          passengersAllowed,
          applyCreditCardFees,
          miscLabel1,
          miscAmount1,
          miscLabel2,
          miscAmount2,
          miscLabel3,
          miscAmount3,
          miscLabel4,
          miscAmount4,
          invoicedPrice
        }
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedPanel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handlePanelChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handlePanelChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleUpdate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdate"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "update", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "runUpdate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QuotesForm;
});