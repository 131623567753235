define("web/ui/authenticated/settings/airports/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AafCYYdn",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/airport\",[],[[\"@model\",\"@saveText\",\"@onSubmit\",\"@isRunning\",\"@saveIcon\"],[[34,0],\"Save\",[30,[36,1],[[32,0,[\"handleSubmit\"]]],null],[32,0,[\"save\",\"isRunning\"]],\"save\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"fn\"]}",
    "meta": {
      "moduleName": "web/ui/authenticated/settings/airports/show/template.hbs"
    }
  });

  _exports.default = _default;
});