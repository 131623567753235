define("web/ui/components/forms/user/permissions/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "ember-apollo-client", "ember-concurrency", "web/ui/components/forms/user/permissions/policies.graphql"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberApolloClient, _emberConcurrency, _policies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  const accessGranted = "granted";
  const accessDenied = "denied";

  let _class = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)(function* () {
    yield this.loadPolicies.perform();
    yield this.loadDefaults.perform();
  }).drop(), _dec4 = (0, _emberConcurrency.task)(function* () {
    const result = yield this.apollo.query({
      query: _policies.default,
      fetchPolicy: "network-only"
    }, "userPrivilegeOptions");
    this.policies = result;
  }).drop(), _dec5 = (0, _emberConcurrency.task)(function* () {
    const {
      model,
      model: {
        userPrivileges
      }
    } = this.args;
    if (model.id) this.selections = this.policies.map(item => {
      const {
        name,
        action
      } = item;
      const priv = userPrivileges.find(priv => priv.policy == name && priv.action == action);
      return {
        name,
        action,
        access: priv == undefined ? "denied" : priv.access
      };
    }); // else
    //     yield this.loadDefaultsForNew.perform()
  }).drop(), _dec6 = (0, _emberConcurrency.task)(function* () {
    yield true;
  }).drop(), _dec7 = Ember._action, _dec8 = Ember._action, (_class2 = (_temp = class _class2 extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "policies", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "selections", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "load", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "loadPolicies", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "loadDefaults", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "loadDefaultsForNew", _descriptor7, this);
      this.load.perform();
    }

    isSelected(name, act) {
      return this.selections.find(item => {
        return item.name == name && item.action == act && item.access == accessGranted;
      });
    }

    handleToggleSelection(policy, act, e) {
      const {
        checked
      } = e.target;
      this.selections = this.selections.map(item => {
        if (item.name == policy && item.action == act) {
          const access = checked ? accessGranted : accessDenied;
          return { ...item,
            access
          };
        } else return item;
      });
      this.args.onPermissionChange(this.selections);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_emberApolloClient.queryManager], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "policies", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "selections", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "load", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadPolicies", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadDefaults", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadDefaultsForNew", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isSelected", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleToggleSelection", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleToggleSelection"), _class2.prototype)), _class2));

  _exports.default = _class;
});