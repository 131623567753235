define("web/constants", ["exports", "web/constants/customers", "web/constants/gender-options", "web/constants/pagination"], function (_exports, _customers, _genderOptions, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "customerTypes", {
    enumerable: true,
    get: function () {
      return _customers.customerTypes;
    }
  });
  Object.defineProperty(_exports, "genderOptions", {
    enumerable: true,
    get: function () {
      return _genderOptions.genderOptions;
    }
  });
  Object.defineProperty(_exports, "quotesPageSize", {
    enumerable: true,
    get: function () {
      return _pagination.quotesPageSize;
    }
  });
  Object.defineProperty(_exports, "facilitiesPageSize", {
    enumerable: true,
    get: function () {
      return _pagination.facilitiesPageSize;
    }
  });
});