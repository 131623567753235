define("web/ui/components/quotes/active-form/sections/case-notes/detail/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get booleanValue() {
      return this.args.value === true;
    }

    get inactive() {
      return this.args.dataType === "boolean" && !this.args.value;
    }

  }

  _exports.default = _default;
});